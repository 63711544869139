import { useNavigate } from "react-router-dom";
import { Button } from "../../../Components";
import { routes } from "../../../util/routes";
import Clients from "./clients";
import classes from "./clients-tab.module.css";
import Heading from "./heading";
import { TextFormField } from "../../../Components/index";
import { useState } from "react";
import * as XLSX from 'xlsx';
import { MultipleCLientAdd } from "../../../Store/Coach/Client/client-actions";
import { useDispatch, useSelector } from "react-redux";
// import { TabContainer, Toast } from "react-bootstrap";
import { toast,ToastContainer } from "react-toastify";

const ClientTab = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state.auth.userInfo);
  console.log(userInfo, "userInfo")
  const [searchTerm, setSearchTerm] = useState('');
  const [Clientdata, setClientdata] = useState([]);
  const [clients, setclients] = useState([])
  const [filename, setfilename] = useState('')
  const [btnshow, setbtnshow] = useState(false)
  const [disabled,setdisabled] =useState(false)
  const addClientHandler = (event) => {
    event.preventDefault();
    navigate(routes.coachDashboard.clients.addClients);
  };



  const downloadESV = () => {
    const data = Clientdata.map((client) => ({
      firstName: client.firstName,
      lastName: client.lastName,
      email: client.email,
      // Add other properties as needed
    }));

    // console.log(data,"code")
    // debugger

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Clients');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'clients.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const searchChangeHandler = (event) => {
    setSearchTerm(event.target.value);
  }

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        console.log(data, "data")
        const workbook = XLSX.read(data, { type: "array" });
        console.log(workbook, "workbook")
        const sheetName = workbook.SheetNames[0];
        console.log(sheetName, "sheetName")
        const worksheet = workbook.Sheets[sheetName];
        console.log(worksheet, "worksheet")
        const json = XLSX.utils.sheet_to_json(worksheet);
        const jsonWithCustomField = json.map((row) => {
          return {
            ...row,
            coachId: userInfo.id,
            language: "english",
            remember_me: true,
            role: "client",
          };
        })
        setfilename(`${sheetName}.xlsx`)
        setbtnshow(true)
        setclients(jsonWithCustomField)
        console.log(jsonWithCustomField, "json file");
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }


  const validateClients = (clients) => {
    for (let i = 0; i < clients.length; i++) {
      const data = clients[i];
      if (
        !data.firstName ||
        !data.lastName ||
        !data.email ||
        !data.password ||
        !data.notes
      ) {
        return false; 
      }
    }
    return true; 
  };

  const UploadClientData = async () => {
    if (clients.length > 0) {
      const isValid = validateClients(clients);
      console.log(isValid,"isValid")
      if (isValid) {
    
        try {
          setdisabled(true)
          await MultipleCLientAdd(clients)(dispatch);
          setdisabled(false)
          toast.success("Registered successfully!");
          navigate(-1);
          //  resetForm();
        } catch (e) {
          toast.error("Email is already registered. Please use a different email.");
        
        }
      } else {
        console.log("Clients data is not valid. All fields are required.")
        toast.error("Clients data is not valid. All fields are required.");
      }
    } else {
      toast.error("Uplaod valid excel file")
    }

  }

  return (
    <div className={classes.background}>
      <div className={classes.center}>
        <div className={classes.addAndSearch}>
          <Button text="+ Add Clients" onClick={addClientHandler} />
          <TextFormField
            type="email"
            isFormFieldExtra={true}
            placeholder="Search"
            onChange={searchChangeHandler}
          />
          <Button text="Download Csv" onClick={downloadESV} />
          <div className={classes.file_upload}>
            <label htmlFor="upload" className={classes.file_upload__label}>
              upload a file
            </label>
            <input
              id="upload"
              class={classes.file_upload__input}
              type="file"
              name="file-upload"
              accept=".xlsx, .xls"
              onChange={readUploadFile}

            />
          </div>
          <label htmlFor="code" style={{marginLeft:"10px"}} className={classes.file_upload__label}>
              <a  style={{color:"white",textDecoration:"none"}} href="/sample.xlsx" target="blank" download="clients.xlsx">
             Download Sample a file
              </a>
            </label>
        </div>
        {
          btnshow ? (
            <>
              {
                filename ? (
                  <div className={classes.documentuploader}>

                    <span style={{ color: "white" }}>{filename}</span>
                    <button disabled={disabled} onClick={UploadClientData}>upload</button>
                  </div>
                ) : null
              }
            </>
          ) : null
        }
        <Heading />
        <Clients searchTerm={searchTerm} clientdata={setClientdata} />
      </div>
      <ToastContainer />
    </div>
  );
};

export default ClientTab;
