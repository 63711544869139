import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CreateDayDuration({ setshow, show }) {
    const navigate = useNavigate();
    const [duration, setDuration] = useState();
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e) => {
        const value = parseFloat(e.target.value);

    if (isNaN(value) || value < 1 || value > 90 || value % 1 !== 0) {
        setErrorMessage('Please enter a whole number between 1 and 90');
    } else {
        setErrorMessage('');
        setDuration(value);
    }
    };

    const naigateHandler = () => {
        console.log(typeof(duration),"duration")

        if (isNaN(duration) || duration === undefined || duration <= 0 || duration > 90 || errorMessage) {
            setErrorMessage('Please enter a whole number between 1 and 90');
        
        } else if(duration>0 && duration<=90 ) {
            window.localStorage.setItem('duration', duration);
            const modal = document.getElementById('exampleModalLong');
            if (modal) {
                modal.classList.remove('show');
                modal.style.display = 'none';
                const backdrop = document.getElementsByClassName('modal-backdrop')[0];
                if (backdrop) backdrop.style.display = 'none';
                // setIsModalOpen(false); // Close the modal by updating state
                setshow(false);
                navigate('/coach-dashboard/program/create');
            }
        }
    };

    

    const closeModal = () => {
        const modal = document.getElementById('exampleModalLong');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            const backdrop = document.getElementsByClassName('modal-backdrop')[0];
            if (backdrop) backdrop.style.display = 'none';
            setshow(false);
        }
    };

    useEffect(() => {
        if (show) {
            const modal = document.getElementById('exampleModalLong');
            if (modal) {
                modal.classList.add('show');
                modal.style.display = 'block';
                const backdrop = document.getElementsByClassName('modal-backdrop')[0];
                if (backdrop) backdrop.style.display = 'block';
            }
        } else {
            const modal = document.getElementById('exampleModalLong');
            if (modal) {
                modal.classList.remove('show');
                modal.style.display = 'none';
                const backdrop = document.getElementsByClassName('modal-backdrop')[0];
                if (backdrop) backdrop.style.display = 'none';
            }
        }

    }, [show]);

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalLong"
                id="open"
                style={{ display: 'none' }}
            >
                Launch demo modal
            </button>
            {/* Modal */}
            <div
                className="modal fade"
                id="exampleModalLong"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLongTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content add_task_wrap">
                        <div className="modal-header">
                            <h5 className="modal-title add_task_text" id="exampleModalLongTitle">
                                Create
                            </h5>
                            <button
                                type="button"
                                className="close add_task_close_btn"
                                data-dismiss="modal"
                                aria-label="Close"
                              
                                onClick={closeModal} // Handle modal close
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label className='duration-text' htmlFor="Duration">Duration</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="Duration"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter number"
                                    max="90"
                                    min="1"
                                    maxLength="1"
                                    onChange={handleInputChange}
                                />
                            </div>
                            {errorMessage && (
                                <div style={{ color: 'red' }}>{errorMessage}</div>
                            )}
                        </div>
                        <div class="modal-footer add-close-btn">
                            <button type="button" id='closeModalButton' class="btn btn-secondary close_add_btn" data-dismiss="modal" onClick={closeModal}>Close</button>
                            <button type="button" className="btn btn-primary blue_add_btn" onClick={naigateHandler}>
                                Next
                            </button>
                         
                        </div>
                        {/* <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeModal} // Handle modal close
                            >
                                Close
                            </button>
                            <button type="button" className="btn btn-primary" onClick={naigateHandler}>
                                next
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}
