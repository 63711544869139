// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_center__1rG6g {
  text-align: center;
  padding: 3rem 1rem;
  margin-top: 5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/login/login.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".center {\n  text-align: center;\n  padding: 3rem 1rem;\n  margin-top: 5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": `login_center__1rG6g`
};
export default ___CSS_LOADER_EXPORT___;
