// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.write-comment-on-topic_commentField__a9TK5 {
  border: none;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  height: 2rem;
  width: 100%;
  opacity: 0.8;
}
.write-comment-on-topic_fullWidth__mnAvN {
  width: 100%;
}

.write-comment-on-topic_text__znY6s {
  color: lightgray;
  font-size: 14px;
  align-self: flex-start;
}

.write-comment-on-topic_display__NV6lv {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  gap: 8px;
}
.write-comment-on-topic_center__7S2U1 {
  border-top: 1px solid gray;
  padding-top: 0.5rem;
  margin: 0.5rem 0rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/home/orderedTasks/components/topics/write-comment-on-topic.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qBAAqB;EACrB,oBAAoB;EACpB,YAAY;EACZ,WAAW;EACX,YAAY;AACd;AACA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".commentField {\n  border: none;\n  border-radius: 0.5rem;\n  padding-left: 0.5rem;\n  height: 2rem;\n  width: 100%;\n  opacity: 0.8;\n}\n.fullWidth {\n  width: 100%;\n}\n\n.text {\n  color: lightgray;\n  font-size: 14px;\n  align-self: flex-start;\n}\n\n.display {\n  display: flex;\n  flex-direction: column;\n  width: 90%;\n  align-items: center;\n  gap: 8px;\n}\n.center {\n  border-top: 1px solid gray;\n  padding-top: 0.5rem;\n  margin: 0.5rem 0rem;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentField": `write-comment-on-topic_commentField__a9TK5`,
	"fullWidth": `write-comment-on-topic_fullWidth__mnAvN`,
	"text": `write-comment-on-topic_text__znY6s`,
	"display": `write-comment-on-topic_display__NV6lv`,
	"center": `write-comment-on-topic_center__7S2U1`
};
export default ___CSS_LOADER_EXPORT___;
