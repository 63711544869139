import { auth } from "../../Configuration/initialize-firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
const login = async (email, password) => {
  const userCredentials = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );
  return userCredentials.user;
};

export default login;
