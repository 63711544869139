// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-user_marginTop__em4V9 {
  margin-top: 7rem;
}
.add-user_center__TaoMo {
  text-align: center;
  padding: 3rem 1rem;
}

.add-user_align__AJQm4 {
  display: flex;
  flex-direction: row;
}
@keyframes add-user_success__qycQf {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.add-user_successMessage__\\+YDtS {
  background: rgb(0, 256, 0, 0.3);
  border-radius: 6px;
  padding: 0.5rem 3rem;
  margin-bottom: 2rem;
  opacity: 1;
  animation-name: add-user_success__qycQf;
  animation-duration: 1s;
}
.add-user_failureMessage__n8OTf {
  background: rgb(256, 0, 0, 0.5);
  border-radius: 6px;
  padding: 0.5rem 3rem;
  margin-bottom: 2rem;
  opacity: 1;
  animation-name: add-user_success__qycQf;
  animation-duration: 1s;
}
`, "",{"version":3,"sources":["webpack://./src/Components/add-user/add-user.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;AACA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,UAAU;EACV,uCAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,UAAU;EACV,uCAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".marginTop {\n  margin-top: 7rem;\n}\n.center {\n  text-align: center;\n  padding: 3rem 1rem;\n}\n\n.align {\n  display: flex;\n  flex-direction: row;\n}\n@keyframes success {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n.successMessage {\n  background: rgb(0, 256, 0, 0.3);\n  border-radius: 6px;\n  padding: 0.5rem 3rem;\n  margin-bottom: 2rem;\n  opacity: 1;\n  animation-name: success;\n  animation-duration: 1s;\n}\n.failureMessage {\n  background: rgb(256, 0, 0, 0.5);\n  border-radius: 6px;\n  padding: 0.5rem 3rem;\n  margin-bottom: 2rem;\n  opacity: 1;\n  animation-name: success;\n  animation-duration: 1s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginTop": `add-user_marginTop__em4V9`,
	"center": `add-user_center__TaoMo`,
	"align": `add-user_align__AJQm4`,
	"successMessage": `add-user_successMessage__+YDtS`,
	"success": `add-user_success__qycQf`,
	"failureMessage": `add-user_failureMessage__n8OTf`
};
export default ___CSS_LOADER_EXPORT___;
