import { setPrograms, setLoading, setError, GetEditProgram, deleteProgramdata } from "./programSlice";
import addTopicClientService from '../../services/program/add-program';
import updateProgramService from "../../services/program/Update_Program";
import getProgram from '../../services/program/get-program';
import TaskAssinByProgrm from "../../services/program/Task-program";
import getProgramByCoachIdService from '../../services/program/get-program-by-client';
import deleteProgramService from "../../services/program/deleteprogram";

// Assuming you have a programSlice object
const programSlice = {
  deleteProgramdata,
};

export const addProgram = async (title, descrption, demoDuration, coachID) => {
  // console.log(demoDuration[0]?.day[0]?.voicerecoding,"fgdgdfgfdgdfgdfgdfgdfgdfgdf");
 try {
    const topicData = {
      title: title,
      descrption: descrption,
      todolist: demoDuration,
      coachId: coachID,
      recordingfile:demoDuration[0]?.day[0]?.voicerecoding,
      createAt: new Date()
    };

    const topic = await addTopicClientService(topicData);

    console.log(topic, "Code is added");

    return topic;
  } catch (e) {
    throw e;
  }
};

export const GetAllProgramByCoach = (coachID) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const programData = await getProgramByCoachIdService(coachID);
    console.log(programData, "programDataprogramData");
    dispatch(setPrograms(programData));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const AssinTaskforClient = (ProgramId, data) => async (dispatch) => {
  try {
    console.log(ProgramId, "ProgramId");
    console.log(data, "data");
    const taskAssign = await TaskAssinByProgrm(ProgramId, data);
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const getProgramId = (programId) => async (dispatch) => {
  try {
    // debugger
    const programData = await getProgram(programId);
    console.log(programData, "hello brother");
    dispatch(GetEditProgram({programData}));
  } catch (error) {
    dispatch(setError(error.message));
  }
};


export const UpdateProgram = async (progamId, title, descrption, demoDuration, coachID) => {
   try {
    const topicData = {
      title: title,
      descrption: descrption,
      todolist: demoDuration,
      coachId: coachID,
      recordingfile:demoDuration[0]?.day[0]?.voicerecoding,
      createAt: new Date()
    };
    
    const topic = await updateProgramService(progamId, topicData);
    return topic;
  } catch (e) {
    throw e;
  }
};

export const deleteprogram = (programId) => async (dispatch) => {
  try {
    const program = await deleteProgramService(programId);
    dispatch(deleteProgramdata({ programId }));
  } catch (e) {
    throw e;
  }
};
