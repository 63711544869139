// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this CSS in your component or global stylesheet */


.Calender_custom_calendar__n\\+B4t{
    color: white; /* Change month and year text color */
    position: relative;            
    margin-left: 150px;
}


.Calender_custom_calendar__n\\+B4t .Calender_fc_toolbar_title__cB\\+HX {
    color: white; /* Change month and year text color */
    border: 1px solid #3d3d3d;
  }
  
  .Calender_custom_calendar__n\\+B4t .Calender_fc_day_header__26H-n {
    color: white; /* Change day of the week text color */
    border: 1px solid #3d3d3d;
  }
  
  .Calender_custom_calendar__n\\+B4t .Calender_fc_day_number__qwb6w {
    color: white; /* Change day number text color */
    border: 1px solid #3d3d3d;
  }


  .Calender_custom_calendar__n\\+B4t .Calender_fc-scrollgrid__T9Z5c{
    border: 1px solid #3d3d3d;
  }

  
  `, "",{"version":3,"sources":["webpack://./src/Pages/Calender/Calender.module.css"],"names":[],"mappings":"AAAA,wDAAwD;;;AAGxD;IACI,YAAY,EAAE,qCAAqC;IACnD,kBAAkB;IAClB,kBAAkB;AACtB;;;AAGA;IACI,YAAY,EAAE,qCAAqC;IACnD,yBAAyB;EAC3B;;EAEA;IACE,YAAY,EAAE,sCAAsC;IACpD,yBAAyB;EAC3B;;EAEA;IACE,YAAY,EAAE,iCAAiC;IAC/C,yBAAyB;EAC3B;;;EAGA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Add this CSS in your component or global stylesheet */\n\n\n.custom_calendar{\n    color: white; /* Change month and year text color */\n    position: relative;            \n    margin-left: 150px;\n}\n\n\n.custom_calendar .fc_toolbar_title {\n    color: white; /* Change month and year text color */\n    border: 1px solid #3d3d3d;\n  }\n  \n  .custom_calendar .fc_day_header {\n    color: white; /* Change day of the week text color */\n    border: 1px solid #3d3d3d;\n  }\n  \n  .custom_calendar .fc_day_number {\n    color: white; /* Change day number text color */\n    border: 1px solid #3d3d3d;\n  }\n\n\n  .custom_calendar .fc-scrollgrid{\n    border: 1px solid #3d3d3d;\n  }\n\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom_calendar": `Calender_custom_calendar__n+B4t`,
	"fc_toolbar_title": `Calender_fc_toolbar_title__cB+HX`,
	"fc_day_header": `Calender_fc_day_header__26H-n`,
	"fc_day_number": `Calender_fc_day_number__qwb6w`,
	"fc-scrollgrid": `Calender_fc-scrollgrid__T9Z5c`
};
export default ___CSS_LOADER_EXPORT___;
