import { addUserToCloudFirestore } from "../users/add-user-to-cloud";
export const addCoachToDB = async (id, userInfo) => {
  if (!id) {
    throw Error("User id is null");
  }
  if (!userInfo.firstName || !userInfo.lastName || !userInfo.role) {
    throw Error("Please check your first name and last name");
  }
  const data = {
    id,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    email: userInfo.email,
    role: userInfo.role,
  };
  await addUserToCloudFirestore(id, data);
};
