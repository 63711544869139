// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash_center__GePkY {
  text-align: center;
}

.splash_img__CJ6yY {
  object-fit: contain;
  width: 12rem;
  height: 12rem;
  margin-top: 10%;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/splash/splash.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,eAAe;AACjB","sourcesContent":[".center {\n  text-align: center;\n}\n\n.img {\n  object-fit: contain;\n  width: 12rem;\n  height: 12rem;\n  margin-top: 10%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": `splash_center__GePkY`,
	"img": `splash_img__CJ6yY`
};
export default ___CSS_LOADER_EXPORT___;
