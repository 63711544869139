// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-clients_display__t1FQo {
    display: grid;
    background-color: whitesmoke;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px 0px;
    border-radius: 10px;
    padding: 10px 30px;
}

.group-clients_pill__4lnmA {
    border-radius: 10px;
    padding: 5px 20px;
    margin: 3px;
    background-color: wheat;
}

.group-clients_pill__4lnmA:hover {
    border: 1px solid red;
}

.group-clients_void__ptxel {
    text-align: center;
    color: rgb(0, 0, 0, 0.5);
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/groups/add-group/group-clients.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;IAC5B,kCAAkC;IAClC,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,wBAAwB;IACxB,WAAW;AACf","sourcesContent":[".display {\n    display: grid;\n    background-color: whitesmoke;\n    grid-template-columns: 1fr 1fr 1fr;\n    gap: 10px 0px;\n    border-radius: 10px;\n    padding: 10px 30px;\n}\n\n.pill {\n    border-radius: 10px;\n    padding: 5px 20px;\n    margin: 3px;\n    background-color: wheat;\n}\n\n.pill:hover {\n    border: 1px solid red;\n}\n\n.void {\n    text-align: center;\n    color: rgb(0, 0, 0, 0.5);\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"display": `group-clients_display__t1FQo`,
	"pill": `group-clients_pill__4lnmA`,
	"void": `group-clients_void__ptxel`
};
export default ___CSS_LOADER_EXPORT___;
