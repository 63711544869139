import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
const groupSlice = createSlice({
    name: "Group",
    initialState: {
        groups: [],
        selectedGroup: undefined,
        groupToEdit: undefined,
    },
    reducers: {
        addGroup(state, action) {
            state.groups.push(action.payload);
        },
        setGroups(state, action) {
            state.groups = [...(action.payload)];
        },
        setSelectedGroup(state, actions) {
            const groupId = actions.payload;
            const group = state.groups.find((e) => e.id === groupId);
            if (!group) {
                throw Error("Client not found");
            }
            state.selectedGroup = group;
        },
        setGroupToEdit(state, actions) {
            state.groupToEdit = state.groups.find((e) => e.id === actions.payload);
        },
        updateGroup(state, actions) {
            const group = actions.payload;
            const groupIndex = findIndex(state.groups, (g) => g.id === group.id);
            if (groupIndex) {
                state.groups[groupIndex] = actions.payload;
            } else {
                throw new Error("Group Not found");
            }
        }
    },
});

export default groupSlice;
export const groupActions = groupSlice.actions;
