import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tasksActions } from "../../../../Store/Coach/tasks/tasks-slice";
import classes from "./client-info.module.css";
import { useState,useEffect } from "react";
import MyCalendar from "../../../Calender/Calender";
import AddTask from "../../../Task/AddTask";
// import { useDispatch, useSelector } from 'react-redux';
// import { getTopicsOfClient } from '../../Store/Coach/tasks/tasks-actions';
import {getTopicsOfClient} from '../../../../Store/Coach/tasks/tasks-actions'

const ClientInfo = (props) => {

  const dispatch = useDispatch();
const [toggle,settoggle]  =useState(false)
 const client = props.client;
 const fetchData = async () => {
  try {
    await dispatch(getTopicsOfClient(client.id)); // Dispatch action
    
    
  } catch (error) {
    console.error('Error fetching topics:', error);
  }
};


useEffect(() => {
  fetchData();
}, [dispatch, client.id]);



  return (
    <div className={classes.display}>
      <div className={classes.main_div}> 
      <div className={classes.button_box}>
        <AddTask  fetchData={fetchData}/>
      </div>
      </div>
      <MyCalendar client={client} fetchData={fetchData}/>
    </div>  
  );
};

export default ClientInfo;
