import classes from "./heading.module.css";
const Heading = () => {
  return (
    <div className={classes.tileHeading}>
      <p className={classes.textInTileHeading}>Group Name</p>
      <p className={classes.textInTileHeading}>Total clients</p>
    </div>
  );
};

export default Heading;
