import { addClientToDB } from "../../../services/client/add-client";
import getClientsWithCoachId from "../../../services/client/get-clients-with-coach-id";
import { createUser } from "../../../services/users/create-user";
import { clientActions } from "./client-slice";
import { MultipleCreateUser } from "../../../services/users/multiple-user-to-clound";
import { addMultipleClientsToDB } from "../../../services/client/multiple-client";

export const createClient = (userInfo) => async (dispatch) => {
  try {
    console.log(userInfo,"12222")
    dispatch(clientActions.error(undefined));
    dispatch(clientActions.isLoading(true));
    console.log("cancel")
    const id = await createUser(userInfo.email, userInfo.password);
    console.log(id,"hello kon")
    await addClientToDB(id, userInfo);
    dispatch(clientActions.isLoading(false));
    dispatch(clientActions.isClientCreationSuccessfully(true));
    setTimeout(function () {
      dispatch(clientActions.isClientCreationSuccessfully(false));
    }, 3000);
  } catch (e) {
    dispatch(clientActions.error(e.message));
    dispatch(clientActions.isLoading(false));
    throw e;
  }
};

export const getClients = (coachId) => async (dispatch) => {
  try {
    const students = await getClientsWithCoachId(coachId);
    students.sort((a, b) => a.firstName.localeCompare(b.firstName));
    dispatch(clientActions.clients(students));
  } catch (e) {
    throw e;
  }
};



export const MultipleCLientAdd =(userInfo)=>async(dispatch)=>{
   try{
     dispatch(clientActions.error(undefined));
     dispatch(clientActions.isLoading(true));

     const ids = await MultipleCreateUser(userInfo);
     await addMultipleClientsToDB(ids, userInfo);
     dispatch(clientActions.isLoading(false));
     dispatch(clientActions.isClientCreationSuccessfully(true));
     setTimeout(function () {
       dispatch(clientActions.isClientCreationSuccessfully(false));
     }, 3000);
   }catch(e){
    dispatch(clientActions.error(e.message));
    dispatch(clientActions.isLoading(false));
    throw e;
   }
}


