import iconType from "../../util/icon-type";
import classes from "./icon-button.module.css";

const IconButton = (props) => {
  const icon =
    props.icon === iconType.delete
      ? require("../../assets/icons/delete.png")
      : props.icon === iconType.close
        ? require("../../assets/icons/close.png")
        : props.icon === iconType.copy
          ? require("../../assets/icons/copy.png")
          : props.icon === iconType.paste
            ? require("../../assets/icons/paste.png")
            : require("../../assets/icons/edit.png");
  return (
    <div className={classes.background} onClick={props.onClick}>
      <img className={classes.icon} src={icon} alt="delete" />
    </div>
  );
};

export default IconButton;
