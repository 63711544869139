import { AddUser } from "../../../Components";
import role from "../../../util/role-enum";

const AddClient = () => {
  return (
    <AddUser
      title="Add a Client"
      buttonText="Create a client"
      role={role.client}
    />
  );
};

export default AddClient;
