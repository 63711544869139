import React, { useState, useEffect } from "react";
import classes from "./Task.module.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import VedioPlayer from "../../Components/VedioPlayer/VedioPlayer";

const cloudName = "db3gzsopp";
const uploadPreset = "u2muuej0";

export default function AddToUploadVideo({ setBtnDisable, setloader, setTask, task,progressBar,
  setprogressbar}) {
  const handleVideoChange = async (e) => {
    if (!e.target.files[0]) {
      toast.error("Please select a file.");
      return;
    }


    console.log(progressBar,"progressBarprogressBar")

    const fileSizeInMB = e.target.files[0].size / (1024 * 1024); // Convert to MB
    const maxFileSize = 100; // Maximum allowed file size in MB
  
    if (fileSizeInMB > maxFileSize) {
      toast.error("File size exceeds 100 MB. Please upload a smaller file.");
      return;
    }

    setBtnDisable(true);
    setloader(true);

    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("upload_preset", uploadPreset);

      let resourceType;
      const fileType = e.target.files[0].type.split("/")[0];

      switch (fileType) {
        case "video":
          resourceType = "video";
          break;
        default:
          console.error("Unsupported file type:", fileType);
          toast.error("Unsupported file type");
          setloader(false);
          return;
      }

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress:event=>{
            setprogressbar(Math.round((100*event.loaded)/event.total))
          }
        }
      );

      setTask((prevTask) => ({
        ...prevTask,
        video: response.data,
      }));
      setprogressbar(0)

      setBtnDisable(false);
      setloader(false);

      toast.success("Uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file");
      setBtnDisable(false);
      setloader(false);
    }
  };

  const closeHandler = () => {
    setTask((prevState) => ({
      ...prevState,
      video: {},
    }));
  };

  useEffect(() => {
    // Any additional cleanup or side effects can be handled here
  }, []);

  return (
    <div>
      <div className={classes.upload__box}>
        <div className={classes.upload__upload__btn_box1}>
          {task.video && Object.keys(task.video).length > 0 ? (
            <>
              {(() => {
                const url = task.video.url;
                const fileExtension = url.split(".").pop().toLowerCase();
                return (
                  <div>
                    <p> Upload Video</p>
                    <div className={classes.iconcsss}>
                      <VedioPlayer url={task.video.url} fileExtension={task.video.format} />
                      <i className="fa-solid fa-xmark" onClick={closeHandler}></i>
                    </div>
                  </div>
                );
              })()}
            </>
          ) : (
            <div className={classes.upload__box}>
              <div className={classes.upload__upload__btn_box}>
                <p> Upload Video</p>
                <label className={classes.upload__btn}>
                  {/* {filename ? "file is selected" : null} */}
                  <i className="fa-solid fa-upload"></i>
                  <input
                    type="file"
                    accept=".avi,.mov, video/quicktime, video/*"
                    className="upload__inputfile"
                    id="image"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      handleVideoChange(event); // Changed function name to handleVideoChange
                    }}
                  />
                  <p className={classes.upload_gallery_text}>Upload from gallery</p>
                </label>
              </div>
              <div className={classes.upload__img_wrap} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
