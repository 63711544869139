import { getApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// const firebaseConfigTest = {
//   apiKey: "AIzaSyCqg8TGg8m7GVEh99jfKwslT9O1KnLa-pM",
//   authDomain: "luc-labarile-web.firebaseapp.com",
//   projectId: "luc-labarile-web",
//   storageBucket: "luc-labarile-web.appspot.com",
//   messagingSenderId: "47327081450",
//   appId: "1:47327081450:web:322d8e28b6b8c1de05b893",
//   measurementId: "G-5N0FTF535K",
// };
const firebaseConfig = {
  apiKey: "AIzaSyAu4kBIdBWVfs8oJPU_XMTYw_jI5gRR3mk",
  authDomain: "labarile-f29b3.firebaseapp.com",
  projectId: "labarile-f29b3",
  storageBucket: "labarile-f29b3.appspot.com",
  messagingSenderId: "1052849558927",
  appId: "1:1052849558927:web:d1e71d6551cf3220e3a7fa",
  measurementId: "G-8GLNCK2S1C",
};

initializeApp(firebaseConfig, "app");
initializeApp(firebaseConfig, "worker-app");

export const app = getApp("app");
export const workerApp = getApp("worker-app");
export const  workerAuth = getAuth(workerApp);
export const auth = getAuth(app);
export const db = getFirestore(app);
