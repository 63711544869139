import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";
import moment from "moment";

const getTopicsByClientIdService = (clientId) => {
  if (!clientId) {
    throw Error("Client id not found");
  }
  return getTopicsByClientId(clientId);
};

const getTopicsByClientId = async (clientId) => {
  console.log(clientId,"getTopicsByClientId")
  const topicRef = collection(db, "topics");
  const q = query(topicRef, where("clientId", "==", clientId));
  const snapshot = await getDocs(q);
  const topics = snapshot.docs
    .map((e) => {
      const data = e.data();
      console.log(data,"lll")
    
      // data.createdAt = moment(data.createdAt.toDate()).format("DD/MM/YYYY");
       return {
         id: e.id,
         ...data,
       };
    })
    // .sort((a, b) => +a.topicOrder - +b.topicOrder);
    console.log(topics,"topicstopics")
  return topics;
};

export default getTopicsByClientIdService;
