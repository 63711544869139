import { useDispatch, useSelector } from "react-redux";
import { addTaskToTopic, sendNotification } from "../../../../../../Store/Coach/tasks/tasks-actions";
import classes from "./add-task.module.css";
import AddFormattingTextField from "../../../../../../Components/UI/add-formatting-text-field";
const AddTask = (props) => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.clients.selectedClient);
  const onSubmit = async (task, description) => {
    addTaskToTopic(task, props.topicId, props.taskOrder)(dispatch).then((taskId) => {
      if (client.deviceToken) {
        sendNotification(props.topicName, description, client.deviceToken, taskId, props.topicId, undefined)(dispatch);
      }
    });
  };
  return (
    <div className={classes.align}>
      <AddFormattingTextField
        placeholder="Add a task here.."
        onSubmit={onSubmit}
      />
      {/* <TextEditor placeholder="Add a task here.." onSubmit={onSubmit} /> */}
    </div>
  );
};

export default AddTask;
