import classes from "./frame-with-date.module.css";
import moment from "moment";
import GroupTopic from "./group-topic";
import AddTopic from "../add-topic";
import { useSelector } from "react-redux";
import React from "react";

const FrameWithDate = ({ date, groupId }) => {
  const allTopics = useSelector((state) => state.topics.topics);
  const topics = allTopics.filter(
    (e) => e.createdAt === moment(date).format("DD/MM/YYYY")
  );
  const addTopic = moment(date).add(1, "day").isAfter(moment()) && (
    <AddTopic
      date={moment(date).toDate()}
      // To preserve the order of topics
      topicOrder={
        (topics && topics.length > 0)
          ? (topics[topics.length - 1].topicOrder ?? topics.length) + 1
          : 1
      }
    />
  );
  return (
    <div className={classes.border}>
      <p className={classes.date}>{date.format("D")}</p>
      <div className={classes.alignTasks}>
        {topics.map((e) => (
          <GroupTopic
            key={e.id}
            name={e.topicName}
            groupId={groupId}
            tasks={e.tasks ?? []}
            topicId={e.id}
            date={date}
          />
        ))}
      </div>
      {addTopic}
    </div>
  );
};

export default React.memo(FrameWithDate);
