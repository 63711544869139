import Comment from "../../../../../../Components/UI/comment";
import classes from "./home-comments.module.css";
const HomeComments = (props) => {
  const comments = (props.comments ?? []).map((e, i) => (
    <Comment comment={e} key={i} />
  ));
   
  return (
       <>
       <div className={classes.align}>{comments}</div>
       {
        //  console.log(props.comments,"codeho")
         props.comments.map((data,i)=>{
            //  console.log(data,"home js")
            if(data.file!=null){
               console.log(data.file.url,"mmmmm")
               if(data.file.format){
                 return(
                  <> 
                    <video  width="320" height="240" controls>
                    <source src={data.file.url} type="video/mp4"/>
                    </video>
                  </>
                 )
               }
            }
         })
       }
       </>
       )
};

export default HomeComments;
