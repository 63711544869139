import React, { useState } from 'react';
import axios from 'axios';

const CloudinaryUpload = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const[btndisable,setbtndisable] =useState(false)

  const cloudName = 'db3gzsopp'; 
  const uploadPreset = 'u2muuej0'; 

  const handleFileChange = async(e) => {
    // setSelectedFile(e.target.files[0]);

    if (!e.target.files[0]) {
      console.error('Please select a file.');
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('upload_preset', uploadPreset);
  
      console.log('Uploading file:', e.target.files[0]);
  
      // Determine resource type based on file type
      let resourceType;
      const fileType = e.target.files[0].type.split('/')[0];
      switch (fileType) {
        case 'image':
          resourceType = 'image';
          break;
        case 'audio':
          resourceType = 'audio';
          break;
        case 'video':
          resourceType = 'video';
          break;
        case 'application':
          resourceType = 'raw'; // Assuming application/octet-stream is used for PDF
          break;
        default:
          console.error('Unsupported file type:', fileType);
          return;
      }
  
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`, // Updated endpoint
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      // Handle the result of the upload, e.g., update state with the uploaded file URL
      console.log(response.data);
      props.updatefile(response.data)
      setbtndisable(true)
      setSelectedFile(response.data)
  
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  

  const handleUpload = async () => {
    props.onSubmit()
    // if (!selectedFile) {
    //   console.error('Please select a file.');
    //   return;
    // }
  
    // try {
    //   const formData = new FormData();
    //   formData.append('file', selectedFile);
    //   formData.append('upload_preset', uploadPreset);
  
    //   console.log('Uploading file:', selectedFile.name);
  
    //   // Determine resource type based on file type
    //   let resourceType;
    //   const fileType = selectedFile.type.split('/')[0];
    //   switch (fileType) {
    //     case 'image':
    //       resourceType = 'image';
    //       break;
    //     case 'audio':
    //       resourceType = 'audio';
    //       break;
    //     case 'video':
    //       resourceType = 'video';
    //       break;
    //     case 'application':
    //       resourceType = 'raw'; // Assuming application/octet-stream is used for PDF
    //       break;
    //     default:
    //       console.error('Unsupported file type:', fileType);
    //       return;
    //   }
  
    //   const response = await axios.post(
    //     `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`, // Updated endpoint
    //     formData,
    //     {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //       },
    //     }
    //   );
  
    //   // Handle the result of the upload, e.g., update state with the uploaded file URL
    //   console.log(response.data.secure_url, "111111111111");
  
    // } catch (error) {
    //   console.error('Error uploading file:', error);
    // }
  };
  

  return (
    <div>
      <input type="file" accept="audio/*,video/*,.pdf" onChange={handleFileChange}  />
      {btndisable?(<button onClick={handleUpload}>Upload File</button>):null}
    </div>
  );
};

export default CloudinaryUpload;
