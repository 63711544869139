// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-topic_center__XDC1W {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #22303d;
  border-radius: 0.5rem;
  border: none;
  margin: 0.5rem 1rem;
  padding-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/tasks/components/Topic/tasks/client-topic.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;AACxB","sourcesContent":[".center {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background: #22303d;\n  border-radius: 0.5rem;\n  border: none;\n  margin: 0.5rem 1rem;\n  padding-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": `client-topic_center__XDC1W`
};
export default ___CSS_LOADER_EXPORT___;
