import React, { useEffect } from 'react';
import classes from './SucessMoadl.module.css'

function SucessModal({ isModalOpen, setIsModalOpen, setshow,fetchData }) {
    useEffect(() => {
        if (isModalOpen) {
            const modal = document.getElementById('exampleModalCenter');
            if (modal) {
                modal.classList.add('show');
                modal.style.display = 'block';
                const backdrop = document.getElementsByClassName('modal-backdrop')[0];
                if (backdrop) backdrop.style.display = 'block';
            }
        } else {
            const modal = document.getElementById('exampleModalCenter');
            if (modal) {
                modal.classList.remove('show');
                modal.style.display = 'none';
                const backdrop = document.getElementsByClassName('modal-backdrop')[0];
                if (backdrop) backdrop.style.display = 'none';
            }
        }
    }, [isModalOpen]);

    const modalclose = () => {
        const modal = document.getElementById('exampleModalCenter');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            const backdrop = document.getElementsByClassName('modal-backdrop')[0];
            if (backdrop) backdrop.style.display = 'none';
            setIsModalOpen(false); // Close the modal by updating state
            setshow(false);
            fetchData()
        }
    };
    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                id="add"
                data-target="#exampleModalCenter"
                style={{ display: "none" }}
            >
                Launch demo modal
            </button>
            <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content " style={{ background: "#535353!important" }}>
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true" onClick={modalclose}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="page-body">
                                <div className={classes.head}>
                                    <img src="/Check.png" alt="" />
                                    <h2>sucess</h2>
                                    <p>
                                    The task has been sent successfully to your clients!
                                        {/* Your task have been saved, would you like to send to other
                                        clients */}
                                    </p>
                                </div>
                                {/* <div className={classes.yesnotbutton}>
                                    <button className={classes.nobtn}>no</button>
                                    <button className={classes.yes}>yes</button>
                                </div> */}
                                <h1 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default SucessModal;
