import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";
import moment from "moment";

const getTopicsByGroupIdService = (groupId) => {
    if (!groupId) {
        throw Error("Group id not found");
    }
    return getTopicsByGroupId(groupId);
};

const getTopicsByGroupId = async (groupId) => {
    const topicRef = collection(db, "topics");
    const q = query(topicRef, where("groupId", "==", groupId));
    const snapshot = await getDocs(q);
    const topics = snapshot.docs
        .map((e) => {
            const data = e.data();
            data.createdAt = moment(data.createdAt.toDate()).format("DD/MM/YYYY");
            return {
                id: e.id,
                ...data,
            };
        })
        .sort((a, b) => +a.topicOrder - +b.topicOrder);
    return topics;
};

export default getTopicsByGroupIdService;
