// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coaches_center__CWKm5 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.coaches_error__MLdMa {
  color: darkred;
  text-align: center;
  width: 100%;
}

.coaches_noCoaches__T71nu {
  color: white;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/admin-dashboard/coaches/coaches.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".center {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  margin-top: 1rem;\n}\n\n.error {\n  color: darkred;\n  text-align: center;\n  width: 100%;\n}\n\n.noCoaches {\n  color: white;\n  width: 100%;\n  text-align: center;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": `coaches_center__CWKm5`,
	"error": `coaches_error__MLdMa`,
	"noCoaches": `coaches_noCoaches__T71nu`
};
export default ___CSS_LOADER_EXPORT___;
