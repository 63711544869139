import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Topics from "./components/Topic/topics";
import ClientInfo from "./components/client-info";
import classes from "./main.module.css";
import OrderedListOfClients from "../home/clients-in-tasks/clients-section";
const TasksPage = () => {
  const { id } = useParams();
  const clients = useSelector((state) => state.clients.clients);
  let client = clients.find((e) => e.id === id);
  return (
    <div className={classes.background}>
      {id ? (
        client ? (
          <div style={{display:'flex',marginTop:'4rem'}}>
            <OrderedListOfClients/>
            <ClientInfo client={client} />
          {/* <Topics clientId={client.id} /> */}
          </div>
        ) : (
          <p className={classes.error}>Client not found</p>
        )
      ) : (
        <p className={classes.error}>Id in the params is null</p>
      )}
    </div>
  );
};

export default TasksPage;
