import classes from "./heading.module.css";
const Heading = () => {
  return (
    <div className={classes.tileHeading}>
      <p className={classes.textInTileHeading}>First Name</p>
      <p className={classes.textInTileHeading}>Last Name</p>
      <p className={classes.textInTileHeading}>Email</p>
    
    </div>
  );
};

export default Heading;
