import { useDispatch } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { clientActions } from "../../../Store/Coach/Client/client-slice";
import classes from "./client-tile.module.css";
const ClientTile = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = props.client;
  const onClickHandler = (event) => {
    event.preventDefault();
    dispatch(clientActions.setSelectedClient(client.id));
    const path = generatePath("tasks/:id", { id: client.id });
    navigate(path);
  };
  console.log(props.Notes,"help pont")
  return (
    <div className={classes.tile} onClick={onClickHandler}>
      <p className={classes.textInTile}>{client.firstName}</p>
      <p className={classes.textInTile}>{client.lastName}</p>
      <p className={classes.textInTile}>{client.email}</p>
      <p className={classes.textInTile}>{client.notes}</p>    
    </div>
  );
};

export default ClientTile;
