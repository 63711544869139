import { useNavigate, useLocation } from "react-router";
import classes from "./client.module.css";
import React from "react";

const Client = (props) => {
  const { name, id: clientId, scrollId, email } = props;

  const imageName = name
    .split(" ")
    .map((e) => e[0])
    .join("")
    .toUpperCase();

  let image;

  if (!imageName) {
    image = (
      <img
        className={classes.image}
        src={require("../../../../assets/images/users.jpg")}
        alt="US"
      />
    );
  } else {
    image = (
      <div className={classes.background}>
        <p className={classes.center}>{imageName}</p>
      </div>
    );
  }

  const navigate = useNavigate();
  const location = useLocation();
  const parts = location.pathname.split('/');
  const taskId = parts[parts.length - 1];

  const handleClickScroll = (id) => {
    if (location.pathname === "/coach-dashboard") {
      const element = document.getElementById(scrollId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }else{
      navigate(`/coach-dashboard/clients/tasks/${id}`);
    }
  };

  return (
    <div
      className={classes.align}
      style={taskId === clientId ? { border: "1px solid white" } : null}
      onClick={() => {
        handleClickScroll(clientId);
      }}
    >
      <div>{image}</div>
      <div className={classes.textSizing}>
        <p className={classes.title}>{name}</p>
        <p className={classes.subtitle}>{email}</p>
      </div>
    </div>
  );
};

export default React.memo(Client);
