import useInput from "../../../../Hooks/use-input";
import classes from "./form.module.css";
import { TextFormField, Button, LoadingButton } from "../../../../Components/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import role from "../../../../util/role-enum";
import GroupClients from "./group-clients";
import { addGroup, editGroup } from "../../../../Store/Coach/groups/group-actions";
import { useEffect, useState } from "react";
import { auth } from '../../../../Configuration/initialize-firebase'; // Import Firebase authentication from initialize-firebase.js

const GroupForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [clientsIdToRemoveGroup, setClientsIdToRemoveGroup] = useState([]);
  let [addedClients, setAddedClients] = useState([]);
  const allclients = useSelector((state) => state.clients.clients);
  const [filteredClients, setFilteredClients] = useState([]);
  const groupToEdit = useSelector((state) => state.groups.groupToEdit);
  const isLoading = useSelector((state) =>
    props.role === role.client
      ? state.clients.isLoading
      : state.coaches.isLoading
  );
  let {
    valueChangeHandler: groupNameChangeHandler,
    valueBlurHandler: groupNameBlurHandler,
    reset: groupNameReset,
    value: groupName,
    set: setGroupName,
  } = useInput((value) => value.trim().length > 0);
  const {
    valueChangeHandler: clientsChangeHandlerHook,
    valueBlurHandler: clientsBlurHandler,
    reset: clientsReset,
    value: clients,
  } = useInput((value) => value.trim().length > 0);
  useEffect(() => {
    if (groupToEdit) {
      setGroupName(groupToEdit.name);
      setAddedClients(groupToEdit.clients);
    }
  }, []);

  const resetForm = () => {
    groupNameReset();
    clientsReset();
  };

  const clientsChangeHandler = (event) => {
    const searchTerm = event.target.value;
    clientsChangeHandlerHook(event);
    if (searchTerm) {
      setFilteredClients([...(allclients.filter((c) => ((addedClients.filter((e) => (
        e?.id === c?.id
      )).length === 0) &&
        (c?.firstName?.toLowerCase()?.includes(searchTerm.toLowerCase())
          || c?.lastName?.toLowerCase()?.includes(searchTerm.toLowerCase())
          || c?.email?.toLowerCase()?.includes(searchTerm.toLowerCase())
        ))))]);
    } else {
      setFilteredClients([]);
    }
  };

  const isFormValid = addedClients.length > 0;

  const onClientAddedToGroup = (client) => {
    setFilteredClients(filteredClients.filter((e) => e.id !== client.id));
    setAddedClients([...addedClients, client]);
  };

  const onRemoveClient = async (id) => {
    try {
      if (groupToEdit) {
        setClientsIdToRemoveGroup([...new Set([...clientsIdToRemoveGroup, id])]);
      }
      setAddedClients(addedClients.filter((e) => e.id !== id));
    } catch (e) {
      throw e;
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isFormValid) {
      const currentUser = auth.currentUser;
      const userId = currentUser ? currentUser.uid : null; // Get user ID if logged in
  
      const groupInfo = {
        name: groupName,
        clients: addedClients.map((e) => ({ email: e.email, id: e.id })),
        userId: userId // Include user ID in the data
      };

      try {
        if (groupToEdit) {
          groupInfo.id = groupToEdit.id;
          groupInfo.clientsIdToRemoveGroup = [...clientsIdToRemoveGroup];
          await editGroup(groupInfo)(dispatch);
        } else {
          await addGroup(groupInfo)(dispatch);
        }
        navigate(-1);
        resetForm();
      } catch (e) {
        throw e;
      }
    }
  };

  return (
    <form method="submit" onSubmit={onSubmit} className={classes.formAlignment}>
      <div>
        <TextFormField
          type="text"
          placeholder="Group Name"
          isFormFieldExtra={true}
          onChange={groupNameChangeHandler}
          onBlur={groupNameBlurHandler}
          value={groupName}
        />
      </div>
      <div>
        <TextFormField
          type="text"
          placeholder="Search and add clients to group"
          isFormFieldExtra={true}
          onChange={clientsChangeHandler}
          onBlur={clientsBlurHandler}
          value={clients}
        />
        {filteredClients.length > 0 &&
          <div className={classes.suggestionsBox}>
            {filteredClients.map((client) => <div key={client.id} className={classes.suggestion} onClick={() => onClientAddedToGroup(client)}>
              <p>{client.firstName}</p>
              <p>{client.email}</p>
            </div>)}
          </div>
        }
      </div>
      <div>
        <GroupClients clients={addedClients} onpillTab={onRemoveClient} />
      </div>
      {isLoading ? <LoadingButton /> : <Button text={groupToEdit ? "Edit a Group" : "Create Group"} />}
    </form>
  );
};

export default GroupForm;
