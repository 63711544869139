import moment from "moment";
import { collection, addDoc,getDoc,setDoc,updateDoc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

export const addTopicClientService = async (topicInfo) => {
  // if (
  //   !topicInfo ||
  //   !topicInfo.task_name ||
  //   !topicInfo.todolist ||
  //   !topicInfo.clientId ||
  //   !topicInfo.coachId ||
  //   !topicInfo.createdAt ||
  //   !topicInfo.topicOrder
  // ) {
  //   throw Error("Topic Data is incorrect");
  // }
  return await addTopicService(topicInfo);
}

export const addTopicGroupService = async (topicInfo) => {
  if (
    !topicInfo ||
    !topicInfo.topicName ||
    !topicInfo.groupId ||
    !topicInfo.coachId ||
    !topicInfo.createdAt ||
    !topicInfo.topicOrder
  ) {
    throw Error("Topic Data is incorrect");
  }
  return await addTopicService(topicInfo);
}


const addTopicService = async (topicInfo) => {
  const response = await addTopicToCloud(topicInfo);
  // topicInfo.createdAt = moment(topicInfo.createdAt).format("DD/MM/YYYY");
  // return {
  //   id: response.id,
  //   // ...topicInfo,
  // };
};

const addTopicToCloud = async (topicData) => {


   let topicInfo={
     clientId:topicData.clientId,
     coachId:topicData.coachId,
     createdAt:topicData.createdAt,
     topicName:topicData.task_name,
     topicOrder:1
   }
  console.log(topicInfo,"topicInfo")
   const docRef = await addDoc(collection(db, "topics"), topicInfo);
   const addedTopicId = docRef.id;
   console.log(addedTopicId,"addedTopicId")

   await setDoc(docRef, { ...topicInfo, id: addedTopicId }, { merge: true });


   let taskInfo={
    description:topicData.todolist,
    Pdf:topicData.Pdf,
    video:topicData.video,
    recordingfile:topicData.recordingfile,
    status:topicData.status,
    comments:[],
    taskOrder:1,
    createdAt:topicData.createdAt
   }

   console.log(taskInfo,"taskInfo")


   const topicRef = collection(db, "topics");
   const taskRef = collection(topicRef, addedTopicId, "tasks");
   console.log("hello")
   const taskDocRef = await addDoc(taskRef, taskInfo);
   const addedTaskId = taskDocRef.id;
   console.log(addedTaskId,"addedTaskIdaddedTaskIdaddedTaskId")
   await updateDoc(taskDocRef, { id: addedTaskId });
};



