import Greetings from "./components/greetings";
import DateFrames from "./components/topics/date-frames";
import classes from "./ordered-task.module.css";

const OrderedTasks = () => {
  return (
    <div className={classes.margin}>
      <Greetings />
      <DateFrames />
    </div>
  );
};

export default OrderedTasks;
