import AdminMenu from "./menu/admin-coach-menu";
import { Outlet } from "react-router-dom";
const AdminDashboard = () => {
  return (
    <div>
      <AdminMenu />
      <Outlet />
    </div>
  );
};
export default AdminDashboard;
