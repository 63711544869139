// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading_tileHeading__nRQ0i {
  padding: 0.5rem;
  margin: 2px 0px;
  background-color: #19222f;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: flex-start;
  gap: 5rem;
  width: 100%;
}
.heading_textInTileHeading__iyaNW {
  color: white;
  font-size: 16px;
  width: 7rem;
  text-align: justify;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/clients/heading.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,yCAAyC;EACzC,uBAAuB;EACvB,SAAS;EACT,WAAW;AACb;AACA;EACE,YAAY;EACZ,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".tileHeading {\n  padding: 0.5rem;\n  margin: 2px 0px;\n  background-color: #19222f;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between !important;\n  align-items: flex-start;\n  gap: 5rem;\n  width: 100%;\n}\n.textInTileHeading {\n  color: white;\n  font-size: 16px;\n  width: 7rem;\n  text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tileHeading": `heading_tileHeading__nRQ0i`,
	"textInTileHeading": `heading_textInTileHeading__iyaNW`
};
export default ___CSS_LOADER_EXPORT___;
