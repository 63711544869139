// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_dashboard__1S2Jc {
  background: #192230;
  height: 150vh;
  width: 100%;
  overflow-y: scroll;
}
.home_dashboard__1S2Jc::-webkit-scrollbar {
  width: 0.5rem;
}

.home_dashboard__1S2Jc::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.home_dashboard__1S2Jc::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.7);
}

.home_align__bNzhY {
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/home/home.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".dashboard {\n  background: #192230;\n  height: 150vh;\n  width: 100%;\n  overflow-y: scroll;\n}\n.dashboard::-webkit-scrollbar {\n  width: 0.5rem;\n}\n\n.dashboard::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);\n}\n\n.dashboard::-webkit-scrollbar-thumb {\n  background-color: rgba(169, 169, 169, 0.7);\n}\n\n.align {\n  display: flex;\n  flex-direction: row;\n  margin-top: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard": `home_dashboard__1S2Jc`,
	"align": `home_align__bNzhY`
};
export default ___CSS_LOADER_EXPORT___;
