import React, { useState, useEffect, useRef } from "react";
import classes from "./create-program-task.module.css";
import { v4 as uuidv4 } from "uuid";
import Voicerecoding from "../../Task/VoicedRecoder";
import { AudioRecorder } from "react-audio-voice-recorder";
// import addProgramToCloud  from '../../../Store/Coach/tasks/tasks-actions'
import { addProgram } from "../../../Store/program/program-action";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

// import { AudioRecorder } from 'react-audio-voice-recorder';

const cloudName = "db3gzsopp";
const uploadPreset = "u2muuej0";

export default function CreateProgrmatask() {
  const dispatch = useDispatch();
  const [title, settitle] = useState("");
  const [descrption, setdescrption] = useState("");
  const [demoDuration, setDemoDuration] = useState([]);
  const [file, setfile] = useState();
  const [microphoneAvailable, setMicrophoneAvailable] = useState(true);
  const audioRecorderRef = useRef(null);
  let programData = useSelector((state) => state.programs.programs);
  const [loader, setloader] = useState(false);
  const [progressBar,setprogressbar] =useState(0)

  const [recordingStarted, setRecordingStarted] = useState(false);  


  console.log(recordingStarted, "recordingStarted35637485")
  

  // const [recordedAudio, setrecordedAudio] = useState()
  // console.log(programData.length, "demoDuration");
  const navigate = useNavigate();

  const coach = useSelector((state) => state.auth.userInfo);

  useEffect(() => {
    const storedDuration = parseInt(window.localStorage.getItem("duration"));
    if (Number.isInteger(storedDuration) && storedDuration > 0) {
      const newDuration = Array.from({ length: storedDuration }, () => ({
        id: uuidv4(),
        day: [
          {
            id: uuidv4(),
            name: "",
            todolist: "",
            Pdf: "",
            voicerecoding: "",
            loader: false,
            video: "", // Typo corrected from 'voicerecoding' to 'voicerecording'
          },
        ],
      }));
      setDemoDuration(newDuration);
    }
  }, []);

  const addDay = () => {
    if (demoDuration.length >= 90) {
      alert("Only 90 days of data can be added.");
    } else {
      const newEntry = {
        id: uuidv4(),
        day: [
          {
            id: uuidv4(),
            name: "",
            todolist: "",
            Pdf: "",
            voicerecoding: "",
            loader: false,
            video: "",
          },
        ],
      };
      setDemoDuration((prevDuration) => [...prevDuration, newEntry]);
    }
  };

  const removeDay = (dayIndex) => {
    setDemoDuration((prevState) => {
      let demoArray = [...prevState];
      let filterDate = demoArray.filter((i) => i.id != dayIndex);
      return [...filterDate];
    });
  };

  const addDemoTask = (event, id) => {
    event.stopPropagation();
    let index = demoDuration.findIndex((i) => i.id == id);
    if (index != -1) {
      if (demoDuration[index].day.length >= 3) {
        alert("Only 3 tasks can be added per day.");
      } else {
        let newEntry = {
          id: uuidv4(),
          name: "",
          todolist: "",
          Pdf: "",
          voicerecoding: "",
          loader: false,
          video: "",
        };
        let chlildData = { ...demoDuration[index] };
        chlildData.day.push(newEntry);
        let newData = demoDuration.map((i) => {
          if (i.id == chlildData.id) return chlildData;
          else return i;
        });
        let data = newData;
        setDemoDuration([...newData]);
      }
    }
  };

  // Function to delete logCounter value from localStorage
const deleteLogCounter = () => {
  localStorage.removeItem('logCounter');
  localStorage.removeItem('oldParentIds');
};

// Attach event listener to the window unload event
window.addEventListener('unload', deleteLogCounter);


  const removeDemoTask = (ParentId, childId) => {
    setDemoDuration((prevState) => {
      let demoArray = [...prevState];
      let index = demoArray.findIndex((i) => i.id == ParentId);
      if (index != -1) {
        let filterData = demoArray[index].day.filter((i) => i.id != childId);
        console.log(filterData,"filterData")
        demoArray[index].day = [...filterData];
        console.log(demoArray[index].day )
      }
      return [...demoArray];
    });
  };

  const TodoListTaksHandler = (event, ParentId, childId) => {
    setDemoDuration((prevState) => {
      let demoArray = [...prevState];
      let index = demoArray.findIndex((i) => i.id === ParentId);
      if (index !== -1) {
        let filteredDay = demoArray[index].day.filter((i) => i.id === childId);
        if (filteredDay.length > 0) {
          // Assuming event.target.name corresponds to the property name you want to update
          if (event.target.name === "name") {
            filteredDay[0].name = event.target.value; // Update 'name' property
          } else if (event.target.name === "todo") {
            filteredDay[0].todolist = event.target.value; // Update 'todolist' property
          }

          // Update the filtered data back into the original array

          console.log("filteredDay", filteredDay);
          demoArray[index].day = demoArray[index].day.map((item) => {
            if (item.id === childId) {
              return filteredDay[0];
            }
            return item;
          });
        }
      }
      return [...demoArray]; // Return the updated array
    });
  };

  const submitDataHandler = async () => {
    try {
        // if (recordingStarted) {
      //   toast.error("Please save recording before submitting.");
      //   return;
      // }

      if (!demoDuration[0]?.day[0]?.todolist || !demoDuration[0]?.day[0]?.name || !title || !descrption || demoDuration.length === 0 || !coach.id) {
        toast.error("Please fill in all required fields.");
        return;
      }
  
      // Get logCounter value from localStorage
      let logCounter = localStorage.getItem('logCounter') || 0;
      logCounter = parseInt(logCounter);
  
      if (logCounter === 0) {
        // If logCounter is 0, save data and reset localStorage value
        localStorage.setItem('logCounter', 0); // Reset logCounter to 0
        setloader(true);
        await dispatch(addProgram(title, descrption, demoDuration, coach.id));
        setloader(false);
        toast.success("Program Added Successfully");
        localStorage.removeItem('logCounter');
        localStorage.removeItem('oldParentIds');
        navigate("/coach-dashboard/program");
      } else {
         // If logCounter is not 0, show toast message
        toast.error("Please save recording before submitting.");
        return;
      }
    } catch (error) {
      setloader(false);
      console.log(error);
      navigate("/coach-dashboard/program");
    }
  };
  
  

  const uploadPdfFile = async (event, ParentId, childId) => {
    // setfile(event.target.files[0])
    const fileSizeInMB = event.target.files[0].size / (1024 * 1024); // Convert to MB
    const maxFileSize = 100; // Maximum allowed file size in MB
  
    if (fileSizeInMB > maxFileSize) {
      toast.error("File size exceeds 100 MB. Please upload a smaller file.");
      return;
    }

    setloader(true);
    console.log(event.target.files[0], "hjkjh");
    setDemoDuration((prevState) => {
      let demoArray = [...prevState];
      let index = demoArray.findIndex((i) => i.id === ParentId);
      if (index !== -1) {
        let filteredDay = demoArray[index].day.filter((i) => i.id === childId);
        if (filteredDay.length > 0) {
          filteredDay[0].loader = true;
          console.log("filteredDay", filteredDay);
          demoArray[index].day = demoArray[index].day.map((item) => {
            if (item.id === childId) {
              return filteredDay[0];
            }
            return item;
          });
        }
      }
      return [...demoArray]; // Return the updated array
    });

    let demoArray = [...demoDuration];
    let index = demoArray.findIndex((i) => i.id === ParentId);
    if (index !== -1) {
      let filteredDay = demoArray[index].day.filter((i) => i.id === childId);
      try {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        formData.append("upload_preset", uploadPreset);
        let resourceType;
        const fileType = event.target.files[0].type;
        if (fileType.includes("video")) {
          resourceType = "video";
        } else if (fileType.includes("application/pdf")) {
          resourceType = "raw";
        } else if (fileType.includes("image")) {
          resourceType = "image";
        } else {
          console.error("Unsupported file type:", fileType);
          toast.error("Unsupported file type");
          setloader(false);
          return;
        }
        console.log(resourceType, "resourceTyperesourceType");
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress:event=>{
              setprogressbar(Math.round((100*event.loaded)/event.total))
            }
          }
        );
        console.log(response.data, "rrr");
        setDemoDuration((prevState) => {
          let demoArray = [...prevState];
          let index = demoArray.findIndex((i) => i.id === ParentId);
          if (index !== -1) {
            let filteredDay = demoArray[index].day.filter(
              (i) => i.id === childId
            );
            if (filteredDay.length > 0) {
              filteredDay[0].Pdf = response.data;
              filteredDay[0].loader = false;
              console.log("filteredDay", filteredDay);
              demoArray[index].day = demoArray[index].day.map((item) => {
                if (item.id === childId) {
                  return filteredDay[0];
                }
                return item;
              });
            }
          }
          return [...demoArray]; // Return the updated array
        });
        toast.success("uploaded Sucessfully");
        setprogressbar(0)
        // setRecordingStarted(false)
        setloader(false);
      } catch (error) {
        toast.error("Please upload a file and ensure that the image is no larger than 10MB.");
        // setloader(false)
      }
    }
  };

  const uploadVediofFile = async (event, ParentId, childId) => {
    const fileSizeInMB = event.target.files[0].size / (1024 * 1024); // Convert to MB
    const maxFileSize = 100; // Maximum allowed file size in MB
  
    if (fileSizeInMB > maxFileSize) {
      toast.error("File size exceeds 100 MB. Please upload a smaller file.");
      return;
    }

    setloader(true);
    console.log(event.target.files[0], "hjkjh");
    setDemoDuration((prevState) => {
      let demoArray = [...prevState];
      let index = demoArray.findIndex((i) => i.id === ParentId);
      if (index !== -1) {
        let filteredDay = demoArray[index].day.filter((i) => i.id === childId);
        if (filteredDay.length > 0) {
          filteredDay[0].loader = true;
          console.log("filteredDay", filteredDay);
          demoArray[index].day = demoArray[index].day.map((item) => {
            if (item.id === childId) {
              return filteredDay[0];
            }
            return item;
          });
        }
      }
      return [...demoArray]; // Return the updated array
    });

    let demoArray = [...demoDuration];
    let index = demoArray.findIndex((i) => i.id === ParentId);
    if (index !== -1) {
      let filteredDay = demoArray[index].day.filter((i) => i.id === childId);
      try {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        formData.append("upload_preset", uploadPreset);
        let resourceType;
        const fileType = event.target.files[0].type;
        console.log(fileType, "fileTypefileType");
        switch (fileType) {
            case "video/mp4":
            case "video/quicktime":
            case "video/x-msvideo":
            resourceType = "video";
            break; // Add a break statement to prevent falling through
          default:
            console.error("Unsupported file type:", fileType);
            toast.error("Unsupported file type");
            //   setloader(false)
            return;
        }
        console.log(resourceType, "resourceTyperesourceType");
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress:event=>{
              setprogressbar(Math.round((100*event.loaded)/event.total))
            }
          }
        );
        console.log(response.data, "rrr");
        setDemoDuration((prevState) => {
          let demoArray = [...prevState];
          let index = demoArray.findIndex((i) => i.id === ParentId);
          if (index !== -1) {
            let filteredDay = demoArray[index].day.filter(
              (i) => i.id === childId
            );
            if (filteredDay.length > 0) {
              filteredDay[0].video = response.data;
              filteredDay[0].loader = false;
              console.log("filteredDay", filteredDay);
              demoArray[index].day = demoArray[index].day.map((item) => {
                if (item.id === childId) {
                  return filteredDay[0];
                }
                return item;
              });
            }
          }
          return [...demoArray]; // Return the updated array
        });
        toast.success("uploaded Sucessfully");
        setprogressbar(0)
        // setRecordingStarted(false)
        setloader(false);
      } catch (error) {
        toast.error(error.message);
        // setloader(false)
      }
    }
  };

  const addAudioElement = async (audioElement, ParentId, childId) => {
 
    setDemoDuration((prevState) => {
      let demoArray = [...prevState];
      let index = demoArray.findIndex((i) => i.id === ParentId);
      if (index !== -1) {
        let filteredDay = demoArray[index].day.filter((i) => i.id === childId);
        if (filteredDay.length > 0) {
          filteredDay[0].loader = true;
          console.log("filteredDay", filteredDay);
          demoArray[index].day = demoArray[index].day.map((item) => {
            if (item.id === childId) {
              return filteredDay[0];
            }
            return item;
          });
        }
      }
      return [...demoArray]; // Return the updated array
    });
  
    let demoArray = [...demoDuration];
    let index = demoArray.findIndex((i) => i.id === ParentId);
    if (index !== -1) {
      let filteredDay = demoArray[index].day.filter((i) => i.id === childId);
      try {
        const formData = new FormData();
        formData.append("file", audioElement);
        formData.append("upload_preset", uploadPreset);
        let resourceType = "audio/webm;codecs=opus";
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: event => {
              setprogressbar(Math.round((100 * event.loaded) / event.total))
            }
          }
        );
      
        if (response.data) {
          console.log(response.data,"dfgfdgfdgfd");
          // Decrement logCounter by 2 if response data exists
          let logCounter = localStorage.getItem('logCounter') || 0;
          logCounter = parseInt(logCounter);
          if (logCounter >= 1) {
              logCounter -= 1; // Decrement by 1

                          // Retrieve oldParentIds array from localStorage or initialize an empty array if it doesn't exist
            let oldParentIds = JSON.parse(localStorage.getItem('oldParentIds')) || [];

            // Find the index of the providedParentId in the oldParentIds array
            let indexToDelete = oldParentIds.indexOf(ParentId);

            // Check if the providedParentId exists in the array
            if (indexToDelete !== -1) {
                // Delete the providedParentId from the oldParentIds array
                oldParentIds.splice(indexToDelete, 1);
                // Update the oldParentIds array in localStorage
                localStorage.setItem('oldParentIds', JSON.stringify(oldParentIds));
                console.log("Provided ParentId deleted from oldParentIds array.");
            }

          } else {
              logCounter = 0; // Set to 0 if counter is less than 1
          }
          localStorage.setItem('logCounter', logCounter);
      }
  
        setDemoDuration((prevState) => {
          let demoArray = [...prevState];
          let index = demoArray.findIndex((i) => i.id === ParentId);
          if (index !== -1) {
            let filteredDay = demoArray[index].day.filter((i) => i.id === childId);
            if (filteredDay.length > 0) {
              filteredDay[0].voicerecoding = response.data;
              filteredDay[0].loader = false;
              console.log("filteredDay", filteredDay);
              demoArray[index].day = demoArray[index].day.map((item) => {
                if (item.id === childId) {
                  return filteredDay[0];
                }
                return item;
              });
            }
          }
          return [...demoArray]; // Return the updated array
        });
        toast.success("Uploaded Successfully");
        setprogressbar(0);
        setloader(false);
      } catch (error) {
        console.log(error.message);
        setloader(false);
      }
    }
  };
  

  const handleMicrophonePermission = async (ParentId, childId) => {
    console.log(ParentId, "abcsheenu", childId, "abcsheenu");
    // Set ParentId in localStorage
    localStorage.setItem('ParentId', ParentId);
    // Retrieve the array of old ParentIds from localStorage or initialize an empty array
    let oldParentIds = JSON.parse(localStorage.getItem('oldParentIds')) || [];
    
    // Check if ParentId already exists in localStorage
    if (oldParentIds.includes(ParentId)) {
        // If ParentId already exists in oldParentIds array, do not save logCounter
        console.log("ParentId already exists in oldParentIds array. logCounter not incremented.");
    } else {
        // If ParentId does not exist in oldParentIds array, save logCounter and update oldParentIds array
        let logCounter = localStorage.getItem('logCounter') || 0; // Retrieve counter from localStorage or initialize to 0
        logCounter++; // Increment the counter when stream is obtained
        console.log("Log count:", logCounter); // Print the log count
        localStorage.setItem('logCounter', logCounter); // Store the updated counter in localStorage
        oldParentIds.push(ParentId); // Add ParentId to the oldParentIds array
        localStorage.setItem('oldParentIds', JSON.stringify(oldParentIds)); // Update oldParentIds array in localStorage
    }
    

    try {
     // if (recordingStarted) {
      //   // If recording is already in progress, display an error message
      //   toast.error("Please save the current recording before starting a new one.");
      //   return;
      // }
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        toast.error("Microphone is not supported in this Device.");
        return;
      }
  
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  
      console.log(stream,"streamstreamstreamstreamstream");
      if (stream) {
        setRecordingStarted(true);
        audioRecorderRef.current.startRecording();
      } else {
        toast.error("Microphone permission denied or unavailable.");
      }
    } catch (error) {
      if (error.name === "NotAllowedError") {
        toast.error(
          "Microphone permission denied. Please allow microphone access to start recording."
        );
      } else {
        console.error("Error accessing microphone:", error);
      }
    }
  };
  

//   useEffect(() => {
//   handleMicrophonePermission();
// }, [recordingStarted]); // Run only once when the component mounts

  const cancelHandler = (type, ParentId, childId) => {

    let logCounter = localStorage.getItem('logCounter') || 0;
          logCounter = parseInt(logCounter);
    if (logCounter >= 1) {
      logCounter -= 1; // Decrement by 1

                  // Retrieve oldParentIds array from localStorage or initialize an empty array if it doesn't exist
    let oldParentIds = JSON.parse(localStorage.getItem('oldParentIds')) || [];

    // Find the index of the providedParentId in the oldParentIds array
    let indexToDelete = oldParentIds.indexOf(ParentId);

    // Check if the providedParentId exists in the array
    if (indexToDelete !== -1) {
        // Delete the providedParentId from the oldParentIds array
        oldParentIds.splice(indexToDelete, 1);
        // Update the oldParentIds array in localStorage
        localStorage.setItem('oldParentIds', JSON.stringify(oldParentIds));
        console.log("Provided ParentId deleted from oldParentIds array.");
    }

  } else {
      logCounter = 0; // Set to 0 if counter is less than 1
  }


    setDemoDuration((prevState) => {
      let demoArray = [...prevState];
      let index = demoArray.findIndex((i) => i.id === ParentId);
      if (index !== -1) {
        let filteredDay = demoArray[index].day.filter((i) => i.id === childId);
        if (filteredDay.length > 0) {
          if (type === "pdf") {
            filteredDay[0].Pdf = "";
          } else if (type === "video") {
            filteredDay[0].video = "";
          } else {
            filteredDay[0].voicerecoding = "";
          }

          demoArray[index].day = demoArray[index].day.map((item) => {
            if (item.id === childId) {
              return filteredDay[0];
            }
            return item;
          });
        }
      }
      return [...demoArray]; // Return the updated array
    });
  };

  // const handleSaveAudio = () => {
  //     // Access the audioRecorderRef.current to trigger the save functionality
  //     console.log(audioRecorderRef.current,"audioRecorderRef.current")
  //     debugger
  //     if (audioRecorderRef.current) {
  //         audioRecorderRef.current.save(); // This is an example; use the actual save function provided by your library
  //     }
  //   };

  const openPdfHandler = (url) => {
    window.open(url, "_blank");
  };

  const openVedioHandler = (url) => {
    window.open(url, "_blank");
  };

  const handleRecordingComplete = () => {
    setRecordingStarted(false); // Reset recording status when recording is complete
  };
  
  const handleRecordingCancel  = () => {
    console.log(audioRecorderRef.current,"sdfdgfdgdfgfdgdfg");
    setRecordingStarted(false); // Reset recording status when recording is cancelled
  };

  return (
    <div className={classes.main}>
      <div className={classes.program_text}>
        <h2>Program</h2>
      </div>
      <div className={classes.task}>
        <div className="form-group">
          <label htmlFor="Title">Title</label>

          <input
            type="text"
            className="form-control"
            id="Title"
            placeholder="Enter Title"
            onChange={(event) => {
              settitle(event.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="Title">Description</label>

          <input
            type="text"
            className="form-control"
            id="Description"
            aria-describedby="emailHelp"
            placeholder="Enter Description"
            onChange={(event) => {
              setdescrption(event.target.value);
            }}
          />
        </div>
      </div>
      <div className={classes.to_dolist}>
        <h3 style={{ color: "white" }}>To Do List</h3>
        <div className={classes.plus_button}>
          {demoDuration.length > 0 && demoDuration.length < 90 ? (
            <button onClick={addDay} style={{}}>
              <i className="fa fa-plus"></i>
            </button>
          ) : null}
        </div>
      </div>

      {demoDuration.length > 0 &&
        demoDuration.map((itemParent, index1) => {
          return (
            <div className={classes.tablelist} key={itemParent.id}>
              <div className={classes.dayuser_list}>
                <h3> Days {index1 + 1}</h3>
                <div className={classes.plus_button}>
                  {demoDuration.length > 1 && (
                    <button
                      style={{ background: "red" }}
                      onClick={() => removeDay(itemParent.id)}
                    >
                      <i className="fa-solid fa-xmark" />
                    </button>
                  )}
                </div>
              </div>
              <table
                className={`table todolist ${classes.todolist_table_wrap}`}
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>To do List</th>
                    <th>Voice Rec</th>
                    <th>Upload File & Image</th>
                    <th>Video Files</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {itemParent.day.length > 0 &&
                    itemParent.day.map((item, index2) => {
                      return (
                        <>
                          {item.loader ? (
                            <div className={classes.table_wrapper}>
                              <RotatingLines
                                height="40"
                                width="40"
                                strokeColor="white"
                                radius="6"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="rings-loading"
                              />
                              <p className="percetage_bar1">{progressBar}%</p>
                            </div>
                          ) : null}

                          <tr id={`btn_${item.id}${index1}${index2}`}>
                            <td>
                              {console.log(item.file, "codeeee")}
                              <input
                                type="text"
                                id={`name_${item.id}`}
                                name={`name`}
                                placeholder="name"
                                value={item.name}
                                onChange={(event) => {
                                  TodoListTaksHandler(
                                    event,
                                    itemParent.id,
                                    item.id
                                  );
                                }}
                              />
                            </td>

                            <td>
                            <textarea
                              type="text"
                              id={`todo_${item.id}`}
                              name={`todo`}
                              value={item.todolist}
                              onChange={(event) => {
                                TodoListTaksHandler(event, itemParent.id, item.id);
                              }}
                              style={{ backgroundColor: '#253341' , color: 'white'}} // Specify the background color here
                            />
                            </td>
                            <td style={{ position: "relative" }}>
                              {/* <i class="fa-solid fa-microphone todovoice_recorder"></i> */}
                              <div>
                                {/* <button onClick={saveAudio}>Save Recorded Audio</button> */}
                                {/* Other components or UI */}
                              </div>

                              {item && item.voicerecoding ? (
                                <div style= {{display:'flex', alignItems:'center'}}>
                                  {item.voicerecoding.url && (
                                    // If voicerecording URL exists, render an audio element with the URL
                                    <>

                                    <div>
                                      <audio controls loop style={{width:'160px', marginRight:"9px"}}>
                                        <source
                                          src={item.voicerecoding.url}
                                          type="audio/mp3"
                                          />
                                        Your browser does not support the audio
                                        element.
                                      </audio>
                                          </div>
                                      <i
                                        className="fa-solid fa-xmark"
                                        onClick={() => {
                                          cancelHandler(
                                            "voicerecording",
                                            itemParent.id,
                                            item.id
                                          );
                                        }}
                                      ></i>
                                    </>
                                  )}
                                </div>
                              ) : (
                                
                                <div onClick={() => {
                                  handleMicrophonePermission(
                                    itemParent.id,
                                    item.id
                                  );
                                }}
                               >
                                   <AudioRecorder
  ref={audioRecorderRef}
  onRecordingComplete={(audioElement) =>
    addAudioElement(
      audioElement,
      itemParent.id,
      item.id
    )
  }
  onStopRecording={handleRecordingCancel}
  audioTrackConstraints={{
    noiseSuppression: true,
    echoCancellation: true,
  }}
  downloadOnSavePress={false}
  downloadFileExtension="webm"
  disabled={recordingStarted}
/>
                                  
                                </div>
                                
                              )}

                              
                            </td>
                            <td>
                              <div class="todoupload-btn-wrapper">
                                {item && item.Pdf ? (
                                  // If 'file' exists, render the file icon, name, and upload button
                                  <>
                                  <span>
                                    {(
                                      (item.Pdf.url && item.Pdf.url.split(".").pop() === "pdf")) && (
                                      <>
                                        <div
                                          onClick={() => {
                                            openPdfHandler(item.Pdf.url);
                                          }}
                                        >
                                          <i className="fa-solid fa-file-pdf" style={{ color: "red" }}></i>
                                          {item.Pdf.original_filename}.pdf{" "}
                                        </div>
                                        <span>
                                          <i
                                            className="fa-solid fa-xmark"
                                            onClick={() => {
                                              cancelHandler("pdf", itemParent.id, item.id);
                                            }}
                                          ></i>
                                        </span>
                                      </>
                                    )}
                                
                                    {(item.Pdf.format === "png" || item.Pdf.format === "jpeg" || item.Pdf.format === "jpg") && (
                                      <>
                                        <div>
                                          <i className="fa-solid fa-image" style={{ color: "blue" }}></i>
                                          &nbsp;{item.Pdf.original_filename}.{item.Pdf.format}
                                        </div>
                                        <span>
                                          <i
                                            className="fa-solid fa-xmark"
                                            onClick={() => {
                                              cancelHandler("pdf", itemParent.id, item.id);
                                            }}
                                          ></i>
                                        </span>
                                        
                                      </>
                                    )}
                                  </span>
                                </>
                                
                                ) : (
                                  // If 'file' doesn't exist, render the file upload button and input
                                  <>
                                    <button className="todouploadbtn">
                                      <i className="fa-solid fa-upload"></i>
                                    </button>
                                    <input
                                      type="file"
                                      name="myfile"
                                      accept=".pdf, .jpg, .jpeg, .png"
                                      onChange={(event) => {
                                        uploadPdfFile(
                                          event,
                                          itemParent.id,
                                          item.id
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </td>

                            <td>
                              <div class="todoupload-btn-wrapper">
                                {item && item.video ? (
                                  // If 'file' exists, render the file icon, name, and upload button
                                  <>
                                    <span>
                                      {item.video.original_filename ||
                                      item.video.url ? (
                                        <>
                                          <div
                                            onClick={() =>
                                              openVedioHandler(item.video.url)
                                            }
                                          >
                                            <i
                                              className="fa fa-video-camera"
                                              aria-hidden="true"
                                            ></i>
                                            {item.video.original_filename}
                                          </div>
                                          <span>
                                            <i
                                              className="fa-solid fa-xmark"
                                              onClick={() => {
                                                cancelHandler(
                                                  "video",
                                                  itemParent.id,
                                                  item.id
                                                );
                                              }}
                                            ></i>
                                          </span>
                                        </>
                                      ) : null}
                                    </span>
                                  </>
                                ) : (
                                  // If 'file' doesn't exist, render the file upload button and input
                                  <>
                                    <button className="todouploadbtn">
                                      <i className="fa-solid fa-upload"></i>
                                    </button>
                                    <input
                                      type="file"
                                      name="myfile"
                                      accept=".avi,.mov, video/quicktime, video/*"
                                      onChange={(event) => {
                                        uploadVediofFile(
                                          event,
                                          itemParent.id,
                                          item.id
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </td>

                            <td>
                              <div
                                className={classes.icons}
                                style={{ color: "white" }}
                              >
                                {itemParent.day.length > 1 && (
                                  <i
                                    key={`rmv_btn_${item.id}${index2}`}
                                    className="fa fa-xmark"
                                    style={{ color: "red", }}
                                    onClick={() => {
                                      removeDemoTask(itemParent.id, item.id);
                                    }}
                                  ></i>
                                )}
                                {itemParent.day.length > 0 &&
                                  itemParent.day.length < 3 && (
                                    <i
                                      key={`add_btn_${item.id}${index2}`}
                                      className="fa fa-plus"
                                      style={{ color: "white" }}
                                      onClick={(event) => {
                                        addDemoTask(event, itemParent.id);
                                      }}
                                    ></i>
                                  )}
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          );
        })}
      {/* </table> */}
      <div class="modal-footer add-close-btn">
        {/* <button type="button" id='closeModalButton' class="btn btn-secondary close_add_btn" data-dismiss="modal">Cancel</button> */}
        {loader ? null : (
          <button
            type="button"
            class="btn btn-primary blue_add_btn"
            onClick={submitDataHandler}
          >
            Submit
          </button>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
