import classes from "./button.module.css";
const Button = (props) => {
  const className = `${props.className} ${classes.button}`;
  return (
    <button className={className} onClick={props.onClick}>
      {props.text}
    </button>
  );
};
export default Button;
