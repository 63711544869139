import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './program.module.css';
import CreateDayDuration from '../Modals/CreateDayDuration';
// import getClientsWithCoachId from '../../../services/client/get-clients-with-coach-id';
import { GetAllProgramByCoach,deleteprogram } from '../../../Store/program/program-action';
import ClientModal from '../Modals/ClientModal';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";


export default function Program() {
  const navigate =useNavigate()
  const coach = useSelector((state) => state.auth.userInfo);
  let programData = useSelector((state) => state.programs.programs);
  const loading = useSelector((state) => state.programs.loading);
  const error = useSelector((state) => state.programs.error);
  const [showModal,setshowModal] =useState(false)
  const [selectId,setselectId]=useState()
  const dispatch = useDispatch()
  const [show, setshow] = useState(false)


  console.log(programData);








  const fetchData = async () => {
    try {
      await dispatch(GetAllProgramByCoach(coach.id))
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const EditHandler =(id)=>{
    navigate(`/coach-dashboard/program/edit/${id}`)
  }

  const DeleteHandler = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want To Delete It!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async(result) => {
      if (result.isConfirmed) {
        await dispatch(deleteprogram(id));
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
      }
    });
   
};



  useEffect(() => {
    fetchData();
  }, [dispatch, coach.id]);


    // programData = programData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
   if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={classes.main}>
      <div className={classes.button_div_content}>
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" onClick={() => { setshow(true) }}>
          Create
        </button>
      </div>

      <table className={`table ${classes.customtable}`}>
        <thead>
          <tr>
            <th style={{ width: '300px' }} >Title</th>
            <th style={{ width: '300px' }}>Day</th>
            <th >Assign</th> {/* Corrected typo here */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            programData.map((data,indx)=>{
                return(
                  <tr className={classes.text_details}>
                  <td>{data.title}</td>
                  <td>{data.todolist.length}</td>
                  <td><button  onClick={()=>{
                    setselectId(data.id)
                    setshowModal(!showModal)}
                    }>Assign</button></td>
                    <td>
                    <i style={{marginRight:"10px"}} class="fas fa-edit" onClick={()=>{EditHandler(data.id)}}></i>
                    <i class="fa-solid fa-trash" onClick={()=>{DeleteHandler(data.id)}}></i>
                    </td>
                </tr>
                )
            })
          }
        </tbody>
      </table>
      {show ? (<CreateDayDuration setshow={setshow} show={show} />) : null}
      {showModal?(<ClientModal setshowModal={setshowModal} showModal={showModal} selectId={selectId}/>):null}

    </div>
  );
}
