import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import {
  sendNotification,
  addTopic,
  getTasksOfTopic,
  addCommentOnTopic,
} from "../../Store/Coach/tasks/tasks-actions";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/style.css";

export default function CoachComment({ eventId }) {
  const topic = useSelector((state) => state.topics.topics);
  const [coach_comment, setCoach_comment] = useState([]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState("");

  console.log(topic,"topictopic")

  console.log(coach_comment,"coach_commentcoach_comment")

  const handleClose = () => setShow(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const addCoachCommentHandler = (event) => {
    event.preventDefault();
    const newComment = comment.trim();
    if (newComment) {
      addCommentOnTopic(eventId, newComment)(dispatch);
      setComment("");
      setCoach_comment((prevCoachComments) => [
        ...prevCoachComments,
        newComment,
      ]);
    }
    // window.location.reload();
  };

  useEffect(() => {
    const fetchData = async () => {
      const filteredTopic = await topic.filter((t) => t.id === eventId);
    

      if (filteredTopic.length > 0 && filteredTopic[0].comments) {
        setCoach_comment(filteredTopic[0].comments);
      }
    };

    fetchData();
  }, [eventId, topic]);

  return (
    <div className="bg-secondary add_task_wrap">
      <Modal show={show} onHide={handleClose} className="custom-coach-modal11221">
        <Modal.Header>
          <div className="coach_comment_box_wrap">
            <h2>Add Coach Comment</h2>
            <i class="fa-solid fa-xmark" onClick={handleClose}></i>
          </div>
        </Modal.Header>
        <Modal.Body style={{height:"500px",overflowY:"scroll"}}>
          {coach_comment.length > 0 &&
            coach_comment.map((commentsection, i) => {
              console.log(commentsection, "commentcomment");
              return (
                <div class="card-body comment_box_text">
                  <p key={i}>{commentsection}</p>
                </div>
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          <div className="comment_section_wrap">
            <form
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                columnGap: "20px",
              }}
              onSubmit={addCoachCommentHandler}
            >
              <input
                type="text"
                className="comment_input_filed"
                style={{ color: "black" }}
                placeholder="Type Coach comment..."
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
              />
              <button type="submit">Send</button>
            </form>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
