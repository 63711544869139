import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClientTile from "./client-tile";
import { getClients } from "../../../Store/Coach/Client/client-actions";
import classes from "./clients.module.css";
import LoadingIndicator from "../../../Components/UI/loader";

const Clients = ({ searchTerm,clientdata}) => {
  const user = useSelector((state) => state.auth.userInfo);
  const clients = useSelector((state) => state.clients.clients);
  console.log(clients,"hello don")
  const [filteredClients, setFilteredClients] = useState([...clients]);
  const dispatch = useDispatch();
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);



  useEffect(() => {
    const getClientsFn = async () => {
      try {
        setError(undefined);
        setIsLoading(true);
        await getClients(user.id)(dispatch);
        setIsLoading(false);
      } catch (e) {
        setError(e.toString());
        setIsLoading(false);
      }
    };
    if (user) {
      getClientsFn();
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (clients.length > 0 && searchTerm.length > 0) {
      setFilteredClients([...(clients.filter((c) => (
        c?.firstName?.toLowerCase()?.includes(searchTerm.toLowerCase())
        || c?.lastName?.toLowerCase()?.includes(searchTerm.toLowerCase())
        || c?.email?.toLowerCase()?.includes(searchTerm.toLowerCase()))))
      ]);
    } else {
      setFilteredClients([...clients]);
      clientdata([...clients])
    }
  }, [clients, searchTerm]);
  
   console.log(filteredClients,"filteredClientsfilteredClients")

  const component = error ? (
    <p className={classes.error}>{error}</p>
  ) : isLoading ? (
    <div className={classes.center}>
      <LoadingIndicator />
    </div>
  ) : filteredClients.length ? (
    filteredClients.map((e) => <ClientTile key={e.id} client={e} />)
  ) : (
    <p className={classes.noClient}>
      No clients found. Please click the '+ Add Client' Button to add customers
    </p>
  );
  return component;
};

export default Clients;
