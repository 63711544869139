import classes from "./login-form.module.css";
import useInput from "../../Hooks/use-input";
import { useDispatch, useSelector } from "react-redux";
import { loginWithEmailAndPassword } from "../../Store/Authentication/auth-actions";
import { TextFormField, LoadingButton, Button } from "../../Components/index";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { authActions } from "../../Store/Authentication/auth-slice";
import { routes } from "../../util/routes";
import role from "../../util/role-enum";
const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginError = useSelector((state) => state.auth.error);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const {
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    valueBlurHandler: emailBlurHandler,
    value: email,
    isValid: isEmailValid,
  } = useInput((value) => value.trim().includes("@"));
  const {
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    valueBlurHandler: passwordBlurHandler,
    value: password,
    isValid: isPasswordValid,
  } = useInput((value) => value.trim().length > 0);
  const isFormValid = isPasswordValid && isEmailValid;
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (isFormValid) {
      try {
        const userRole = await loginWithEmailAndPassword(
          email,
          password
        )(dispatch);
        if (userRole === role.adminCoach) {
          navigate(routes.adminDashboard.index);
        } else if (userRole === role.coach) {
          navigate(routes.coachDashboard.index);
        } else {
          dispatch(authActions.error("user role is not defined", role));
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  return (
    <form className={classes.align} method="submit" onSubmit={onSubmitHandler}>
      <div>
        <TextFormField
          type="email"
          isFormFieldExtra={true}
          placeholder="Email"
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
        />

        {emailHasError && (
          <p className={classes.error}>Please enter a valid email</p>
        )}
      </div>
      <div>
        <TextFormField
          type="password"
          isFormFieldExtra={true}
          placeholder="Password"
          onChange={passwordChangeHandler}
          onBlur={passwordBlurHandler}
        />
        {passwordHasError && (
          <p className={classes.error}>Please enter password</p>
        )}
      </div>
      {loginError && (
        <p className={classes.error1}>Email or password is incorrect</p>
      )}
      {/* {loginError && <p className={classes.error1}>{loginError}</p>} */}
      {isLoading ? <LoadingButton /> : <Button text="Login" />}
      <p style={{color:"white"}}>create a account <NavLink to ="/signup">Sign Up</NavLink> </p>
    </form>
  );
};

export default LoginForm;
