import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopicsOfHomePage } from "../../../../../../Store/Coach/tasks/tasks-actions";
import DateFrame from "./date-frame";
import classes from "./date-frames.module.css";
import React from "react";
const DateFrames = () => {
  const coach = useSelector((state) => state.auth.userInfo);
  const coachId = useMemo(() => coach.id, [coach]);
  const topicsOrderByClient = useSelector((state) => state.topics.homeTopics);
  console.log(topicsOrderByClient,"topicsOrderByClient")
  const dispatch = useDispatch();
  const getHomeTasks = useCallback(
    async (coachId) => {
      await getTopicsOfHomePage(coachId)(dispatch);
    },
    [dispatch]
  );
  useEffect(() => {
    getHomeTasks(coachId);
  }, [getHomeTasks, coachId]);

  const dateFrames = topicsOrderByClient.map((t, i) => (
    <DateFrame
      key={t.clientId ?? t.groupId}
      topics={t.topics}
      clientId={t.clientId}
      groupId={t.groupId}
      scrollId={i}
    />
  ));
  return (
    <div className={classes.align}>
      <div className={classes.sizing}>{dateFrames}</div>
    </div>
  );
};

export default React.memo(DateFrames);
