import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

const editTopicService = async (topicId, newTopicName) => {
  if (!topicId) {
    throw Error("No topic id found");
  }
  await editTopic(topicId, newTopicName);
};

const editTopic = async (topicId, newTopicName) => {
  const topicRef = collection(db, "topics");
  const topicDoc = doc(topicRef, topicId);
  await updateDoc(topicDoc, {
    topicName: newTopicName,
  });
};

export default editTopicService;
