import { useState, useRef, useEffect } from "react";
import classes from "./add-formatting-text-field.module.css";
import { FaCheck } from "react-icons/fa";

const AddFormattingTextField = (props) => {
  const [content, setContent] = useState("");
  const editorRef = useRef();
  const [divOpacity, setDivOpacity] = useState(false);
  const divClassName = divOpacity
    ? ` ${classes.align} ${classes.fullOpacity}`
    : `${classes.align}`;

  const applyStyle = (style) => {
    document.execCommand(style, false, null);
    const editorHtml = editorRef.current.innerHTML;
    setContent(editorHtml);
  };
  const onFocus = (event) => {
    event.preventDefault();
    if (!divOpacity) {
      setDivOpacity(true);
    }
  };
  const onBlur = (event) => {
    event.preventDefault();
    if (divOpacity) {
      setDivOpacity(false);
    }
  };

  useEffect(() => {
    setContent(props.value);
  }, [props.value]);

  const submitHandler = () => {
    setContent("");
    props.onSubmit(editorRef.current.innerHTML, editorRef.current.innerText);
  };
  return (
    <div
      onSubmit={submitHandler}
      className={`${classes.fullWidth} ${classes.background}`}
    >
      <div>
        <div className={divClassName}>
          <p>🔄</p>
          <div
            contentEditable="true"
            ref={editorRef}
            className={classes.input}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(event) => {
              setContent(event.target.value);
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        <div className={classes.buttonDisplay}>
          <div className={classes.icons}>
            <button
              onClick={() => applyStyle("bold")}
              className={classes.styleButton}
            >
              Bold
            </button>
            <button
              onClick={() => applyStyle("italic")}
              className={classes.styleButton}
            >
              Italic
            </button>
            <button
              onClick={() => applyStyle("underline")}
              className={classes.styleButton}
            >
              Underline
            </button>
          </div>
          <FaCheck onClick={() => submitHandler()} className={classes.check} />
        </div>
      </div>
    </div>
  );
};

export default AddFormattingTextField;
