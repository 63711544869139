import { useState } from "react";
import classes from "./add-text-field.module.css";

const AddTextField = (props) => {
  const [divOpacity, setDivOpacity] = useState(false);
  const divClassName = divOpacity
    ? `${classes.background} ${classes.align} ${classes.fullOpacity}`
    : `${classes.background} ${classes.align}`;
  const onFocus = (event) => {
    event.preventDefault();
    if (!divOpacity) {
      setDivOpacity(true);
    }
  };
  const onBlur = (event) => {
    event.preventDefault();
    if (divOpacity) {
      setDivOpacity(false);
    }
  };

  let [value, setValue] = useState(props.value);
  const onChange = (event) => {
    setValue(event.target.value);
  };
  const submitHandler = (event) => {
    props.onSubmit(event);
    setValue("");
  };
  return (
    <form onSubmit={submitHandler} className={divClassName}>
      <p>🔄</p>
      {/* <textarea
        className={classes.input}
        cols={25}
        rows={3}
        placeholder={props.placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={props.onSubmit}
      /> */}
      {value && onChange ? (
        <input
          type="text"
          className={classes.input}
          placeholder={props.placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
        />
      ) : (
        <input
          type="text"
          className={classes.input}
          placeholder={props.placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
      {props.children}
    </form>
  );
};

export default AddTextField;
