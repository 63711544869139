import { doc, deleteDoc,getDoc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

const deleteProgramService = async (programId) => {
  if (!programId) {
    throw Error("Topic id not found");
  }
  await deleteprogram(programId);
};

const deleteprogram = async (programId) => {
    try {
      // Get the document data before deleting
      const programDoc = doc(db, "programs", programId);
      const snapshot = await getDoc(programDoc);
      await deleteDoc(programDoc);
      return snapshot.data();
    } catch (error) {
      // Handle errors if necessary
      console.error("Error deleting document:", error);
      throw error; // Rethrow the error to propagate it to the calling code
    }
  };

export default deleteProgramService;
