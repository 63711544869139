import classes from "./login.module.css";
import LoginForm from "./login-form";
import { Wrap, Card, Logo } from "../../Components/index";
const Login = () => {
  return (
    <Wrap>
      <Card className={`${classes.center}`}>
        <Logo />
        <LoginForm />
      </Card>
    </Wrap>
  );
};
export default Login;
