import { collection, addDoc,doc,updateDoc,getDoc,setDoc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

const updateProgramService = async (programData,updatedProgramData) => {
  try {
    return await updateProgramInCloud(programData,updatedProgramData);
  } catch (error) {
    throw new Error(`Error adding program to Firestore: ${error}`);
  }
};


// const topicRef = collection(db, "topics");
// const taskDoc = doc(topicRef, taskId);


 // Import your Firebase configuration file

 const updateProgramInCloud = async (programId, updatedProgramData) => {
    try {
      console.log(updatedProgramData, "updatedProgramData");
      console.log(programId, "programId");
      const programRef = doc(db, 'programs', programId);
      
      // Handle undefined values in updatedProgramData
      const updateData = {
        title: updatedProgramData.title || "",
        description: updatedProgramData.description || "",
        coachId: updatedProgramData.coachId || "",
        todolist: Array.isArray(updatedProgramData.todolist)
          ? updatedProgramData.todolist.map(dayData => ({
              id: dayData.id || "",
              day: Array.isArray(dayData.day)
                ? dayData.day.map(item => ({
                    id: item.id || "",
                    name: item.name || "",
                    todolist: item.todolist || "",
                    Pdf: item.Pdf || "",
                    voicerecoding: item.
                    voicerecoding || "",
                    loader: item.loader || "",
                    video: item.video || "",
                  }))
                : [],
            }))
          : [],
      };
  
      await updateDoc(programRef, updateData, { merge: true });
  
      console.log('Document updated successfully!');
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };
  
  

  


export default updateProgramService

