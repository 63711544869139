import { db } from "../../Configuration/initialize-firebase";
import { doc, updateDoc,getDoc } from "firebase/firestore";



const updateCoachProfile = async (userId, updatedData) => {
  const userRef = doc(db, "users", userId);

  try {
    // Check if meetingUrl field is undefined and provide a default value if needed
    if (updatedData.meetingUrl === undefined) {
      updatedData.meetingUrl = "https://calendly.com/"; // Provide a default value here
    }

    // Update the document
    await updateDoc(userRef, updatedData);

    // Fetch the updated document
    const updatedDoc = await getDoc(userRef);

    if (updatedDoc.exists()) {
      // Access the updated data
      const updatedUserData = updatedDoc.data();
      console.log("User profile updated successfully:", updatedUserData);
      return updatedUserData;
    } else {
      console.error("Error fetching updated user profile: Document does not exist");
      return null; // or throw an error, depending on your error handling strategy
    }

  } catch (error) {
    console.error("Error updating user profile:", error.message);
    throw error; // Re-throw the error to handle it in the calling code if needed
  }

};


export default updateCoachProfile;