// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SucessMoadl_modal_box_wrap__c4azs{
    background: #535353!important;
}
.SucessMoadl_head__TgIVQ{
    text-align: center;
}
.SucessMoadl_head__TgIVQ img{
    width: 80px;
}
.SucessMoadl_head__TgIVQ h2{
    font-size: 20px;
    color: black;
}
.SucessMoadl_head__TgIVQ p{
    color: black;
    font-size: 16px;
}
.SucessMoadl_yesnotbutton__uimY-{
    text-align: center;
    display: flex;
    column-gap: 14px;
    justify-content: center;
}
.SucessMoadl_nobtn__v0FU-{
    background: #FF0000;
border: navajowhite;
padding: 10px 44px;
color: white;
border-radius: 4px;
font-size: 18px;
text-transform: capitalize;
}
.SucessMoadl_yes__kQ2di{
    background-color: #00A3FF;
    border: navajowhite;
padding: 10px 44px;
color: white;
border-radius: 4px;
font-size: 18px;
text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/Components/Modal/SucessMoadl.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;AACA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI,YAAY;IACZ,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;IACI,mBAAmB;AACvB,mBAAmB;AACnB,kBAAkB;AAClB,YAAY;AACZ,kBAAkB;AAClB,eAAe;AACf,0BAA0B;AAC1B;AACA;IACI,yBAAyB;IACzB,mBAAmB;AACvB,kBAAkB;AAClB,YAAY;AACZ,kBAAkB;AAClB,eAAe;AACf,0BAA0B;AAC1B","sourcesContent":[".modal_box_wrap{\n    background: #535353!important;\n}\n.head{\n    text-align: center;\n}\n.head img{\n    width: 80px;\n}\n.head h2{\n    font-size: 20px;\n    color: black;\n}\n.head p{\n    color: black;\n    font-size: 16px;\n}\n.yesnotbutton{\n    text-align: center;\n    display: flex;\n    column-gap: 14px;\n    justify-content: center;\n}\n.nobtn{\n    background: #FF0000;\nborder: navajowhite;\npadding: 10px 44px;\ncolor: white;\nborder-radius: 4px;\nfont-size: 18px;\ntext-transform: capitalize;\n}\n.yes{\n    background-color: #00A3FF;\n    border: navajowhite;\npadding: 10px 44px;\ncolor: white;\nborder-radius: 4px;\nfont-size: 18px;\ntext-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_box_wrap": `SucessMoadl_modal_box_wrap__c4azs`,
	"head": `SucessMoadl_head__TgIVQ`,
	"yesnotbutton": `SucessMoadl_yesnotbutton__uimY-`,
	"nobtn": `SucessMoadl_nobtn__v0FU-`,
	"yes": `SucessMoadl_yes__kQ2di`
};
export default ___CSS_LOADER_EXPORT___;
