// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coach-tab_background__qDubY {
  background: #161f28;
  width: 100%;
  height: 100vh;
  padding-top: 5rem;
  overflow-y: auto;
}

.coach-tab_center__kKha5 {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/admin-dashboard/coaches/coach-tab.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;AACzB","sourcesContent":[".background {\n  background: #161f28;\n  width: 100%;\n  height: 100vh;\n  padding-top: 5rem;\n  overflow-y: auto;\n}\n\n.center {\n  margin-left: auto;\n  margin-right: auto;\n  text-align: center;\n  width: 60%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `coach-tab_background__qDubY`,
	"center": `coach-tab_center__kKha5`
};
export default ___CSS_LOADER_EXPORT___;
