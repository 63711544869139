import React, { useState } from 'react';
import classes from "./changepassword.module.css";
import { useSelector } from "react-redux";
import updatePasswordInFirebase from '../../../services/Authentication/ChangePassword';
import SucessModal from '../../../Components/Modal/SucessModals';

export default function ChangePassword() {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [error, setError] = useState('');
  const [message,setmessage] =useState()
  const [show,setshow] =useState(false)
  const [isModalOpen,setisModalOpen]=useState(false)
  // const[]

  const [formValues, setFormValues] = useState({
    currentpassword: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const updatePassword = async (e) => {
    e.preventDefault();
     if(formValues.password=="" || formValues.confirmPassword=="" || formValues.currentpassword ==""){
      setError("Please fill currentPassword && Password && confirpasswword");
      return;
     } 

    if (formValues.password !== formValues.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
    await updatePasswordInFirebase(userInfo.email, formValues.currentpassword, formValues.password);
      setmessage("Update Password Sucessfully")
      setshow(true)
      setisModalOpen(true)
      
      // Handle success here if needed
    } catch (e) {
      // Handle error if needed
    }
  };

  return (
    <>
      <div className={classes.profile_name}>
        <div className={classes.profile_wrap}>
        <div> <h1 className={classes.title}> Change Password </h1> </div>
        </div>
        <div className={classes.profileuserfileds}>
          <div className={classes.profilename_input}>
            <i className="fa-regular fa-lock" />
            <input
              id="currentPassword"
              name='currentpassword'
              type="password"
              placeholder="Current Password"
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.profilename_input}>
            <i className="fa-regular fa-lock" />
            <input
              id="newPassword"
              name='password'
              type="password"
              placeholder="New Password"
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.profilename_input}>
            <i className="fa-solid fa-lock"></i>
            <input
              id="confirmNewPassword"
              name='confirmPassword'
              type="password"
              placeholder="Confirm New Password"
              onChange={handleInputChange}
            />
          </div>
        </div>
        {error && <p style={{ color: "white" }}>{error}</p>}
        <div className={classes.save_btn}>
          <button onClick={(e) => { updatePassword(e) }}>Update Password</button>
        </div>
      
      </div>

      {
  show ? (
    <SucessModal  isModalOpen={isModalOpen} setisModalOpen={setisModalOpen}  setshow={setshow} message={message}/>
  ) : null
}
    </>
  );
}
