import React, { useState, useEffect } from "react";
import classes from "./Task.module.css";
import "../../assets/style.css";
import axios from "axios";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import {
  sendNotification,
  addTopic,
  getTasksOfTopic,
  updateTask,
  taskOfTopic,
  deleteTopic
  
} from "../../Store/Coach/tasks/tasks-actions";
import SucessModal from "./SucessMoal";
import { ToastContainer, toast } from "react-toastify";
import VoicedRecoder from "./VoicedRecoder";
import UpdateSucessModal from "./UpdateSucessModal";
import PdfReader from "../../Components/pdf-reader/PdfReader";
import UploadPdf from "./UploadPdf";
import UploadVedio from "./UploadVedio";
import CoachComment from "./CoachComment";
import ClientComment from "./ClientComment";

const cloudName = "db3gzsopp";
const uploadPreset = "u2muuej0";

export default function UpdateToTask({
  setshowModal,
  showModal,
  eventId,
  fetchData,
  seteventId
}) {
  const topics = useSelector((state) => state.topics.topics);
  const [task, setTask] = useState({
    taskId:"",
    date: "",
    task_name: "",
    todolist: "",
    Pdf: {},
    recordingfile: {},
    video: {},
    status: "",
  });
  const dispatch = useDispatch();
  const [btndisable, setBtnDisable] = useState(false);
  const [istoggle, setistoggle] = useState(false);
  const [errors, setErrors] = useState({});
  const taslkList = useSelector((state) => state.topics.tasks);

  const [recordingStarted, setRecordingStarted] = useState(false);

  const [loader, setloader] = useState(false);
  const [show, setshow] = useState(true);
  const [videoshow, setvideoshow] = useState(true);
  const [coach_comment, setcoach_comment] = useState(false);
  const [client_comment, setclient_comment] = useState(false);
  const [progressBar,setprogressbar] =useState(0)
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const tasksdDate = new Date(task.date);
  tasksdDate.setHours(0, 0, 0, 0);

  console.log("taslkList007", taslkList);

  console.log(recordingStarted, "recordingStarted232323")

  const eventHandler = (e) => {
    const { name, value } = e.target;
    let errors = {};

    if (name === "date") {
      const selectedDate = new Date(value);
      selectedDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      console.log(selectedDate, "selectedDate");
      console.log(currentDate, "currentDate");

      if (selectedDate < currentDate) {
        setErrors({ date: "Please select a valid Date" }); // Fixed the syntax error here
        // toast.error("Please select a valid date.");
      }
    }

    setTask((prevTask) => ({ ...prevTask, [name]: value }));
  };



  useEffect(() => {
    if (showModal) {
      const modal = document.getElementById("exampleModal1222");
      if (modal) {
        modal.classList.add("show");
        modal.style.display = "block";
        const backdrop = document.getElementsByClassName("modal-backdrop")[0];
        if (backdrop) backdrop.style.display = "block";
      }
    } else {
      const modal = document.getElementById("exampleModal1222");
      if (modal) {
        modal.classList.remove("show");
        modal.style.display = "none";
        const backdrop = document.getElementsByClassName("modal-backdrop")[0];
        if (backdrop) backdrop.style.display = "none";
      }
    }
  }, [showModal]);

  const validateForm = (values) => {
    let errors = {};
    const selectedDate = new Date(values.date);
    selectedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    if (!values.date) {
      errors.date = "Date is required";
      setBtnDisable(false)
    }

    if (selectedDate < currentDate) {
      errors.date = "Previous task not be Updated";
      toast.error("Previous task not be Updated");
    
    }

    if (!values.task_name) {
      errors.task_name = "Task name is required";
      setBtnDisable(false)
    }
    if (!values.todolist) {
      errors.todolist = "Todo List is required";
      setBtnDisable(false)
    }

    return errors;
  };

  const updateTaskHandler = () => {
    const validationErrors = validateForm(task);
    // setBtnDisable(true);

    let tasklogCounter = localStorage.getItem('tasklogCounter');
     
 if (tasklogCounter) {
    toast.error("Please save recording before submitting.");
      return;
  }
     if (Object.keys(validationErrors).length === 0) {
      if (eventId) {
        updateTask(eventId, task)(dispatch);
        fetchData();
        const modal = document.getElementById("exampleModal1222");
        // window.location.reload()
        setTimeout(() => {
          if (modal) {
            modal.classList.remove("show");
            modal.style.display = "none";
            const backdrop =
              document.getElementsByClassName("modal-backdrop")[0];
            if (backdrop) backdrop.style.display = "none";
            setshowModal(false);
            setBtnDisable(false);
            Swal.fire({
              title: "Success",
              text: "Update Task Successfully",
              icon: "success",
            });
            localStorage.removeItem('tasklogCounter');
          }
        }, 2000);
      }
    } else {
      console.log("hello");
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    console.log(topics,"DSFfffffffffffffffffffffffffffff")
    if (eventId && topics) {
      const filterTopic = topics.find((topic) => topic.id === eventId);
  
      if (filterTopic && filterTopic.createdAt) {
        const date = new Date(
          filterTopic.createdAt.seconds * 1000 +
            filterTopic.createdAt.nanoseconds / 1e6
        );
  
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
          // debugger
  
        dispatch(getTasksOfTopic(eventId));
        setloader(true)
  
        setTask({
          date: formattedDate,
          task_name: filterTopic.topicName || "",
        });
      }
    }
  }, [eventId, topics]);
  


  useEffect(() => {
    
    if (eventId && taslkList) {
      taslkList.forEach((todolist) => {
        if (todolist) {
          setTask((prevState) => ({
            ...prevState,
            taskId: todolist.id,
            todolist: todolist.description,
            Pdf: todolist.Pdf || {},
            recordingfile: todolist.recordingfile || {},
            video: todolist.video || {},
            status: todolist.status,
          }));
        }
        setloader(false)
      });
    }
  }, [eventId, taslkList]);
  

  // console.log(task, "12222222");

  const closeModal = () => {
    setTask({
      taskId: "",
      date: "",
      task_name: "",
      todolist: "",
      Pdf: {},
      recordingfile: {},
      video: {},
      status: "",
    });
    seteventId()
    const modal = document.getElementById("exampleModal1222");
    if (modal) {
      modal.classList.remove("show");
      modal.style.display = "none";
      const backdrop = document.getElementsByClassName("modal-backdrop")[0];
      if (backdrop) backdrop.style.display = "none";
      setshowModal(false);
      fetchData();
      
    }
  };
  // console.log(coach_comment, "errorserrors");

  const handleCoachCommentClick = () => {
    setcoach_comment(!coach_comment);
  };

  const handleClientCommentClick = () => {
    setclient_comment(!client_comment);
  };


  const DeleteTask =async()=>{

    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want To Delete It!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async(result) => {
      if (result.isConfirmed) {
        setloader(true)
        await dispatch(deleteTopic(eventId))
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
         const modal = document.getElementById("exampleModal1222");
       if (modal) {
         modal.classList.remove("show");
         modal.style.display = "none";
         const backdrop = document.getElementsByClassName("modal-backdrop")[0];
         if (backdrop) backdrop.style.display = "none";
         setshowModal(false);
         fetchData();
         setloader(false)
       }
      }
    });
  }



  return (
    <>
      <button
        type="button"
        class="btn btn-primary"
        style={{ display: "none" }}
        data-toggle="modal"
        data-target="#exampleModal1222"
      >
        View To Task
      </button>
      <div
        class="modal fade"
        id="exampleModal1222"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog updateTaskup"
          role="document"
          style={{ overflowY: "scroll", height: "500px" }}
        >
          <div class="modal-content add_task_wrap">
            <div class="modal-header user-comment-box">
              <h5 class="modal-title add_task_text" id="exampleModalLabel">
                View Task
              </h5>
              <p>
                Status: {task.status == "inProgress" ? "Progress" : "Complete"}
              </p>
              <div class="menu_box_wrap">
                <i
                  class="fa-solid fa-ellipsis-vertical"
                  onClick={() => {
                    setistoggle(!istoggle);
                  }}
                ></i>
                <div>
                  {istoggle && (
                    <div className="profile">
                      <div
                        className="user_profile_name_coment"
                        // className={classNames.profile_dd}
                      >
                        <ul
                        // className={classNames.profile_ul}
                        >
                          <li onClick={handleCoachCommentClick}>
                            <span
                              className="picon"
                              onClick={handleCoachCommentClick}
                            >
                              {" "}
                              COACH COMMENT
                            </span>
                          </li>

                          <li onClick={handleClientCommentClick}>
                            <span
                              className="picon"
                              onClick={handleClientCommentClick}
                            >
                              {" "}
                              CLIENT COMMENT
                            </span>
                          </li>

                          <li onClick={DeleteTask}>
                            <span
                              className="picon"
                              onClick={DeleteTask}
                            >
                              {" "}
                               DELETE
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  <i class=" fa-solid fa-xmark" onClick={closeModal}></i>
                </div>
              </div>
            </div>
            <div class="modal-body">
              <div className={classes.container}>
                <form className={classes.contact}>
                  <div className="date_picker">
                    <input
                      type="date"
                      name="date"
                      className={classes.datepicker}
                      autoComplete="off"
                      onChange={eventHandler}
                      required
                      value={task.date || ""}
                      min={new Date().toISOString().split("T")[0]}
                    />

                    {errors.date && (
                      <span className={classes.error}>{errors.date}</span>
                    )}
                  </div>
                  <div className="add_task_fiedset">
                    <label className={classes.nme} htmlFor="">
                      Topic name
                    </label>
                    <input
                      placeholder="task name"
                      className={classes.namefild}
                      name="task_name"
                      value={task.task_name}
                      type="text"
                      tabIndex={2}
                      required
                      onChange={eventHandler}
                    />
                    {errors.task_name && (
                      <span className={classes.error}>{errors.task_name}</span>
                    )}
                  </div>
                  <div className="add_task_fiedset">
                    <label className={classes.nme} htmlFor="">
                      Todo List
                    </label>
                    <input
                      placeholder="Todo List"
                      className={classes.namefild}
                      name="todolist"
                      type="text"
                      tabIndex={2}
                      required
                      onChange={eventHandler}
                      value={task.todolist}
                    />
                    {errors.todolist && (
                      <span className={classes.error}>{errors.todolist}</span>
                    )}
                  </div>
                  {loader ? (
                    <div className={classes.loaderwrapper}>
                      <RotatingLines
                        height="40"
                        width="40"
                        strokeColor="white"
                        radius="6"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                    </div>
                  ) : null}

                  <div className="add_task_fiedset">
                    <div className="add_task_fiedset">
                      <label className={classes.nme} htmlFor="">
                        Voice Rec
                      </label>
                      <VoicedRecoder
                        setTask={setTask}
                        setBtnDisable={setBtnDisable}
                        loader={loader}
                        setloader={setloader}
                        task={task}
                        
                        progressBar={progressBar} 
                        setprogressbar={setprogressbar}
                        recordingStarted={recordingStarted} 
                      setRecordingStarted={setRecordingStarted} 
                      />
                    </div>
                  </div>
                  <UploadPdf
                    setTask={setTask}
                    setBtnDisable={setBtnDisable}
                    loader={loader}
                    setloader={setloader}
                    task={task}
                    show={show}
                    setshow={setshow}
                    progressBar={progressBar}
                    setprogressbar={setprogressbar}
                  />
                  <UploadVedio
                    setTask={setTask}
                    setBtnDisable={setBtnDisable}
                    loader={loader}
                    setloader={setloader}
                    task={task}
                    videoshow={videoshow}
                    setvideoshow={setvideoshow}
                    progressBar={progressBar}
                    setprogressbar={setprogressbar}
                  />
                </form>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                id="closeModalButton"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                disabled={btndisable}
                onClick={updateTaskHandler}
              >
                Update Task
              </button>
            </div>
          </div>
        </div>
      </div>
      {coach_comment && <CoachComment eventId={eventId} />}
      {client_comment && <ClientComment eventId={eventId} taslkList={taslkList}/>}
    </>
  );
}
