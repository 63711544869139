import classNames from "./logout.module.css";
import { logout } from "../../Store/Authentication/auth-actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../util/routes";
import { useState } from "react";
import { Link } from "react-router-dom";
import role from "../../util/role-enum";

const Logout = () => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  console.log(userInfo, "codee");
  const [istogglevissible, setIstogglevissible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signOutHandler = async (event) => {
    event.preventDefault();
    await logout()(dispatch);
    navigate(routes.index);
  };

  return (
    <>
      {userInfo && userInfo.role === role.coach && (
        <>
          <div className={classNames.userProfile}>
            <div className={classNames.userProfile_image}>
              <img src="/ewqw.png" alt="logo" />
            </div>
            <div
              className={classNames.userProfile_text}
              onClick={() => {
                setIstogglevissible(!istogglevissible);
              }}
            >
              <h3>
                {userInfo.firstName} {userInfo.lastName}
              </h3>
              {istogglevissible?(<i className="fa fa-angle-down" aria-hidden="true"></i>):(<i className="fa fa-angle-up" aria-hidden="true"></i>)}
            </div>
          </div>
          {istogglevissible && (
            <div className="profile">
              <div className={classNames.profile_dd}>
                <ul className={classNames.profile_ul}>
                  <li className={classNames.profile_list}>
                    <img src="/ewqw.png" alt="image" />
                    <span>
                      {userInfo.firstName} {userInfo.lastName}
                    </span>
                  </li>
                  <li className={classNames.profile_li}>
                    <Link className="profile" onClick={()=>{setIstogglevissible(!istogglevissible)}} to={`${routes.coachDashboard.profile.index}`}>
                      <span className="picon">
                        <i className="fas fa-user-alt"></i>
                      </span>
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link className="address" onClick={()=>{setIstogglevissible(!istogglevissible)}} to={`${routes.coachDashboard.profile.changepassword}`}>
                      <span className="picon">
                        <i className="fas fa-lock"></i>
                      </span>
                      Change Password
                    </Link>
                  </li>
                  <li>
                    <a className="logout" onClick={signOutHandler}>
                      <span className="picon">
                        <i className="fas fa-sign-out-alt"></i>
                      </span>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </>
      )}

{userInfo && userInfo.role === role.adminCoach && (
  <button onClick={signOutHandler}>SignOut</button>
)}
    </>
  );
};

export default Logout;
