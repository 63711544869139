import { useNavigate } from "react-router-dom";
import { Button } from "../../../Components";
import { routes } from "../../../util/routes";
import Groups from "./groups";
import classes from "./groups-tab.module.css";
import Heading from "./heading";
import { TextFormField } from "../../../Components/index";
import { useState } from "react";

const GroupsTab = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const addGroupHandler = (event) => {
    event.preventDefault();
    navigate(routes.coachDashboard.groups.addGroups);
  };
  const searchChangeHandler = (event) => {
    setSearchTerm(event.target.value);
  }
  return (
    <div className={classes.background}>
      <div className={classes.center}>
        <div className={classes.addAndSearch}>
          <Button text="+ Add Groups" onClick={addGroupHandler} />
          <TextFormField
            type="email"
            isFormFieldExtra={true}
            placeholder="Search"
            onChange={searchChangeHandler}
          />
        </div>
        <Heading />
        <Groups searchTerm={searchTerm} />
      </div>
    </div>
  );
};

export default GroupsTab;
