import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";
import moment from "moment";

const getProgramByCoachIdService = (coachId) => {
  if (!coachId) {
    throw Error("Client id not found");
  }
  return getProgramByClientId(coachId);
};

const getProgramByClientId = async (coachId) => {
  const topicRef = collection(db, "programs");
  const q = query(
    topicRef,
     where("coachId", "==", coachId),
     );
  const snapshot = await getDocs(q);
//   console.log(snapshot.docs,"snapshot.docsv")
  const programdata = snapshot.docs
    .map((e) => {
      const data = e.data();
      console.log(data,"lll")

       return {
         id: e.id,
         ...data,
       };
    })
    const sortedProgramData = programdata.sort((a, b) => b.createAt.seconds - a.createAt.seconds);
  return sortedProgramData;
};

export default getProgramByCoachIdService;