import OrderedListOfClients from "./clients-in-tasks/clients-section";
import OrderedTasks from "./orderedTasks/ordered-tasks";
import classes from "./home.module.css";
const CoachDashboardHome = () => {
  return (
    <div className={classes.align}>
      <OrderedListOfClients />
      <div className={classes.dashboard}>
        <OrderedTasks />
      </div>
    </div>
  );
};

export default CoachDashboardHome;
