// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_error__686K9 {
  color: rgb(255, 0, 0, 0.8);
  font-size: 12px;
  text-align: left;
  font-weight: thin;
  padding-left: 20px;
}

.form_formAlignment__HEeC- {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form_row__KclDx {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.form_radioButtonAlign__Wwi4A {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 1rem;
  margin: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/add-user/form.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,SAAS;EACT,cAAc;AAChB","sourcesContent":[".error {\n  color: rgb(255, 0, 0, 0.8);\n  font-size: 12px;\n  text-align: left;\n  font-weight: thin;\n  padding-left: 20px;\n}\n\n.formAlignment {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n}\n\n.radioButtonAlign {\n  display: flex;\n  flex-direction: row;\n  align-items: baseline;\n  gap: 1rem;\n  margin: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `form_error__686K9`,
	"formAlignment": `form_formAlignment__HEeC-`,
	"row": `form_row__KclDx`,
	"radioButtonAlign": `form_radioButtonAlign__Wwi4A`
};
export default ___CSS_LOADER_EXPORT___;
