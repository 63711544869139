import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash/array";
import moment from "moment";
const tasksSlice = createSlice({
  name: "Tasks",
  initialState: {
    startDate: moment().subtract("2", "days").format("YYYY-MM-DD"),
    endDate: moment().add("28", "days").format("YYYY-MM-DD"),
    topics: [],
    tasks: [],
    homeTopics: [],
    homeTasks: [],
    copiedTopic: null,
    copiedTasks: null,
  },
  reducers: {
    setTopics(state, actions) {
      // console.log(actions,'actions007')
      state.topics = actions.payload;
    },
    addTopic(state, actions) {
      state.topics.push(actions.payload);
    },
    deleteTopic(state, actions) {
      const topicId = actions.payload.topicId;
      const clientId = actions.payload.clientId;
      state.topics = state.topics.filter((t) => t.id !== topicId);
      state.tasks = state.tasks.filter((t) => t.topicId !== topicId);

      const indexHome = findIndex(
        state.homeTopics,
        (t) => t.clientId === clientId
      );
       
      const client = findIndex(
        state.topics,
        (t) => t.clientId === clientId
      );

      if (indexHome >= 0) {
        state.homeTopics[indexHome].topics = state.homeTopics[
          indexHome
        ].topics.filter((t) => t.id !== topicId);

        state.topics[client].topics=state.topics[client].topics.filter((t) => t.id !== topicId);
      }
    },
    editTopicName(state, actions) {
      const topicId = actions.payload.topicId;
      const clientId = actions.payload.clientId;
      const newTopicName = actions.payload.newTopicName;
      const index = findIndex(state.topics, (t) => t.id === topicId);
      const indexHome = findIndex(
        state.homeTopics,
        (t) => t.clientId === clientId
      );
      if (index >= 0) {
        state.topics[index].topicName = newTopicName;
      }
      if (indexHome >= 0) {
        const indexHomeTopic = findIndex(
          state.homeTopics[indexHome].topics,
          (t) => t.id === topicId
        );
        if (indexHomeTopic >= 0) {
          state.homeTopics[indexHome].topics[indexHomeTopic].topicName =
            newTopicName;
        }
      }
    },
    addTask(state, actions) {
      state.tasks.push(actions.payload);
    },
    deleteTask(state, actions) {
      const taskId = actions.payload;
      state.tasks = state.tasks.filter((t) => t.id !== taskId);
      state.homeTasks = state.homeTasks.filter((t) => t.id !== taskId);
    },
    updateTask(state, actions) {
      const taskId = actions.payload.taskId;
      console.log(taskId,"")
      const description = actions.payload.newDescription;
      const indexTask = findIndex(state.tasks, (t) => t.debuggerid === taskId);
      const indexHomeTask = findIndex(state.homeTasks, (t) => t.id === taskId);
      if (indexTask >= 0) {
        state.tasks[indexTask].description = description;
      }
      if (indexHomeTask >= 0) {
        state.homeTasks[indexHomeTask].description = description;
      }
    },
    setTasks(state, actions) {

      const newTasks = actions.payload.tasks;
  
      console.log("Current state.tasks:", state.tasks);
    
      newTasks.forEach((newTask) => {
        const existingTaskIndex = state.tasks.findIndex((task) => task.id === newTask.id);
        // debugger

    
        if (existingTaskIndex !== -1) {
          const existingTask = state.tasks[existingTaskIndex];
          if (existingTask.description !== newTask.description) {
            existingTask.description = newTask.description;
          }

          if(existingTask.Pdf!==newTask.Pdf){
            existingTask.Pdf = newTask.Pdf||{};
          }



          if(existingTask.recordingfile!==newTask.recordingfile){
            existingTask.recordingfile = newTask.recordingfile ||{};
          }

          if(existingTask.video!==newTask.video){
            existingTask.video = newTask.video || {};
          }
          state.tasks[existingTaskIndex] = { ...existingTask };
          state.homeTasks[existingTaskIndex]={...existingTask}
          // 
        }else{
          console.log(newTask,"tajsm")
          state.tasks[0] = { ...newTask };
          state.homeTasks[0]={...newTask}
          // state.tasks.push(newTask);
          // state.homeTasks.push(newTask);
        }
      });
    
      // Log the updated state.tasks
      console.log("Updated state.tasks after setTasks:", state.tasks);
    },
    
    setTaskOfTopic(state, actions) {
     
      // debugger
      const index = findIndex(
        state.topics,
        (t) => t.id === actions.payload.topicId
      );
      if (index < 0) {
        throw Error(`Unable to find the topic with ${actions.payload.topicId}`);
      } else {
        state.topics[index].tasks = [];
        state.topics[index].tasks = actions.payload.tasks;
      }
    },
    setHomeTopics(state, actions) {
      state.homeTopics = actions.payload;
    },
    setHomeTask(state, actions) {
      const tasks = actions.payload.tasks ?? [];
     


      state.homeTasks = state.homeTasks.concat(tasks).filter(function (t) {
        return this[t.id] ? false : (this[t.id] = true);
      }, {});
    },
    addCommentToTopic(state, actions) {
      debugger
      var indexOuter = -1;
      var indexInner = -1;
      state.homeTopics.forEach((topicByClient, i) => {
        if (indexInner === -1) {
          indexInner = findIndex(topicByClient.topics, (t) => t.id === actions.payload.topicId);
          indexOuter = i;
        }
      });
      if (indexInner < 0 || indexOuter < 0) {
        throw Error("Topic not found");
      } else {
        if (state.homeTopics[indexOuter].topics[indexInner].comments) {
          state.homeTopics[indexOuter].topics[indexInner].comments.push(
            actions.payload.comment
          );
        } else {
          state.homeTopics[indexOuter].topics[indexInner].comments = [
            actions.payload.comment,
          ];
        }
      }
    },
    copy(state, actions) {
      const topicId = actions.payload;
      const topic = state.topics.find((e) => e.id === topicId);
      if (topic) {
        state.copiedTopic = topic;
        const tasks = state.tasks.filter((e) => e.topicId === topicId);
        if (tasks) {
          state.copiedTasks = tasks;
        }
      }
    },
    paste(state, actions) {
      state.copiedTasks = null;
      state.copiedTopic = null;
    },
    setStartDate(state, actions) {
      const endDate = moment(state.endDate, "YYYY-MM-DD");
      const startDate = moment(actions.payload, "YYYY-MM-DD");
      if (endDate.isAfter(startDate)) {
        state.startDate = actions.payload;
      }
    },
    setEndDate(state, actions) {
      const endDate = moment(actions.payload, "YYYY-MM-DD");
      const startDate = moment(state.startDate, "YYYY-MM-DD");
      if (endDate.isAfter(startDate)) {
        state.endDate = actions.payload;
      }
    },

    updateTaskList(state, actions) {
      console.log(state.tasks,"12")
      // debugger
  
      const taskId = actions.payload.task.taskId;
      // debugger
      const updatedTask = actions.payload.task;

      console.log(taskId,"")
      // console.log(tasks,"hello brother")
      const indexTask = findIndex(state.tasks, (t) => t.id === taskId);
      // console.log(indexTask,"indexTaskindexTask")
      const indexHomeTask = findIndex(state.homeTasks, (t) => t.id === taskId);
      console.log(state.tasks[indexTask]," state.tasks[indexTask]  state.tasks[indexTask" )
    
      if (indexTask >= 0) {
        state.tasks[indexTask] = { ...state.tasks[indexTask], ...updatedTask };
      }
    
      // Update only if the task is found in homeTasks
      if (indexHomeTask >= 0) {
        state.homeTasks[indexHomeTask] = { ...state.homeTasks[indexHomeTask], ...updatedTask };
      }
    },
  },
});

export const tasksActions = tasksSlice.actions;
export default tasksSlice;
