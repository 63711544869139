import { query, collection, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";
import moment from "moment";
import { findIndex } from "lodash/array";

const getTopicsHomeService = async (coachId) => {
  if (!coachId) {
    throw Error("Coach Id not found");
  }
  const topics = await getTopicsHome(coachId);
  // return topicsToTopicsByDate(topics);
  const orderedTopics = topicsToTopicsByClient(topics);
  return orderedTopics;
};

const topicsToTopicsByClient = (topics) => {
  const topicOrderByClient = [];
  topics.forEach((at) => {
    const index = findIndex(topicOrderByClient, (tClient) => {
      return tClient.clientId === at.clientId;
    });
    const topic = at;
    if (index < 0) {
      if (at.clientId) {
        topicOrderByClient.push({
          clientId: at.clientId,
          topics: [topic],
        });
      } else if (at.groupId) {
        topicOrderByClient.push({
          groupId: at.groupId,
          topics: [topic],
        });
      }
    } else {
      topicOrderByClient[index].topics.push(topic);
    }
  });
  return topicOrderByClient;
};

const getTopicsHome = async (coachId) => {
  //You should must have an index for the below query to execute
  const topicsRef = collection(db, "topics");
  const q = query(
    topicsRef,
    where("coachId", "==", coachId),
    where(
      "createdAt",
      ">=",
      moment().subtract(1, "day").toDate(),
      orderBy("createdAt", "desc")
    )
  );

  console.log(q,"qqq")
  const snapshot = await getDocs(q);
  console.log(snapshot,"snapshot")
  console.log(snapshot.docs,"snapshotdocsdocs")
  const topics = snapshot.docs
    .map((e) => {
      const data = e.data();
      data.createdAt = moment(data.createdAt.toDate()).format("DD/MM/YYYY");
      return {
        id: e.id,
        ...data,
      };
    })
    .sort((a, b) => {
      if (a.createdAt === b.createdAt) {
        return +a.topicOrder - +b.topicOrder;
      }
      return true;
    });

    console.log(topics,"master")
  return topics;
};

export default getTopicsHomeService;
