import { doc, deleteDoc,getDocs,collection } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

const deleteTopicService = async (topicId) => {
  if (!topicId) {
    throw Error("Topic id not found");
  }
  await deleteTopic(topicId);
};

const deleteTopic = async (topicId) => {
  const topicDoc = doc(db, "topics", topicId);
  const taskRef = collection(topicDoc, "tasks");

  const tasksQuerySnapshot = await getDocs(taskRef);

  // Delete each task
  const deleteTasksPromises = tasksQuerySnapshot.docs.map(async (taskDoc) => {
    await deleteDoc(taskDoc.ref);
  });

  // Wait for all tasks to be deleted
  await Promise.all(deleteTasksPromises);

  // Finally, delete the topic
  await deleteDoc(topicDoc);
  // await deleteDoc(topicDoc);
};

export default deleteTopicService;
