import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupTile from "./group-tile";
import { getClients } from "../../../Store/Coach/Client/client-actions";
import classes from "./groups.module.css";
import LoadingIndicator from "../../../Components/UI/loader";
import { getGroups } from "../../../Store/Coach/groups/group-actions";
import { v1 } from "uuid";

const Groups = ({ searchTerm }) => {
  const user = useSelector((state) => state.auth.userInfo);
  const groups = useSelector((state) => state.groups.groups);
  const [filteredGroups, setFilteredGroups] = useState([...groups]);
  const dispatch = useDispatch();
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getClientsFn = async () => {
      try {
        setError(undefined);
        setIsLoading(true);
        await getClients(user.id)(dispatch);
        setIsLoading(false);
      } catch (e) {
        setError(e.toString());
        setIsLoading(false);
      }
    };
    const getAllGroups = async () => {
      try {
        setError(undefined);
        setIsLoading(true);
        await getGroups(user.id)(dispatch);
        setIsLoading(false);
      } catch (e) {
        setError(e.toString());
        setIsLoading(false);
      }
    }
    if (user) {
      getClientsFn();
      getAllGroups();
    }
  }, [user, dispatch]);
  useEffect(() => {
    if (groups.length > 0 && searchTerm.length > 0) {
      setFilteredGroups([...(groups.filter((c) => (
        c?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
      )))
      ]);
    } else {
      setFilteredGroups([...groups]);
    }
  }, [groups, searchTerm]);
  const component = error ? (
    <p className={classes.error}>{error}</p>
  ) : isLoading ? (
    <div className={classes.center}>
      <LoadingIndicator />
    </div>
  ) : filteredGroups.length ? (
    filteredGroups.map((e) => <GroupTile key={v1()} groupName={e.name} totalClients={e?.clients?.length ?? 0} groupId={e.id} />)
  ) : (
    <p className={classes.noClient}>
      No groups found. Please click the '+ Add Group' Button to add a group
    </p>
  );
  return component;
};

export default Groups;
