import Logout from "../../../../Components/logout/logout";
import classes from "./add-group.module.css";
import GroupForm from "./form";
import { Wrap, Card } from "../../../../Components/index";
const AddGroup = (props) => {
  return (
    <Wrap className={classes.marginTop}>
      <h1>{props.title}</h1>
      <Card className={`${classes.center}`}>
        <GroupForm buttonText={props.buttonText} role={props.role} />
      </Card>
      <Logout />
    </Wrap>
  );
};
export default AddGroup;
