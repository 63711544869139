// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-coach-menu_align__ah9gw {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #293c89;
  padding: 0.5rem;
}

.admin-coach-menu_row__Y34Lw {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.admin-coach-menu_title__RLr9i {
  color: white;
  font-size: 18px;
}

.admin-coach-menu_logo__4fIwU {
  height: 1.7rem;
  width: 1.7rem;
  object-fit: contain;
  margin: 0px 0px 0px 5px;
}

.admin-coach-menu_ul__nKato {
  list-style-type: none;
}
.admin-coach-menu_ul__nKato > li {
  display: inline;
  margin: 8px;
  font-size: 14px;
}
.admin-coach-menu_active__gKNEX {
  color: rgb(256, 256, 256, 1);
  text-decoration: none;
}
.admin-coach-menu_inactive__cdJpC {
  color: rgb(256, 256, 256, 0.5);
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/admin-dashboard/menu/admin-coach-menu.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,eAAe;EACf,WAAW;EACX,eAAe;AACjB;AACA;EACE,4BAA4B;EAC5B,qBAAqB;AACvB;AACA;EACE,8BAA8B;EAC9B,qBAAqB;AACvB","sourcesContent":[".align {\n  position: fixed;\n  top: 0;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  background: #293c89;\n  padding: 0.5rem;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 1rem;\n}\n\n.title {\n  color: white;\n  font-size: 18px;\n}\n\n.logo {\n  height: 1.7rem;\n  width: 1.7rem;\n  object-fit: contain;\n  margin: 0px 0px 0px 5px;\n}\n\n.ul {\n  list-style-type: none;\n}\n.ul > li {\n  display: inline;\n  margin: 8px;\n  font-size: 14px;\n}\n.active {\n  color: rgb(256, 256, 256, 1);\n  text-decoration: none;\n}\n.inactive {\n  color: rgb(256, 256, 256, 0.5);\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"align": `admin-coach-menu_align__ah9gw`,
	"row": `admin-coach-menu_row__Y34Lw`,
	"title": `admin-coach-menu_title__RLr9i`,
	"logo": `admin-coach-menu_logo__4fIwU`,
	"ul": `admin-coach-menu_ul__nKato`,
	"active": `admin-coach-menu_active__gKNEX`,
	"inactive": `admin-coach-menu_inactive__cdJpC`
};
export default ___CSS_LOADER_EXPORT___;
