// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-frame_background__4h7sv {
  background-color: #253341;
  margin: 0.5rem 0rem;
  padding: 0.5rem;
  width: 80%;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.date-frame_white__DvIHA {
  color: lightgray;
  font-size: 14px;
  align-self: flex-start;
  margin-left: 8px;
}



`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/home/orderedTasks/components/topics/date-frame.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,UAAU;EACV,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".background {\n  background-color: #253341;\n  margin: 0.5rem 0rem;\n  padding: 0.5rem;\n  width: 80%;\n  border-radius: 0.5rem;\n  display: flex;\n  flex-direction: column;\n  margin: auto;\n}\n.white {\n  color: lightgray;\n  font-size: 14px;\n  align-self: flex-start;\n  margin-left: 8px;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `date-frame_background__4h7sv`,
	"white": `date-frame_white__DvIHA`
};
export default ___CSS_LOADER_EXPORT___;
