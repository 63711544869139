import { db } from "../../Configuration/initialize-firebase";
import { collection, addDoc } from "firebase/firestore";

const addTaskToTopicService = async (taskInfo, topicId) => {
  if (
    !topicId ||
    !taskInfo ||
    !taskInfo.description ||
    !taskInfo.status ||
    !taskInfo.taskOrder
  ) {
    throw Error("Task info is incorrect");
  }
  const id = await addTaskToCloud(taskInfo, topicId);
  taskInfo.id = id;
  return taskInfo;
};

const addTaskToCloud = async (taskInfo, topicId) => {
  const topicRef = collection(db, "topics");
  const taskRef = collection(topicRef, topicId, "tasks");
  const response = await addDoc(taskRef, taskInfo);
  return response.id;
};

export default addTaskToTopicService;
