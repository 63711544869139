import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GroupInfo from "./components/group-info";
import classes from "./main.module.css";
import Topics from "./components/Topic/topics"
const GroupsPage = () => {
  const { id } = useParams();
  const groups = useSelector((state) => state.groups.groups);
  let group = groups.find((e) => e.id === id);
  return (
    <div className={classes.background}>
      {id ? (
        group ? (
          <div>
            <GroupInfo group={group} />
            <Topics groupId={group.id} />
          </div>
        ) : (
          <p className={classes.error}>Client not found</p>
        )
      ) : (
        <p className={classes.error}>Id in the params is null</p>
      )}
    </div>
  );
};

export default GroupsPage;
