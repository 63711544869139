import { collection, arrayUnion, doc, writeBatch, updateDoc, arrayRemove } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

const editGroupService = async ({ name, clients, groupId, clientsIdToRemoveGroup ,userId}) => {
    if (
        !name ||
        !groupId ||
        clients.length === 0
    ) {
        throw Error("Group Data is incorrect");
    }
    await editGroupOnCloud({ name, clients,userId }, groupId);
    await bulkClientRemove(clientsIdToRemoveGroup, groupId);
    await bulkClientUpdate(clients, groupId);
    return {
        id: groupId, name, clients
    };
};

const bulkClientUpdate = async (clients, groupId) => {
    var batch = writeBatch(db);
    const userRef = collection(db, "users");
    for (let c of clients) {
        let docRef = doc(userRef, c.id);
        batch.update(docRef, {
            groupIds: arrayUnion(groupId)
        });
    }
    await batch.commit();
}

const bulkClientRemove = async (clients, groupId) => {
    var batch = writeBatch(db);
    const userRef = collection(db, "users");
    for (let id of clients) {
        let docRef = doc(userRef, id);
        batch.update(docRef, {
            groupIds: arrayRemove(groupId)
        });
    }
    await batch.commit();
}


const editGroupOnCloud = async (groupData, id) =>
    await updateDoc(doc(db, "groups", id), groupData);

export default editGroupService;
