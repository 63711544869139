// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unit-topic_alignButtons__j7Nz6 {
  display: flex;
  flex-direction: row;
}

.unit-topic_alignTop__T0uBQ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.unit-topic_alignButtonAndField__wlcsK {
  display: flex;
  width: 90%;
  flex-direction: row;
  align-items: center;
}

.unit-topic_title__rZGoz {
  color: whitesmoke;
  font-size: 1rem;
  width: 88%;
  text-align: left;
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/unit-topic.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,UAAU;EACV,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,UAAU;EACV,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".alignButtons {\n  display: flex;\n  flex-direction: row;\n}\n\n.alignTop {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 90%;\n}\n\n.alignButtonAndField {\n  display: flex;\n  width: 90%;\n  flex-direction: row;\n  align-items: center;\n}\n\n.title {\n  color: whitesmoke;\n  font-size: 1rem;\n  width: 88%;\n  text-align: left;\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignButtons": `unit-topic_alignButtons__j7Nz6`,
	"alignTop": `unit-topic_alignTop__T0uBQ`,
	"alignButtonAndField": `unit-topic_alignButtonAndField__wlcsK`,
	"title": `unit-topic_title__rZGoz`
};
export default ___CSS_LOADER_EXPORT___;
