import React, { useEffect, useState } from "react";
import classes from "./Task.module.css";
import "../../assets/style.css";
import axios from "axios";
import Swal from "sweetalert2";
// import { addTaskToTopic } from '../../Store/Coach/tasks/tasks-actions';
// import AddTopic from '../coach-dashboard/tasks/components/Topic/add-topic';
// AddTopic
// import AddTopic from '../coach-dashboard/tasks/components/Topic/add-topic';
import { RotatingLines } from "react-loader-spinner";
import { BallTriangle  } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import {
  sendNotification,
  addTopic,
} from "../../Store/Coach/tasks/tasks-actions";
import SucessModal from "./SucessMoal";
import VoicedRecoder from "./VoicedRecoder";
import { useParams } from "react-router-dom";
import { values } from "lodash";
import PdfReader from "../../Components/pdf-reader/PdfReader";
import VedioPlayer from "../../Components/VedioPlayer/VedioPlayer";
import AddToUploadVideo from "./AddToUploadVideo";
import AddToUploadpdf from "./AddUploadPdf";

const cloudName = "db3gzsopp";
const uploadPreset = "u2muuej0";

export default function AddTask({ fetchData }) {
  const { id } = useParams();
  console.log(id,fetchData,"my datttttta");
  const [task, setTask] = useState({
    date: "",
    task_name: "",
    todolist: "",
    Pdf: {},
    recordingfile: {},
    video: {},
  });
  
  const [filename, setfilename] = useState();
  const allTopics = useSelector((state) => state.topics.topics);
  const dispatch = useDispatch();
  const selectedClient = useSelector((state) => state.clients.selectedClient);
  const coach = useSelector((state) => state.auth.userInfo);
  const topic = useSelector((state) => state.topics.copiedTopic);
  const tasks = useSelector((state) => state.topics.copiedTasks);
  const [btndisable, setBtnDisable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [show, setshow] = useState(false);
  const [errors, setErrors] = useState({});
  const [VoicedRecodering, setVoicedRecodering] = useState(false);
  const [loader, setloader] = useState(false);
  const [viewpdffile, setviewpdffile] = useState(true);
  const [viewVediofile, setviewVediofile] = useState(true);
  const [progressBar,setprogressbar] =useState(0)
  const [recordingStarted, setRecordingStarted] = useState(false); 
  console.log("helllll",progressBar)

  const currentDate = new Date();


  console.log(recordingStarted, "recordingSTATETTE")





  const onSubmit = async (event) => {
   
    event.preventDefault();
    const validationErrors = validateForm(task);

    let tasklogCounter = localStorage.getItem('tasklogCounter');
     
 if (tasklogCounter) {
    toast.error("Please save recording before submitting.");
      return;
  }

    else if (Object.keys(validationErrors).length === 0) {
      setloader(true)
      try {
        // Proceed if there are no validation errors
        await dispatch(addTopic(task, id, coach.id));

        // Reset the task state and perform other actions

        const modal = document.getElementById("exampleModal");
        if (modal) {
          modal.classList.remove("show");
          modal.style.display = "none";
          const backdrop = document.getElementsByClassName("modal-backdrop")[0];
          if (backdrop) backdrop.style.display = "none";
          setTask({
            date: "",
            task_name: "",
            todolist: "",
            Pdf: {},
            recordingfile: {},
            video:{}
          });
          setIsModalOpen(!isModalOpen);
          setErrors({})
          setshow(!show);
          setloader(false)
          fetchData();
          Swal.fire({
            title: "Sucess",
            text: "The task has been sent successfully to your clients!",
            icon: "success",
          });
          event.target.disabled = false;
          localStorage.removeItem('tasklogCounter');
        }
      } catch (err) {
        console.log(err.message,"code hetre")
         fetchData();
        
      }
     
    } 
    
    else {
      // If there are validation errors, set the errors object to show error messages
      setErrors(validationErrors);
    }
  };

  const eventHandler = (e) => {
    const { name, value } = e.target;

    if (name === "date") {
      const selectedDate = new Date(value);
      selectedDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);
      console.log(selectedDate, "selectedDate");
      console.log(currentDate, "currentDate");
      if (selectedDate < currentDate) {
        toast.error("Please select a future date.");
      }
    }

    // Update the task state irrespective of date validation
    setTask((prevTask) => ({ ...prevTask, [name]: value }));
  };

  const validateForm = (values) => {
    let errors = {};
    const selectedDate = new Date(values.date);
    selectedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    if (!values.date) {
      errors.date = "Date is required";
    }
    if (selectedDate < currentDate) {
      errors.date = "Please fill the valid date";
    }
    if (!values.task_name) {
      errors.task_name = "Task name is required";
    }
    if (!values.todolist) {
      errors.todolist = "Todo List is required";
    }

    return errors;
  };

  const closeModals = () => {
    const modal = document.getElementById("exampleModal");
    if (modal) {
      modal.classList.remove("show");
      modal.style.display = "none";
      const backdrop = document.getElementsByClassName("modal-backdrop")[0];
      if (backdrop) backdrop.style.display = "none";
    }

    // Reset the form fields to their initial state
    setTask({
      date: "",
      task_name: "",
      todolist: "",
      Pdf: {},
      recordingfile: {},
      video:{}
    });
    setErrors({});
  };

  const closeHandler = () => {
    setviewpdffile(true);
    setTask((prevState) => ({
      ...prevState,
      file: {},
    }));
  };


  // useEffect(() => {
  //   const modalElement = document.getElementById("exampleModal");
  //   if (modalElement) {
  //     debugger
  //     modalElement.addEventListener("hidden.bs.modal", () => {
  //       setTask({
  //         date: "",
  //         task_name: "",
  //         todolist: "",
  //         Pdf: {},
  //         recordingfile: {},
  //         video: {},
  //       });
  //       setErrors({});
  //     });
  //   }
  // }, [])

  return (
    <>
      <button
        type="button"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        Add To Task
      </button>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog add-task-comment-wrapperr" role="document">
          <div class="modal-content add_task_wrap">
            <div class="modal-header">
              <h5 class="modal-title add_task_text" id="exampleModalLabel">
                Add Task
              </h5>
              <button
                type="button"
                class="close add_task_close_btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModals}
              >
                <span aria-hidden="true">
                  <i class="fa-solid fa-circle-xmark"></i>
                </span>
              </button>
            </div>
            <div class="modal-body">
              <div className={classes.container}>
                <form className={classes.contact}>
                  <div className="date_picker">
                    <input
                      type="date"
                      name="date"
                      className={classes.datepicker}
                      autoComplete="off"
                      onChange={eventHandler}
                      min={new Date().toISOString().split("T")[0]}
                      value={task ? task.date ?? "" : ""}
                    />
                    {errors.date && (
                      <span className={classes.error}>{errors.date}</span>
                    )}
                  </div>
                  <div className="add_task_fiedset">
                    <label className={classes.nme} htmlFor="">
                      Topic name
                    </label>
                    <input
                      placeholder="task name"
                      className={classes.namefild}
                      name="task_name"
                      type="text"
                      tabIndex={2}
                      onChange={eventHandler}
                      value={task ? task.task_name ?? task.task_name : ""}
                    />
                    {errors.task_name && (
                      <span className={classes.error}>{errors.task_name}</span>
                    )}
                  </div>
                  <div className="add_task_fiedset">
                    <label className={classes.nme} htmlFor="">
                      Todo List
                    </label>
                    <textarea
                      placeholder="Todo List"
                      className={classes.namefild}
                      name="todolist"
                      type="text"
                      tabIndex={2}
                      onChange={eventHandler}
                      value={task ? task.todolist ?? task.todolist : ""}
                    />
                    {errors.todolist && (
                      <span className={classes.error}>{errors.todolist}</span>
                    )}
                  </div>

                  {loader ? (
                    <>
                    <div className={classes.loaderwrapper}>
                    <div style={{ marginTop: '200px' }}>
                          {/* <BallTriangle color="white" height={40} width={40} /> */}
                        </div>
                      <RotatingLines
                        height="40"
                        width="40"
                        strokeColor="white"
                        radius="6"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                        
                      />
                        
                    </div>
                  
                    </>
                  ) : null}

                  <div className="add_task_fiedset">
                    <label className={classes.nme} htmlFor="">
                      Voice Rec
                    </label>
                    <VoicedRecoder
                      setTask={setTask}
                      setBtnDisable={setBtnDisable}
                      loader={loader}
                      setloader={setloader}
                      task={task}
                      progressBar={progressBar} 
                      setprogressbar={setprogressbar}
                      recordingStarted={recordingStarted} 
                      setRecordingStarted={setRecordingStarted} 
                    />
                  </div>
                
                  <AddToUploadpdf setBtnDisable={setBtnDisable} setloader={setloader} setTask={setTask} task={task} progressBar={progressBar} setprogressbar={setprogressbar}/>
                  <AddToUploadVideo setBtnDisable={setBtnDisable} setloader={setloader} setTask={setTask} task={task}progressBar={progressBar} setprogressbar={setprogressbar}/>
                </form>
              </div>
            </div>
            <div class="modal-footer add-close-btn">
              <button
                type="button"
                id="closeModalButton"
                class="btn btn-secondary close_add_btn"
                data-dismiss="modal"
                onClick={closeModals}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary blue_add_btn"
                disabled={btndisable} 
                onClick={(event) => {
                  event.target.disabled = true; // Disable the button
                  onSubmit(event); // Call the onSubmit function
                }}
              >
                Add Task
              </button>

            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
