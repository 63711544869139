import classes from "./logo.module.css";
const Logo = () => {
  return (
    <div className={classes.align}>
      <img
        className={classes.logo}
        src={require("../../assets/logos/logo.png")}
        alt="Logo"
      />
      {/* <h1>Luc Labarile</h1> */}
    </div>
  );
};
export default Logo;
