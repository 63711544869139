import { collection, addDoc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

const addTopicClientService = async (programData) => {
  try {
    return await addProgramToCloud(programData);
  } catch (error) {
    throw new Error(`Error adding program to Firestore: ${error}`);
  }
};

const addProgramToCloud = async (programData) => {
  try {
    console.log(programData,"programDataprogramData")
    const docRef = await addDoc(collection(db, "programs"), programData);
    return docRef; // You can return the reference to the added document if needed
  } catch (error) {
    console.log(error.message)
    throw new Error(`Error adding program document: ${error}`);
  }
};


export default addTopicClientService

