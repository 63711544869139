import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Authentication/auth-slice";
import coachesSlice from "./Admin/coaches/coaches-slice";
import clientSlice from "./Coach/Client/client-slice";
import tasksSlice from "./Coach/tasks/tasks-slice";
import groupSlice from "./Coach/groups/group-slice";
import programSlice from "./program/programSlice";
const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    coaches: coachesSlice.reducer,
    clients: clientSlice.reducer,
    topics: tasksSlice.reducer,
    groups: groupSlice.reducer,
    programs:programSlice.reducer
  },
});

export default store;
