import { db } from "../../Configuration/initialize-firebase";
import { writeBatch, collection, doc } from "firebase/firestore";
const addTasksToTopicService = async (tasks, topicId) => {
  if (!topicId || checkTask(tasks)) {
    throw Error("Task info is incorrect");
  }
  return await addTasksToCloud(tasks, topicId);
};

const addTasksToCloud = async (tasks, topicId) => {
  var batch = writeBatch(db);
  const topicRef = collection(db, "topics");
  const taskRef = collection(topicRef, topicId, "tasks");
  const taskToReturn = [];
  for (let t of tasks) {
    let docRef = doc(taskRef);
    const task = {
      description: t.description,
      status: "inProgress",
      taskOrder: t.taskOrder,
    };
    taskToReturn.push({
      topicId,
      ...task,
    });

    batch.set(docRef, task);
  }
  await batch.commit();
  return taskToReturn;
};

const checkTask = (tasks) => {
  for (let taskInfo of tasks) {
    if (
      !taskInfo ||
      !taskInfo.description ||
      !taskInfo.status ||
      !taskInfo.taskOrder
    ) {
      throw Error("Task info is incorrect");
    }
  }
};

export default addTasksToTopicService;
