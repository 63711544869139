import React, { useRef, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import classes from './Calender.module.css';
import $ from 'jquery';
import 'magnific-popup';
import { getTopicsOfClient } from '../../Store/Coach/tasks/tasks-actions';
import { useDispatch, useSelector } from 'react-redux';
import UpdateToTask from '../Task/UpdateToTask';





const MyCalendar = ({ client,fetchData }) => {
  console.log(fetchData,"fetchData")
  const calendarRef = useRef(null);
  const dispatch = useDispatch();
  const topics = useSelector((state) => state.topics.topics);
  const [showModal,setshowModal] =useState(false)
  const [eventId,seteventId]=useState()
  console.log(topics,"topicstopics")



  const colors = ['#0D4C87', '#875922', '#7D2222','#0D6626'];

  const updateCalendarEvents = () => {
    const mappedEvents = topics.map((topic, index) => {
      if (topic && topic.createdAt) {
        const date = new Date(topic.createdAt.seconds * 1000 + topic.createdAt.nanoseconds / 1e6);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        return {
          id: topic.id,
          title: topic.topicName, 
          start: formattedDate,
          end: formattedDate,
          className: 'event-url',
          backgroundColor: colors[index % colors.length],
          allDay: true,
        };
      } else {
        console.error('createdAt is undefined for topic:', topic);
        return null; // or handle this case appropriately
      }
    });
  
    // Filter out potential null values
    return mappedEvents.filter(event => event !== null);
  };
  
  

  
  const handleEventClick =(clickInfo)=>{
    const eventId = clickInfo.event.id; 
    
    const eventTitle = clickInfo.event.title;
    const eventStartDate = clickInfo.event.start;
    seteventId(eventId)
    setshowModal(true)
  }

  return (
    <>
    <div className={classes.custom_calendar}>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
        }}
        dayMaxEventRows={4}
        // select={handleDateSelect} 
        events={updateCalendarEvents()} 
        eventClick={handleEventClick}   
           
      />
        {showModal?(<UpdateToTask  setshowModal={setshowModal} showModal={showModal} eventId={eventId} fetchData={fetchData} seteventId={seteventId}/>):null} 
    </div>
 
    </>
  );
};

export default MyCalendar;
