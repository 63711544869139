import taskStatus from "../../util/task-status";
import classes from "./unit-task.module.css";
import Comment from "./comment";
import makeLinkClickableInText from "../../util/make-link-clickable-in-text";
import IconButton from "./icon-button";
import iconType from "../../util/icon-type";
import { deleteTask, updateTask } from "../../Store/Coach/tasks/tasks-actions";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import AddFormattingTextField from "./add-formatting-text-field";

const UnitTask = (props) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const status =
    props.task.status === taskStatus.complete ? (
      <p>✅</p>
    ) : props.status === taskStatus.failed ? (
      <p>❌</p>
    ) : (
      <p>🔄</p>
    );
  const description = makeLinkClickableInText(props.task.description);
  const onDeleteHandler = async () => {
    try {
      await deleteTask(props.task.id, props.topicId)(dispatch);
    } catch (e) {
    }
  };
  const onEditingHandler = () => {
    setIsEditing((state) => !state);
  };
  const onUpdateTask = async (value) => {
    updateTask(props.task.id, props.topicId, value)(dispatch);
    setIsEditing((state) => !state);
  };
  return (
    <div className={classes.background}>
      {isEditing ? (
        <AddFormattingTextField
          value={props.task.description}
          onSubmit={onUpdateTask}
        />
      ) : (
        <div className={classes.align}>
          {" "}
          <div>{status}</div>
          <p
            className={classes.task}
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
        </div>
      )}
      {isEditing ? (
        <div className={classes.buttons}>
          <IconButton icon={iconType.close} onClick={onEditingHandler} />
        </div>
      ) : (
        <div className={classes.buttons}>
          <IconButton icon={iconType.delete} onClick={onDeleteHandler} />
          <IconButton icon={iconType.edit} onClick={onEditingHandler} />
        </div>
      )}

      <div>
        {props.task.comments &&
          props.task.comments.map((e, i) => <Comment key={i} comment={e} />)}
      </div>
    </div>
  );
};

export default UnitTask;
