// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.greetings_colorWhite__d7T5r {
  color: whitesmoke;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/home/orderedTasks/components/greetings.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB","sourcesContent":[".colorWhite {\n  color: whitesmoke;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorWhite": `greetings_colorWhite__d7T5r`
};
export default ___CSS_LOADER_EXPORT___;
