// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-tile_tile__Gjgfl {
  padding: 0.5rem;
  margin: 2px 0px;
  background-color: #19222f;
  border-radius: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  /* gap: 5rem; */
}

.group-tile_textInTile__gkLDX {
  color: lightgray;
  font-size: 16px;
  width: 7rem;
}

.group-tile_innerDiv__n24xA {
  display: flex;
  width: 80%;
  gap: 5rem;
}

.group-tile_tile__Gjgfl:hover {
  background-color: #243041;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/groups/group-tile.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,2BAA2B;EAC3B,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,UAAU;EACV,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".tile {\n  padding: 0.5rem;\n  margin: 2px 0px;\n  background-color: #19222f;\n  border-radius: 10px;\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  /* gap: 5rem; */\n}\n\n.textInTile {\n  color: lightgray;\n  font-size: 16px;\n  width: 7rem;\n}\n\n.innerDiv {\n  display: flex;\n  width: 80%;\n  gap: 5rem;\n}\n\n.tile:hover {\n  background-color: #243041;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tile": `group-tile_tile__Gjgfl`,
	"textInTile": `group-tile_textInTile__gkLDX`,
	"innerDiv": `group-tile_innerDiv__n24xA`
};
export default ___CSS_LOADER_EXPORT___;
