import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { AssinTaskforClient } from '../../../Store/program/program-action';
import SucessModal from '../../../Components/Modal/SucessModal'
import { parseClassNames } from '@fullcalendar/core/internal';
import classes from './modal.module.css'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { RotatingLines } from "react-loader-spinner";
import { BallTriangle  } from "react-loader-spinner";

// import 'bootstrap/dist/css/bootstrap.min.css';


export default function ClientModal({ setshowModal, showModal, selectId }) {
  const navigate =useNavigate()
  const clients = useSelector((state) => state.clients.clients);
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [successModal, setsuccessModal] = useState(false)
  const [calender, setcalender] = useState(false)
  const [clientsData, setClientsData] = useState([])
  const [loader,setloader]=useState(false)

  console.log(selectedClients, "selectedClients")
  console.log(clients, "clientsclients")

  const handleClose = () => {
    setshowModal(false);
    setShow(false);
  };

  useEffect(() => {
    setShow(true);
  }, []);

  const taskAssignHandler = (event, clientInfo) => {
    if (event.target.checked) {
      setClientsData((prevData) => {
        let array = [...prevData];
        let index = array.findIndex((item) => item.id === clientInfo.id);
        if (index !== -1) {
          array[index] = { ...array[index], calender: true };
        }
        return [...array];
      });
    } else {
      setClientsData((prevData) => {
        let array = [...prevData];
        let index = array.findIndex((item) => item.id === clientInfo.id);
        if (index !== -1) {
          array[index] = { ...array[index], calender: false };
        }
        return [...array];
      });

      setSelectedClients((prevData)=>{
         let selectedClientsdata =[...selectedClients]
         let filter = selectedClients.filter((item) => item.clientId !== clientInfo.id);
         return[...filter]
      })
    }
  };



  const clientAssignTaskHandler = async () => {
    try {
      setloader(true)
      if (selectedClients.length > 0) {
        let isValid = true;
  
        selectedClients.forEach((data) => {
          if (!data.date) {
            isValid = false;
            toast.error("Date is required for each selected client.");
            return; // Exit the loop early if a client is missing a date
          }
        });
  
        if (isValid) {
          await dispatch(
            AssinTaskforClient(
              selectId,
              selectedClients
            )
          );
          setShow(false);
          setloader(false)

          Swal.fire({
            title: "Sucess",
            text: "Program has been sent sucessfully to  your client",
            icon: "success"
            });
  
           setTimeout(() => {
            navigate('/coach-dashboard/clients')
          }, 500);
        }
      } else {
        toast.error("Please select at least one client.");
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  
  const dateHandler = (event, clientInfo) => {
    let data = {
      coachId: clientInfo.coachId,
      clientId: clientInfo.id,
      date: event.target.value,
    };
    setSelectedClients((prevState) => [...prevState, data])
  }


  useEffect(() => {
    setClientsData(clients)
  }, [])
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static"  >
        <div className={classes.client_user_moadl_box}>
                  {loader ? (
                    <div className={classes.loaderwrapper}>
                   <div style={{ marginTop: '200px' }}>
                          <BallTriangle color="white" height={40} width={40} />
                        </div>
                      {/* loading..... */}
                    </div>
                  ) : null}
        <Modal.Header  closeButton={false}  >
          <Modal.Title>Client List</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modolviewoverflowscroll'>
          <Table>
            <thead>
              {clientsData.length > 0 ? (
                clientsData.map((data) => {
                  return (
                    <tr className='checkbox_wrap_client_model' key={data.id} style={{ border: 'none' }}>
                      <th>
                        {data.firstName} {data.lastName}
                      </th>
                      {data.calender ? (
                       
                          <th>
                            <input type='date' onChange={(event) => { dateHandler(event, data) }}  className={classes.date_picker}   min={new Date().toISOString().split('T')[0]}/>
                          </th>

                      ) : null}
                      <th >
                        <input type='checkbox' className='' onChange={(event) => taskAssignHandler(event, data)} />
                      </th>
                    </tr>
                  );
                })
              ) : null} 
            </thead>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          {selectedClients.length > 0 && (
            <Button variant='primary' onClick={clientAssignTaskHandler}>
              Next
            </Button>
          )}
        </Modal.Footer>
        </div>
      </Modal>
      {/* {successModal ? (<SucessModal successModal={successModal} setsuccessModal={successModal} />) : null} */}
    </>
  );
}
