import HomeTopic from "./home-topic";
import classes from "./home-topics.module.css";
import React from "react";

const HomeTopics = ({ topics, clientId, groupId }) => {
  console.log(topics,"helloooo game ")
  const topicsComponenet = (topics ?? []).map((t) => (
    <HomeTopic
      key={t.id}
      topicId={t.id}
      clientId={clientId}
      groupId={groupId}
      topicName={t.topicName}
      date={t.createdAt}
      comments={t.comments}
    />
  ));
  return <div className={classes.gap}>{topicsComponenet}</div>;
};

export default React.memo(HomeTopics);
