import { useDispatch } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import classes from "./group-tile.module.css";
import { groupActions } from "../../../Store/Coach/groups/group-slice";
import IconButton from ".././../../Components/UI/icon-button";
import iconType from "../../../util/icon-type";
const GroupTile = ({ groupName, totalClients, groupId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickHandler = (event) => {
    event.preventDefault();
    dispatch(groupActions.setSelectedGroup(groupId));
    const path = generatePath("tasks/:id", { id: groupId });
    navigate(path);
  };

  const onEditHandler = () => {
    const path = "add-groups";
    dispatch(groupActions.setGroupToEdit(groupId));
    navigate(path);
  }
  return (
    <div className={classes.tile}>
      <div className={classes.innerDiv} onClick={onClickHandler}>
        <p className={classes.textInTile}>{groupName}</p>
        <p className={classes.textInTile}>{totalClients}</p>
      </div>
      <IconButton icon={iconType.edit} onClick={onEditHandler} />
    </div>
  );
};

export default GroupTile;
