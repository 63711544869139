import { useNavigate } from "react-router-dom";
import { Button } from "../../../Components";
import Coaches from "./coaches";
import classes from "./coach-tab.module.css";
import Heading from "./heading";
import { routes } from "../../../util/routes";

const CoachTab = () => {
  const navigate = useNavigate();
  const addClientHandler = (event) => {
    event.preventDefault();
    navigate(routes.adminDashboard.addCoach);
  };
  return (
    <div className={classes.background}>
      <div className={classes.center}>
        <Button text="+ Add Coaches" onClick={addClientHandler} />
        <Heading />
        <Coaches />
      </div>
    </div>
  );
};

export default CoachTab;
