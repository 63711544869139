// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form_align__enYG1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form_error__OaVwC {
  color: rgb(255, 0, 0, 0.8);
  font-size: 12px;
  text-align: left;
  font-weight: thin;
  padding-left: 20px;
}
.login-form_error1__Fpbnm {
  color: rgb(255, 0, 0, 0.8);
  font-size: 14px;
  text-align: center;
  font-weight: thin;
  padding-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/login/login-form.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,0BAA0B;EAC1B,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".align {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.error {\n  color: rgb(255, 0, 0, 0.8);\n  font-size: 12px;\n  text-align: left;\n  font-weight: thin;\n  padding-left: 20px;\n}\n.error1 {\n  color: rgb(255, 0, 0, 0.8);\n  font-size: 14px;\n  text-align: center;\n  font-weight: thin;\n  padding-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"align": `login-form_align__enYG1`,
	"error": `login-form_error__OaVwC`,
	"error1": `login-form_error1__Fpbnm`
};
export default ___CSS_LOADER_EXPORT___;
