import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";
// import { addDoc } from "firebase/firestore";
import { addMultipleClientService } from "./multple_add_topic";




const TaskAssignByProgram = async (taskId, task) => {
  if (!taskId || !task) {
    throw Error("Task or topic description not found");
  }
  await TaskAssign(taskId, task);
};



const TaskAssign = async (programId, tasks) => {
  const topicRef = collection(db, 'programs');
  const programDoc = doc(topicRef, programId);

  try {
    const docSnapshot = await getDoc(programDoc);
    if (docSnapshot.exists()) {
      const programData = docSnapshot.data();
      const output = [];

      if (programData && programData.todolist && Array.isArray(programData.todolist)) {
        tasks.forEach(task => {
          let incrementedDate = new Date(task.date);
          console.log(incrementedDate,"incrementedDate")
          programData.todolist.forEach(program => {
            let date = new Date(incrementedDate.setDate(incrementedDate.getDate()));
            date = `${date.getFullYear().toString()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
            program.day.forEach(dayItem => {
              output.push({
                coachId: task.coachId,
                createDate: date,
                client: task.clientId,
                name: dayItem.name,
                todolist: dayItem.todolist,
                voicerecording: dayItem.voicerecoding,
                Pdf: dayItem.Pdf,
                video:dayItem.video
              });
            });
             date = new Date(incrementedDate.setDate(incrementedDate.getDate() + 1));
            date = `${date.getFullYear().toString()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
          });
        });
      } else {
        console.log('Program data or todolist is missing or not an array.');
      }
      await addMultipleClientService(output)
      // console.log(output, "output");
      // return output; // Return the generated output

    } else {
      console.log('No such document exists!');
    }
  } catch (error) {
    console.error('Error getting document:', error.message);
  }
};





export default TaskAssignByProgram
