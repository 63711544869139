import { createUserWithEmailAndPassword } from "firebase/auth";
import { workerAuth } from "../../Configuration/initialize-firebase";
export const createUser = async (email, password) => {
  if (!email || !password) {
    throw Error("Email or password is undefined or null");
  }
  const userCredentials = await createUserWithEmailAndPassword(
    workerAuth,
    email,
    password
  );
  return userCredentials.user.uid;
};
