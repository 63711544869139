import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "Auth",
  initialState: {
    userInfo: undefined,
    error: undefined,
    isLoading: false,
    isCheckingAuthStatus: false,
  },
  reducers: {
    login(state, action) {
      state.userInfo = action.payload;
      state.error = undefined;
    },
    error(state, action) {
      state.error = action.payload;
    },
    logout(state, _) {
      state.userInfo = undefined;
    },
    isLoading(state, action) {
      state.isLoading = action.payload;
    },
    user(state, action) {
      state.userInfo = action.payload;
    },
    checkingAuthStatus(state, action) {
      state.isCheckingAuthStatus = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
