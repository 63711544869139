import moment from "moment";
import { collection, addDoc, getDoc, setDoc,updateDoc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

export const addMultipleClientService = async (topicInfos) => {
  console.log(topicInfos, "topicInfostopicInfos");
  if (!Array.isArray(topicInfos)) {
    throw Error("Invalid input. Expected an array of topic information.");
  }

  return await addTopicToCloud(topicInfos);
};

const addTopicToCloud = async (topicData) => {
  try {
    console.log(topicData, "topicData");
    for (const element of topicData) {
      const {
        Pdf,
        video,
        name,
        todolist,
        voicerecoding,
        createDate,
        client,
        coachId,
      } = element;
      if (!Pdf && !video && !name && !todolist && !voicerecoding) {
        console.log("Skipping entry with all empty properties");
        continue;
      }

      const topicObj = {
        createdAt: new Date(element.createDate),
        coachId:element.coachId,
        clientId:element.client,
        topicName: element.name,
        comment: [],
        topicOrder:1
      };

      console.log(topicObj, "topicObj");
      const docRef = await addDoc(collection(db, "topics"), topicObj);
      const addedTopicId = docRef.id;
      await setDoc(docRef, { ...topicObj, id: addedTopicId }, { merge: true });



      const taskObj = {
        createdAt: new Date(element.createDate),
        Pdf: element.Pdf || {},
        video: element.video || {},
        description: element.todolist,
        recordingfile: element.voicerecording || {},
        status: "inProgress",
        comment: [],
        taskOrder:1
      };

      // debugger

      const topicRef = collection(db, "topics");
      const taskRef = collection(topicRef, addedTopicId, "tasks");

      const taskDocRef = await addDoc(taskRef, taskObj);
      console.log(taskDocRef,"taskDocReftaskDocRef")
      const addedTaskId = taskDocRef.id;
      console.log(addedTaskId, "addedTaskIdaddedTaskIdaddedTaskId");
      await updateDoc(taskDocRef, { id: addedTaskId });
      // debugger
    }
  } catch (error) {
    console.error("Error adding topics:", error);
  }
};

// const addedTopicId = docRef.id;

// console.log(addedTopicId, "addedTopicId");
