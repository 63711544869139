import addGroupService from "../../../services/groups/add-groups";
import editGroupService from "../../../services/groups/edit-group";
import getGroupsService from "../../../services/groups/get-groups";
import { groupActions } from "./group-slice";
export const addGroup = ({ name, clients,userId }) => async (dispatch) => {
    try {
        const payload = await addGroupService({ name, clients,userId });
        dispatch(groupActions.addGroup(payload));
    } catch (e) {
        throw e;
    }
};

export const editGroup = ({ name, clients, id, clientsIdToRemoveGroup,userId }) => async (dispatch) => {
    try {
        const payload = await editGroupService({ name, clients, clientsIdToRemoveGroup, groupId: id ,userId});
        dispatch(groupActions.addGroup(payload));
    } catch (e) {
        throw e;
    }
};

export const getGroups = (coachId) => async (dispatch) => {
     try {
        const payload = await getGroupsService(coachId);
        console.log(payload,"groupsssss");
        dispatch(groupActions.setGroups(payload));
    } catch (e) {
        throw e;
    }
};
