// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.program_main__UC4bB{
  margin: 100px 5rem 0px;

}
.program_customtable__AzVL3{
  background: #253341;
  margin-top: 5rem;
}

.program_button_div_content__m-ejY button{
    background: #293C89;
    border: none;
    padding: 10px 30px;
    font-weight: 500;
}
/* #303E4D */

.program_customtable__AzVL3 thead{
    background: #303E4D;
    color:#FFFFFF;
}

.program_customtable__AzVL3 thead tr th{
     border: none;
     padding: 20px 22px !important;
}

.program_customtable__AzVL3 tbody tr td{
    color: #B7B7B7;
    border-top: none;
    padding: 20px;
}

.program_text_details__hTARR{
    border-bottom:1px solid #212121;
}
.program_customtable__AzVL3 tbody tr td button{
    color: white;
    border: none;
    background: #2F731E;
    text-align: center;
    padding: 8px 28px;
    border-radius: 8px;
}


.program_button_div_content__m-ejY{
    text-align: right;
}
thead th {
    padding: 10px;
  }
/* 
.tablelist{
    border: 2px solid black;A
} */




.program_todoupload_btn_wrapper__1Uuz\\+{
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    /* background: black; */
    /* opacity: 0.3; */
    -webkit-backdrop-filter: blur(3px) saturate(70%);
            backdrop-filter: blur(3px) saturate(70%);
    height: 100%;
    top: 0;
    z-index: 86;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Program/program/program.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;;AAExB;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;AACA,YAAY;;AAEZ;IACI,mBAAmB;IACnB,aAAa;AACjB;;AAEA;KACK,YAAY;KACZ,6BAA6B;AAClC;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,+BAA+B;AACnC;AACA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;;AAGA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;EACf;AACF;;;GAGG;;;;;AAKH;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,uBAAuB;IACvB,kBAAkB;IAClB,gDAAwC;YAAxC,wCAAwC;IACxC,YAAY;IACZ,MAAM;IACN,WAAW;AACf","sourcesContent":[".main{\n  margin: 100px 5rem 0px;\n\n}\n.customtable{\n  background: #253341;\n  margin-top: 5rem;\n}\n\n.button_div_content button{\n    background: #293C89;\n    border: none;\n    padding: 10px 30px;\n    font-weight: 500;\n}\n/* #303E4D */\n\n.customtable thead{\n    background: #303E4D;\n    color:#FFFFFF;\n}\n\n.customtable thead tr th{\n     border: none;\n     padding: 20px 22px !important;\n}\n\n.customtable tbody tr td{\n    color: #B7B7B7;\n    border-top: none;\n    padding: 20px;\n}\n\n.text_details{\n    border-bottom:1px solid #212121;\n}\n.customtable tbody tr td button{\n    color: white;\n    border: none;\n    background: #2F731E;\n    text-align: center;\n    padding: 8px 28px;\n    border-radius: 8px;\n}\n\n\n.button_div_content{\n    text-align: right;\n}\nthead th {\n    padding: 10px;\n  }\n/* \n.tablelist{\n    border: 2px solid black;A\n} */\n\n\n\n\n.todoupload_btn_wrapper{\n    display: flex;\n    justify-content: center;\n    position: absolute;\n    left: 0;\n    right: 0;\n    /* background: black; */\n    /* opacity: 0.3; */\n    backdrop-filter: blur(3px) saturate(70%);\n    height: 100%;\n    top: 0;\n    z-index: 86;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `program_main__UC4bB`,
	"customtable": `program_customtable__AzVL3`,
	"button_div_content": `program_button_div_content__m-ejY`,
	"text_details": `program_text_details__hTARR`,
	"todoupload_btn_wrapper": `program_todoupload_btn_wrapper__1Uuz+`
};
export default ___CSS_LOADER_EXPORT___;
