import makeLinkClickableInText from "../../util/make-link-clickable-in-text";
import classes from "./comment.module.css";
const Comment = (props) => {
  const commentText = typeof props.comment === 'string' ? props.comment : props.comment.comment;
  const comment = makeLinkClickableInText(commentText);
  const commentComponent = typeof props.comment === 'string' ?
    <CommentClientTask comment={comment} /> :
    <CommentGroupTask comment={commentText} clientName={props.comment.clientName} />
  return commentComponent;
};


const CommentClientTask = ({ comment }) => (
  <div className={classes.commentAlign}>
    <div className={classes.commentBar}></div>
    <div>
      <p
        className={classes.comment}
        dangerouslySetInnerHTML={{ __html: comment }}
      ></p>
    </div>
  </div>
);

const CommentGroupTask = ({ comment, clientName }) => (
  <div className={classes.groupCommentDisplay}>
    <p className={classes.groupClientNameText}>{clientName}</p>
    <CommentClientTask comment={comment} />
  </div>
)

export default Comment;
