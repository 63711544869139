import AddGroup from "./add-group/add-group";
import role from "../../../util/role-enum";
import { useSelector } from "react-redux";

const AddGroupWrap = () => {
  const groupToEdit = useSelector((state) => state.groups.groupToEdit);
  return (
    <AddGroup
      title={groupToEdit ? "Update a Group" : "Add a Group"}
      buttonText="Create a client"
      role={role.client}
    />
  );
};

export default AddGroupWrap;
