import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";
import { notification_document_id } from "../../constants/constants";

export const notificationTrigger = async (title, body, deviceToken, taskId, topicId, date) => {
    try {
        if (!deviceToken || !body || !title) {
            throw new Error("NOTIFICATION: Data is incorrect");
        }
        await trigger(title, body, deviceToken, taskId, topicId, date);
    } catch (e) {
        throw e;
    }
}

const trigger = async (title, body, deviceToken, taskId, topicId, date) => {
    try {
        const notificaitonRef = collection(db, "notification");
        const notificationDoc = doc(notificaitonRef, notification_document_id);
        const randomNumber = Math.random(); // To ensure update
        const data = taskId && topicId ? {
            title,
            body,
            deviceToken,
            randomNumber,
            taskId,
            topicId
        } : date ? {
            title,
            body,
            deviceToken,
            randomNumber,
            date,
        } : {
            title,
            body,
            deviceToken,
            randomNumber,
        };
        await setDoc(notificationDoc, data);
    } catch (e) {
        throw e;
    }
}