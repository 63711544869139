import React, { useState, useEffect, useRef } from 'react';
import { AudioRecorder } from 'react-audio-voice-recorder';
import axios from 'axios';  
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classes from './Task.module.css';

const fetchFile = async (file) => {
    console.log(file,"recordingggggggggggggggggggggggggggggggggggggg");
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = (event) => {
        resolve(new Uint8Array(event.target.result));
      };
      reader.readAsArrayBuffer(file);
    });
};

const cloudName = 'db3gzsopp';
const uploadPreset = 'u2muuej0';

export default function VoicedRecoder({ setTask, setBtnDisable, task, loader, setloader, setprogressbar, recordingStarted, setRecordingStarted }) {
    const audioRecorderRef = useRef(null);

    const addAudioElement = async (blob) => {
        localStorage.removeItem('tasklogCounter');
          setBtnDisable(true);
        setloader(true);

        try {
            const formData = new FormData();
            formData.append('file', blob);
            formData.append('upload_preset', uploadPreset);

            const response = await axios.post(
                `https://api.cloudinary.com/v1_1/${cloudName}/upload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: event => {
                        setprogressbar(Math.round((100 * event.loaded) / event.total))
                    }
                }
            );
            toast.success("Upload successfully");

            setTask(prevTask => ({
                ...prevTask,
                recordingfile: response.data,
            }));
            setprogressbar(0);
            setRecordingStarted(false);
            setBtnDisable(false);
            setloader(false);
        } catch (error) {
            console.error('Error uploading file:', error.message);
            setBtnDisable(false);
            setloader(false);
        }
    };

    const handleMicrophonePermission = async () => {
        let tasklogCounter = "1"; // Retrieve counter from localStorage or initialize to 0
        localStorage.setItem('tasklogCounter', tasklogCounter); // Store the updated counter in localStorage
    };


    const deleteLogCounter = () => {
        localStorage.removeItem('tasklogCounter');
      };
        // Attach event listener to the window unload event
        window.addEventListener('unload', deleteLogCounter);


    useEffect(() => {
        const handleMicrophonePermission = async () => {
            try {
                if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                    toast.error('Microphone is not supported in this device.');
                    return;
                }

                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                if (stream) {
                    setRecordingStarted(true); // Set recording state to true when permission is granted
                    audioRecorderRef.current.startRecording();
                } else {
                    toast.error('Microphone permission denied or unavailable.');
                }
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    toast.error('Microphone permission denied. Please allow microphone access to start recording.');
                } else {
                    console.error('Error accessing microphone:', error);
                }
            }
        };

        handleMicrophonePermission();
    }, []); // Run only once when the component mounts

    const closeHandler = () => {
        setRecordingStarted(false);
        setTask(prevTask => ({
            ...prevTask,
            recordingfile: {},
        }));
    };

    return (
        <div className={classes.voice_recorder_wrapper}>
            {task && task.recordingfile && Object.keys(task.recordingfile).length > 0 ? (
                <div>
                    <p>Recorded Audio Preview:</p>
                    <div className='audio_recorder-wrap'>
                        <audio controls>
                            <source src={task.recordingfile.url} style={{ color: 'white' }} type="audio/webm" />
                            Your browser does not support the audio element.
                        </audio>
                        <i className="fa-solid fa-xmark" style={{ color: 'white', marginLeft: '10px' }} onClick={closeHandler}></i>
                    </div>
                </div>
            ) : (

                <div onClick={handleMicrophonePermission}
                 >
                     <AudioRecorder
                                ref={audioRecorderRef}
                                onRecordingComplete={addAudioElement}
                               audioTrackConstraints={{
                                noiseSuppression: true,
                                echoCancellation: true,
                                }}
                                downloadOnSavePress={false}
                                downloadFileExtension="webm"
                                disabled={recordingStarted}
                                />
                    
                  </div>

               
            )}
            <ToastContainer />
        </div>
    );
}



/**
 * 
 * import React, { useState, useEffect,useRef } from 'react';
import { AudioRecorder } from 'react-audio-voice-recorder';
import axios from 'axios';  
import { Rings } from 'react-loader-spinner';
import classes from './Task.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {FFmpeg} from '@ffmpeg/ffmpeg';
// import audioConverter from 'audio-converter'



const fetchFile = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = (event) => {
        resolve(new Uint8Array(event.target.result));
      };
      reader.readAsArrayBuffer(file);
    });
  };



const cloudName = 'db3gzsopp';
const uploadPreset = 'u2muuej0';

export default function VoicedRecoder({ setTask, setBtnDisable,task,loader,setloader,progressBar,setprogressbar ,recordingStarted, setRecordingStarted  }) {
    const audioRecorderRef =useRef(null)
    const [recordedBlob, setRecordedBlob] = useState(null);
    // const ffmpeg = new FFmpeg();

    // const ffmpeg = new FFmpeg({ log: true });
      
    
   

    const addAudioElement = async(blob) => {
        setBtnDisable(true);
        setloader(true);
        // await ffmpeg.load();

        try {
            const formData = new FormData();
            formData.append('file', blob);
            formData.append('upload_preset', uploadPreset);

            let resourceType = "audio/webm;codecs=opus";
            const response = await axios.post(
                `https://api.cloudinary.com/v1_1/${cloudName}/upload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress:event=>{
                        setprogressbar(Math.round((100*event.loaded)/event.total))
                      }
                }
            );
            toast.success("Upload sucessfully")
          
            setTask((prevTask) => ({
                ...prevTask,
                recordingfile: response.data,
            }));
            setprogressbar(0)

            setRecordingStarted(false)
           

            setBtnDisable(false);
            setloader(false);
        } catch (error) {
            console.error('Error uploading file:', error.message);
            // toast.error("Error uploading file:")
            setBtnDisable(false);
            setloader(false);
        }
    };



    const handleMicrophonePermission = async () => {
        try {
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                toast.error('Microphone  is not supported in this Device.');
                return;
            }
    
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            if (stream) {
                setRecordingStarted(true);
                audioRecorderRef.current.startRecording();
            } else {
                toast.error('Microphone permission denied or unavailable.');
                return;
            }

        } catch (error) {
            if (error.name === 'NotAllowedError') {
                toast.error('Microphone permission denied. Please allow microphone access to start recording.');
                return;
            } else {
                console.error('Error accessing microphone:', error);
            }
        }
    };

 console.log(task,"hello react js") 


 const closeHandler =()=>{
     setRecordingStarted(false)

    setTask((prevTask) => ({
        ...prevTask,
        recordingfile: {},
    }));
 }
  

    return (
        <div className={classes.voice_recorder_wrapper} onClick={handleMicrophonePermission}>
        {task && task.recordingfile && Object.keys(task.recordingfile).length > 0 ? (
            <div >
                {console.log(task.recordingfile,"hello")}
                {console.log(task.recordingfile.url,"hello2")}
                <p>Recorded Audio Preview:</p>
                <div className='audio_recorder-wrap'>
                <audio controls>
                    <source src={task.recordingfile.url} style={{color:'white'}} type="audio/webm" />
                    Your browser does not support the audio element.
                </audio>
                <i class="fa-solid fa-xmark"  style={{color:"white", marginLeft:"10px"}}   onClick={closeHandler}></i>
                </div>
                
            </div>
        ) : (
            <AudioRecorder
                ref={audioRecorderRef}
                onRecordingComplete={addAudioElement}
                audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                }}
                downloadFileExtension="webm"
            />
        )}
        <ToastContainer />
    </div>
    );
}

 * 
 * 
 */