import { useSelector } from "react-redux";
import classes from "./date-frame.module.css";
import HomeTopics from "./home-topics";
import React, { useMemo } from "react";

// const DateFrame = (props) => {
//   const clients = useSelector((state) => state.clients.clients);
//   const clientInfo = (clients ?? []).find((c) => c.id === props.clientId);
//   const topics = useMemo(() => props.topics, [props.topics]);
//   return (
//     <div className={classes.background} id={props.scrollId}>
//       <p className={classes.white}>
//         {clientInfo
//           ? `${clientInfo.firstName} ${clientInfo.lastName}`
//           : "No Name found"}
//       </p>
//       <HomeTopics topics={topics} clientId={props.clientId} />
//     </div>
//   );
// };


const DateFrame = ({ topics, clientId, groupId, scrollId }) => {
  const clients = useSelector((state) => state.clients.clients);
  console.log(clients,"clientsclients")
  const groups = useSelector((state) => state.groups.groups);
  console.log(groups,"groups")
  const clientInfo = (clients ?? []).find((c) => c.id === clientId);
  console.log(clientInfo,"clientInfo")
  const groupInfo = (groups ?? []).find((g) => g.id === groupId);
  console.log(groupInfo,"groupInfogroupInfo")
  const topicsMemoized = useMemo(() => topics, [topics]);
  const info = clientInfo
    ? `${clientInfo.firstName} ${clientInfo.lastName}`
    : groupInfo ? groupInfo.name : "No info found";


    console.log(info,"infoinfo")
  return (
    <div className={classes.background} id={scrollId}>
      <p className={classes.white}>
        {info}
      </p>
      <HomeTopics topics={topicsMemoized}  clientId={clientId} groupId={groupId} />
    </div>
  );
};



export default React.memo(DateFrame);
