import { coachesActions } from "./coaches-slice";
import { createUser } from "../../../services/users/create-user";
import { addCoachToDB } from "../../../services/coach/add-coach";
import getCoachesFromCloud from "../../../services/coach/get-coaches";
import updateCoachProfile from "../../../services/coach/update-coach";
import getUserProfileFromCloud from "../../../services/coach/getUserProfileFromCloud";

export const createCoach = (userInfo) => async (dispatch) => {
  try {
    dispatch(coachesActions.error(undefined));
    dispatch(coachesActions.isLoading(true));
    const id = await createUser(userInfo.email, userInfo.password);
    await addCoachToDB(id, userInfo);
    dispatch(coachesActions.isLoading(false));
    dispatch(coachesActions.isCoachCreationSuccessfully(true));
    setTimeout(function () {
      dispatch(coachesActions.isCoachCreationSuccessfully(false));
    }, 3000);
  } catch (e) {
    dispatch(coachesActions.error(e.message));
    dispatch(coachesActions.isLoading(false));
    throw e;
  }
};
export const getCoaches = () => async (dispatch) => {
  try {
    const coaches = await getCoachesFromCloud();
    dispatch(coachesActions.coaches(coaches));
  } catch (e) {
    throw e;
  }
};



export const updateProfileAction =(coachId)=>async(dispatch)=>{
  try{
    const data =await updateCoachProfile(coachId.id,coachId)
    dispatch(coachesActions.updateProfile(data)); 
  }catch(e){
    throw e;
  }   
}



export const ProfileAction =(coachId)=>async(dispatch)=>{
  try{
    const data =await getUserProfileFromCloud(coachId)
     return data
  }catch(e){
    throw e;
  }   
}

