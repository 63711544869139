import classes from "./login.module.css";

import { Wrap, Card, Logo } from "../../Components/index";

// export default function SignUp() {
//   return (
//     <div>SignUp</div>
//   )
// }


const SignUp = () => {
    return(
      <Wrap>
        <Card className={classes.center}>
          {/* Card content */}
        </Card>
      </Wrap>
     )
  }
  
  export default SignUp;
