import DateAndTime from "./date-time";
import NewClients from "./newClint";
import Clients from "../../clients/clients";
import classes from "./clients-section.module.css";

const OrderedListOfClients = ({clients}) => {
  return (
    <div className={classes.sizing}>
      <DateAndTime />
      <NewClients clients={clients}/>
    </div>
  );
};

export default OrderedListOfClients;
