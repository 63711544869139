import { createSlice } from "@reduxjs/toolkit";

const coachesSlice = createSlice({
  name: "Coaches",
  initialState: {
    isLoading: false,
    error: undefined,
    isCoachCreationSuccessfully: false,
    coaches: [],
  },
  reducers: {
    isLoading(state, action) {
      state.isLoading = action.payload;
    },
    error(state, action) {
      state.error = action.payload;
    },
    isCoachCreationSuccessfully(state, action) {
      state.isCoachCreationSuccessfully = action.payload;
    },
    coaches(state, action) {
      state.coaches = action.payload;
    },
    updateProfile(stae,action){
      // state.isCoachUpdated = action.payload;
    }
  },
});

export default coachesSlice;
export const coachesActions = coachesSlice.actions;
