import React, { useEffect, useState } from "react";
import { Player } from 'video-react';

export default function VedioPlayer({ url, fileExtension ,key}) {
  console.log(url,"121211")
  const [videoUrl, setVideoUrl] = useState(url);

  useEffect(() => {
    console.log('url changed:', url);
    setVideoUrl(url);
  }, [url]);
  

  return (
    <video key={key} width="100%" height="200px" controls>
      <source src={videoUrl} />
    </video>
  );
}
