import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

const getGroupsService = async (coachId) => {
    if (!coachId) {
        return await getGroups();
    }
    return await getGroups(coachId);
};

const getGroups = async (coachId) => {
    const groupRef = collection(db, "groups");
    let response;
    if (!coachId) {
        response = await getDocs(groupRef);
    } else {
        const q = query(groupRef, where("userId", "==", coachId));
        response = await getDocs(q);
    }

    const data = response.docs.map((e) => {
        return {
            id: e.id,
            ...e.data(),
        };
    });
    return data;
};

export default getGroupsService;
