import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tasksActions } from "../../../../Store/Coach/tasks/tasks-slice";
import classes from "./group-info.module.css";
const GroupInfo = ({ group }) => {
  const dispatch = useDispatch();
  const start = useSelector((state) => state.topics.startDate);
  const startDate = moment(start, "YYYY-MM-DD");
  const end = useSelector((state) => state.topics.endDate);
  const endDate = moment(end, "YYYY-MM-DD");
  const onStartDateChange = (event) => {
    dispatch(tasksActions.setStartDate(event.target.value));
  };
  const onEndDateChange = (event) => {
    dispatch(tasksActions.setEndDate(event.target.value));
  };
  return (
    <div className={classes.display}>
      <p>Tasks of {`${group.name}`}</p>
      <div className={classes.align}>
        <input
          type="date"
          className={classes.date}
          defaultValue={moment(startDate).format("YYYY-MM-DD")}
          onChange={onStartDateChange}
        />
        <input
          type="date"
          className={classes.date}
          defaultValue={moment(endDate).format("YYYY-MM-DD")}
          onChange={onEndDateChange}
        />
      </div>
    </div>
  );
};

export default GroupInfo;
