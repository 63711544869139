// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topics_margin__a7zwJ {
  margin: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/group-task/components/Topic/topics.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kCAAkC;EAClC,QAAQ;AACV","sourcesContent":[".margin {\n  margin: 1rem;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"margin": `topics_margin__a7zwJ`
};
export default ___CSS_LOADER_EXPORT___;
