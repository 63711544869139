import { authActions } from "./auth-slice";
import logOut from "../../services/Authentication/sign-out";
import login from "../../services/Authentication/sign-in-with-email-password";
import { auth } from "../../Configuration/initialize-firebase"
import getUserFromCloud from "../../services/users/get-user-from-cloud";
import { onAuthStateChanged } from "firebase/auth";

export const loginWithEmailAndPassword =
  (email, password) => async (dispatch) => {
    try {
      dispatch(authActions.isLoading(true));
      const user = await login(email, password);
      const id = user.uid;
      const userInfo = await getUserFromCloud(id);
      const userData = {
        email: user.email,
        role: userInfo.role,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        id: userInfo.id,
      };
      dispatch(authActions.login(userData));
      dispatch(authActions.isLoading(false));
      return userInfo.role;
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      dispatch(authActions.error(`${errorMessage}:${errorCode}`));
      dispatch(authActions.isLoading(false));
      throw error;
    }
  };

export const logout = () => async (dispatch) => {
  try {
    await logOut(auth);
    dispatch(authActions.logout());
  } catch (e) {
    dispatch(authActions.logout());
  }
};

export const setupListener = () => async (dispatch) => {
  dispatch(authActions.checkingAuthStatus(true));
  onAuthStateChanged(auth, async (currentUser) => {
    try {
      if (currentUser) {
        const userInfo = await getUserFromCloud(currentUser.uid);
        if (userInfo) {
          const userData = {
            email: userInfo.email,
            role: userInfo.role,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            id: userInfo.id,
          };
          dispatch(authActions.login(userData));
          dispatch(authActions.checkingAuthStatus(false));
          return;
        }
      }
      dispatch(authActions.user(undefined));
      dispatch(authActions.checkingAuthStatus(false));
    } catch (e) {
      dispatch(authActions.user(undefined));
      dispatch(authActions.checkingAuthStatus(false));
    }
  });
};
