import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classes from './SucessMoadl.module.css'
import { useNavigate } from 'react-router-dom';

function SucessModal({setsuccessModal}) {
  const navigate =useNavigate()
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setsuccessModal(false)
    navigate('/coach-dashboard/clients')
  }
 


  useEffect(() => {
   
    setShow(true)
  }, [])


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="page-body">
            <div className={classes.head}>
              <img src="/Check.png" alt="" />
              <h2>sucess</h2>
              <p>
                Program has been send sucessfully to  your client
              </p>
            </div>

          </div>
        </Modal.Body>

      </Modal>
    </>
  );
}

export default SucessModal;