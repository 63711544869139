import moment from "moment";
import classes from "./topics.module.css";
import FrameWithDate from "./tasks/frame-with-date";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopicsOfClient } from "../../../../../Store/Coach/tasks/tasks-actions";
const Topics = (props) => {
  const client = useSelector((state) => state.clients.selectedClient);
  const start = useSelector((state) => state.topics.startDate);
  const startDate = moment(start, "YYYY-MM-DD");
  const end = useSelector((state) => state.topics.endDate);
  const endDate = moment(end, "YYYY-MM-DD");
  const nextThirtyDays = [
    ...new Array(endDate.diff(startDate, "days") + 1),
  ].map((i, idx) => {
    const date = moment(startDate);
    return date.add(idx, "days");
  });


  console.log("nextThirtyDays","nextThirtyDays",nextThirtyDays)
  const dispatch = useDispatch();
  const getTopics = useCallback(
    async (clientId) => {
      await getTopicsOfClient(clientId)(dispatch);
    },
    [dispatch]
  );
  useEffect(() => {
    getTopics(client.id);
  }, [getTopics, client.id]);

  console.log(props.clientId,"code home")
  return (
    <div className={classes.margin}>
      {nextThirtyDays.map((e) => (
        <FrameWithDate date={e} key={e.toString()} clientId={props.clientId} />
      ))}
    </div>
  );
};

export default Topics;
