// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_error__eEs\\+G {
  color: rgb(255, 0, 0, 0.8);
  font-size: 12px;
  text-align: left;
  font-weight: thin;
  padding-left: 20px;
}

.form_formAlignment__aeaN8 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form_row__p6xAv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.form_radioButtonAlign__EJA5o {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 0.5rem;
}

.form_suggestionsBox__-fGsa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: whitesmoke;
  padding: 10px;
  margin: 10px 10px;
  border-radius: 10px;
  text-align: center;
}

.form_suggestion__ElIbS {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 5px 0px;
  justify-content: space-around;
  background-color: rgb(255, 255, 255, 0.5);
  border-radius: 5px;
}

.form_suggestion__ElIbS:hover {
  background-color: white;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/groups/add-group/form.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,4BAA4B;EAC5B,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,6BAA6B;EAC7B,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":[".error {\n  color: rgb(255, 0, 0, 0.8);\n  font-size: 12px;\n  text-align: left;\n  font-weight: thin;\n  padding-left: 20px;\n}\n\n.formAlignment {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n}\n\n.radioButtonAlign {\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n  margin: 0.5rem;\n}\n\n.suggestionsBox {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  background-color: whitesmoke;\n  padding: 10px;\n  margin: 10px 10px;\n  border-radius: 10px;\n  text-align: center;\n}\n\n.suggestion {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  margin: 5px 0px;\n  justify-content: space-around;\n  background-color: rgb(255, 255, 255, 0.5);\n  border-radius: 5px;\n}\n\n.suggestion:hover {\n  background-color: white;\n  border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `form_error__eEs+G`,
	"formAlignment": `form_formAlignment__aeaN8`,
	"row": `form_row__p6xAv`,
	"radioButtonAlign": `form_radioButtonAlign__EJA5o`,
	"suggestionsBox": `form_suggestionsBox__-fGsa`,
	"suggestion": `form_suggestion__ElIbS`
};
export default ___CSS_LOADER_EXPORT___;
