// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_background__B\\+fw- {
  background: #161f28;
  width: 100%;
  padding-top: 0rem;
  overflow-y: auto;

}

.main_error__R1FSO {
  width: 100%;
  text-align: center;
  color: darkred;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/tasks/main.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,iBAAiB;EACjB,gBAAgB;;AAElB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB","sourcesContent":[".background {\n  background: #161f28;\n  width: 100%;\n  padding-top: 0rem;\n  overflow-y: auto;\n\n}\n\n.error {\n  width: 100%;\n  text-align: center;\n  color: darkred;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `main_background__B+fw-`,
	"error": `main_error__R1FSO`
};
export default ___CSS_LOADER_EXPORT___;
