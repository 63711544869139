import { useDispatch, useSelector } from "react-redux";
import AddTextField from "../../../../../Components/UI/add-text-field";
import IconButton from "../../../../../Components/UI/icon-button";
import {
  addTopic,
  paste,
  sendNotification,
} from "../../../../../Store/Coach/tasks/tasks-actions";
import iconType from "../../../../../util/icon-type";
import classes from "./add-topic.module.css";

const AddTopic = (props) => {
  const dispatch = useDispatch();
  const selectedClient = useSelector((state) => state.clients.selectedClient);
  const coach = useSelector((state) => state.auth.userInfo);
  const topic = useSelector((state) => state.topics.copiedTopic);
  const tasks = useSelector((state) => state.topics.copiedTasks);
  const onPasteHandler = async () => {
    await paste(
      topic,
      tasks,
      selectedClient.id,
      props.date,
      props.topicOrder
    )(dispatch);
    sendNotification(topic.topicName, tasks, selectedClient.deviceToken, undefined, undefined, undefined)(dispatch);
  };
  const onSubmitHandler = (event) => {
    event.preventDefault();
    const topic = event.target[0].value;
    addTopic(
      topic,
      selectedClient.id,
      props.date,
      coach.id,
      props.topicOrder
    )(dispatch);
    if (selectedClient?.deviceToken) {
      sendNotification(topic, undefined, selectedClient.deviceToken, undefined, undefined, undefined)(dispatch);
    }
    event.target.reset();
  };
  return (
    <div className={classes.align}>
      <AddTextField
        placeholder="Add a topic here..."
        onSubmit={onSubmitHandler}
      >
        {topic && <IconButton icon={iconType.paste} onClick={onPasteHandler} />}
      </AddTextField>
    </div>
  );
};
export default AddTopic;
