import { auth } from "../../Configuration/initialize-firebase";
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";

const updatePasswordInFirebase = async (userEmail, currentPassword, newPassword) => {
  try {
    const user = auth.currentUser;
 
    if (!user) {
      throw new Error('User not signed in');
    }

    const credential = EmailAuthProvider.credential(userEmail, currentPassword||110694449);
    const data = await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
    console.log("Password updated successfully");
  } catch (error) {
    throw error;
  }
};

export default updatePasswordInFirebase;
