import { createSlice } from "@reduxjs/toolkit";
const clientSlice = createSlice({
  name: "Client",
  initialState: {
    isLoading: false,
    error: undefined,
    isClientCreationSuccessfully: false,
    clients: [],
    selectedClient: undefined,
    homeClients: [],
  },
  reducers: {
    isLoading(state, action) {
      state.isLoading = action.payload;
    },
    error(state, action) {
      state.error = action.payload;
    },
    isClientCreationSuccessfully(state, action) {
      state.isClientCreationSuccessfully = action.payload;
    },
    clients(state, action) {
      state.clients = action.payload;
    },
    setSelectedClient(state, actions) {
      const clientId = actions.payload;
      const client = state.clients.find((e) => e.id === clientId);
      if (!client) {
        throw Error("Client not found");
      }
      state.selectedClient = client;
    },
    setHomeClients(state, actions) {
      const clientIds = actions.payload;
      // Finding and sorting homeClients
      state.homeClients = state.clients
        ?.map((client) => {
          const foundId = clientIds.find((e) => e.clientId === client.id);
          if (foundId && foundId?.order >= 0) {
            return {
              order: foundId.order,
              ...client,
            };
          }
          return undefined;
        })
        ?.filter((n) => n)
        ?.sort((a, b) => a?.order - b?.order);
    },
  },
});

export default clientSlice;
export const clientActions = clientSlice.actions;
