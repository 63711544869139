// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client_textSizing__yy2gG {
  flex: 2 1;
}

.client_title__AG\\+a9 {
  font-size: 18px;
  color: whitesmoke;
}

.client_subtitle__x8L\\+O {
  font-size: 12px;
  color: lightgray;
}

.client_image__E8kc3 {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
}

.client_align__02PW3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  margin: 0rem 1rem;
  padding: 10px 5px;
}
.client_align__02PW3:hover {
  border: 0.5px solid whitesmoke;
  border-radius: 0.5rem;
  cursor: pointer;
}
.client_background__-1ixw {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: #3f556d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.client_center__ID4GX {
  font-size: 18px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/home/clients-in-tasks/client.module.css"],"names":[],"mappings":"AAAA;EACE,SAAO;AACT;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,8BAA8B;EAC9B,qBAAqB;EACrB,eAAe;AACjB;AACA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".textSizing {\n  flex: 2;\n}\n\n.title {\n  font-size: 18px;\n  color: whitesmoke;\n}\n\n.subtitle {\n  font-size: 12px;\n  color: lightgray;\n}\n\n.image {\n  border-radius: 50%;\n  height: 2rem;\n  width: 2rem;\n}\n\n.align {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  gap: 1rem;\n  margin: 0rem 1rem;\n  padding: 10px 5px;\n}\n.align:hover {\n  border: 0.5px solid whitesmoke;\n  border-radius: 0.5rem;\n  cursor: pointer;\n}\n.background {\n  height: 3rem;\n  width: 3rem;\n  border-radius: 50%;\n  background-color: #3f556d;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.center {\n  font-size: 18px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textSizing": `client_textSizing__yy2gG`,
	"title": `client_title__AG+a9`,
	"subtitle": `client_subtitle__x8L+O`,
	"image": `client_image__E8kc3`,
	"align": `client_align__02PW3`,
	"background": `client_background__-1ixw`,
	"center": `client_center__ID4GX`
};
export default ___CSS_LOADER_EXPORT___;
