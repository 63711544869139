import { db } from "../../Configuration/initialize-firebase";
import { query, collection, where, getDocs } from "firebase/firestore";
const getCoachesFromCloud = async () => {
  const coachesRef = collection(db, "users");
  const q = query(coachesRef, where("role", "==", "coach"));
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((d) => d.data());
  return data;
};

export default getCoachesFromCloud;
