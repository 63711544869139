import classes from "./text-form-field.module.css";
const TextFormField = (props) => {
  let classNames = props.isFormFieldExtra
    ? `${classes.textfield} ${classes.textfieldExtra}`
    : `${classes.textfield}`;
  return (
    <input
      className={classNames}
      type={props.type}
      placeholder={props.placeholder}
      onChange={props.onChange}
      onBlur={props.onBlur}
      value={props.value}
    />
  );
};

export default TextFormField;
