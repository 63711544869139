import { createSlice } from "@reduxjs/toolkit";

const programSlice = createSlice({
  name: "program",
  initialState: {
    programs: [],
    loading: false,
    error: null,
    getProgram: {}, // Fix the typo here
  },
  reducers: {
    setPrograms(state, action) {
      state.programs = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    GetEditProgram(state, action) {
      // debugger
      state.getProgram = action.payload.programData
      state.loading = false;
      state.error = null;
    },
    deleteProgramdata(state, action) {
      const programId = action.payload.programId;
      // debugger
      state.programs = state.programs.filter((t) => t.id !== programId);
    },
  },
});

export const { setPrograms, setLoading, setError, GetEditProgram, deleteProgramdata } = programSlice.actions;
export default programSlice;
