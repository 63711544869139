import classes from "./greetings.module.css";
import { useSelector } from "react-redux";

const Greetings = () => {
  const clients = useSelector((state) => state.auth.userInfo);
  return (
    <h3 className={classes.colorWhite}>
      Welcome, {`${clients.firstName} ${clients.lastName}`}
    </h3>
  );
};
export default Greetings;
