import { useDispatch } from "react-redux";
import { addTaskToTopic } from "../../../../../../Store/Coach/tasks/tasks-actions";
import classes from "./add-task.module.css";
import AddFormattingTextField from "../../../../../../Components/UI/add-formatting-text-field";
const AddTask = ({ topicId, topicName, date, taskOrder }) => {
  const dispatch = useDispatch();
  // const client = useSelector((state) => state.clients.selectedClient);
  const onSubmit = async (task, description) => {
    addTaskToTopic(task, topicId, taskOrder)(dispatch);
    // .then((taskId) => {
    //   // if (client.deviceToken) {
    //   //   sendNotification(topicName, description, client.deviceToken, taskId, topicId, undefined)(dispatch);
    //   // }
    // });
  };
  return (
    <div className={classes.align}>
      <AddFormattingTextField
        placeholder="Add a task here.."
        onSubmit={onSubmit}
      />
      {/* <TextEditor placeholder="Add a task here.." onSubmit={onSubmit} /> */}
    </div>
  );
};

export default AddTask;
