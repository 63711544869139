// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-topic_background__X5JqF {
  background-color: #3f566d;
  margin: 0.3rem;
  padding: 0.3rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.home-topic_white__EPEl9 {
  color: lightgray;
  padding: 0.3rem;
  font-size: 14px;
  text-align: left;
  margin-left: 1rem;
}

.home-topic_tasks__2Z2C4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-topic_topic__at\\+iU {
  width: 100%;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/home/orderedTasks/components/topics/home-topic.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".background {\n  background-color: #3f566d;\n  margin: 0.3rem;\n  padding: 0.3rem;\n  border-radius: 0.5rem;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n\n.white {\n  color: lightgray;\n  padding: 0.3rem;\n  font-size: 14px;\n  text-align: left;\n  margin-left: 1rem;\n}\n\n.tasks {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.topic {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `home-topic_background__X5JqF`,
	"white": `home-topic_white__EPEl9`,
	"tasks": `home-topic_tasks__2Z2C4`,
	"topic": `home-topic_topic__at+iU`
};
export default ___CSS_LOADER_EXPORT___;
