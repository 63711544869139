export const routes = {
  index: "/",
  signup:'/signup',
  adminDashboard: {
    index: "admin-dashboard",
    addCoach: "add-coaches",
  },
  coachDashboard: {
    index: "coach-dashboard",
    clients: {
      index: "clients",
      addClients: "add-client",
      tasks: "tasks/:id",
    },
    pdf:'pdf',
    groups: {
      index: "groups",
      addGroups: "add-groups",
      tasks: "tasks/:id",
    },
    profile:{
      index: "myprofile",
      changepassword:"change-password"
    },
    program:{
      index: "program",
      create:"create",
      edit:"edit/:id"
    },  
  },
  any: "*",
};
