import React, { useEffect, useState } from 'react';
import classes from './SucessMoadl.module.css';

function UpdateSuccessModal({ setshow, fetchData }) {
    console.log(setshow)
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('modal-open'); // Add this class to prevent background scrolling
        } else {
            document.body.classList.remove('modal-open'); // Remove the class to enable scrolling
        }
    }, [isModalOpen]);

    const closeModal = () => {
        setIsModalOpen(false); // Close the modal by updating state
        setshow(false);
        fetchData();
    };

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                onClick={() => setIsModalOpen(true)}
                style={{ display: 'none' }}
            >
                Launch demo modal
            </button>
            {isModalOpen && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className={`modal-content ${classes.customModalContent}`}>
                            <div className="modal-header">
                                <button type="button" className="close" onClick={closeModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="page-body">
                                    <div className={classes.head}>
                                        <img src="/Check.png" alt="" />
                                        <h2>Success</h2>
                                        <p>Update Task Successfully</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default UpdateSuccessModal;
