import moment from "moment";
import classes from "./topics.module.css";
import FrameWithDate from "./tasks/frame-with-date";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopicsOfGroup } from "../../../../../Store/Coach/tasks/tasks-actions";
const Topics = ({ groupId }) => {
  const start = useSelector((state) => state.topics.startDate);
  const startDate = moment(start, "YYYY-MM-DD");
  const end = useSelector((state) => state.topics.endDate);
  const endDate = moment(end, "YYYY-MM-DD");
  const nextThirtyDays = [
    ...new Array(endDate.diff(startDate, "days") + 1),
  ].map((i, idx) => {
    const date = moment(startDate);
    return date.add(idx, "days");
  });
  const dispatch = useDispatch();
  const getTopics = useCallback(
    async (groupId) => {
      await getTopicsOfGroup(groupId)(dispatch);
    },
    [dispatch]
  );
  useEffect(() => {
    getTopics(groupId);
  }, [getTopics, groupId]);
  return (
    <div className={classes.margin}>
      {nextThirtyDays.map((e) => (
        <FrameWithDate date={e} key={e.toString()} groupId={groupId} />
      ))}
    </div>
  );
};

export default Topics;
