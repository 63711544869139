import { addUserToCloudFirestore } from "../users/add-user-to-cloud";



export const addMultipleClientsToDB = async (ids, userInfos) => {
    if (!ids || !userInfos || ids.length !== userInfos.length) {
      throw Error("IDs or user information is missing or mismatched");
    }
  
    const clientsToAdd = [];
  
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const userInfo = userInfos[i];
  
      if (
        !userInfo.firstName ||
        !userInfo.lastName ||
        !userInfo.role ||
        !userInfo.coachId
      ) {
        throw Error(`Please check the first name and last name for user ${i + 1}`);
      }
  
      const data = {
        id: ids[i], 
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        role: userInfo.role,
        coachId: userInfo.coachId,
        email: userInfo.email, 
        notes: userInfo.notes || '', // Handling if notes are not provided
      };
  
      if (userInfo.language) {
        data.language = userInfo.language;
      }
  
      clientsToAdd.push(data);
    }
    // debugger
  
    // Assuming addUserToCloudFirestore function is responsible for adding to the database
    const data =await Promise.all(clientsToAdd.map(client => addUserToCloudFirestore(client.id, client)));
    console.log(data,"1234567889")
  };
  