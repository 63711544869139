// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-button_button__b0faG {
  margin: 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  background: white;
  padding: 10px 30px;
  border: none;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/loading-button.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,qBAAqB;EACrB,mDAAmD;EACnD,eAAe;EACf,UAAU;AACZ","sourcesContent":[".button {\n  margin: 10px;\n  color: rgba(0, 0, 0, 0.5);\n  font-size: 18px;\n  background: white;\n  padding: 10px 30px;\n  border: none;\n  border-radius: 0.5rem;\n  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;\n  cursor: pointer;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `loading-button_button__b0faG`
};
export default ___CSS_LOADER_EXPORT___;
