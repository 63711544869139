const makeLinkClickableInText = (text) => {
  let urlRegex =
    // eslint-disable-next-line no-useless-escape
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  let newText = text.toString().replace(urlRegex, function (url) {
    return `</p><a href="${url}" target="#">${url}</a><p>`;
  });
  newText = `<p>${newText}</p>`;
  return newText;
};

export default makeLinkClickableInText;
