import { db } from "../../Configuration/initialize-firebase";
import { query, collection, where, getDocs } from "firebase/firestore";

const getUserProfileFromCloud = async (userId) => {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("id", "==", userId)); // Assuming "id" is the field to match
  const snapshot = await getDocs(q);

  if (snapshot.docs.length > 0) {
    const userProfile = snapshot.docs[0].data();
    return userProfile;
  } else {
    return null; // No user found with the specified condition
  }
};

export default getUserProfileFromCloud;
