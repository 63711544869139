import classes from "./coach-menu.module.css";
import { Logout } from "../../../Components/index";
import { NavLink, redirect } from "react-router-dom";
import { routes } from "../../../util/routes";
const CoachMenu = () => {
  const isActive = ({ isActive }) =>
    isActive ? classes.active : classes.inactive;


    const redirectHandler =()=>{
       window.open('https://www.luclabarile.com/guidevideos', '_blank');
    }

  return (
    <div className={classes.align}>
      <div className={classes.row}>
        <img
          className={classes.logo}
          src={require("../../../assets/logos/white-logo.png")}
          alt="logo"
        />
        <ul className={classes.ul}>
          <li>
            <NavLink
              className={isActive}
              end
              to={`/${routes.coachDashboard.index}`}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              className={isActive}
              to={routes.coachDashboard.clients.index}
            >
              Clients
            </NavLink>
          </li>
          <li>
            <NavLink
              className={isActive}
              to={routes.coachDashboard.groups.index}
            >
              Groups
            </NavLink>
          </li>

          <li>
            <NavLink
               className={isActive}
              onClick={redirectHandler}
            >
              Help
            </NavLink>
          </li>

          <li>
            <NavLink
              className={isActive}
              to={routes.coachDashboard.program.index}
            >
              Program
            </NavLink>
          </li>
        </ul>
      </div>
      <Logout />
    </div>
  );
};

export default CoachMenu;
