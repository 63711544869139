import { collection, addDoc, arrayUnion, doc, writeBatch } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

const addGroupService = async ({ name, clients,userId }) => {
    if (
        !name ||
        clients.length === 0
    ) {
        throw Error("Group Data is incorrect");
    }
    const response = await addGroupToCloud({ name, clients,userId });
    const id = response.id;
    await bulkClientUpdate(clients, id);
    return {
        id, name, clients,userId
    };
};

const bulkClientUpdate = async (clients, groupId) => {
    var batch = writeBatch(db);
    const userRef = collection(db, "users");
    for (let c of clients) {
        let docRef = doc(userRef, c.id);
        batch.update(docRef, {
            groupIds: arrayUnion(groupId)
        });
    }
    await batch.commit();
}


const addGroupToCloud = async (groupData) =>
    await addDoc(collection(db, "groups"), groupData);

export default addGroupService;
