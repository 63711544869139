import { db } from "../../Configuration/initialize-firebase";
import { collection, updateDoc, arrayUnion, doc } from "firebase/firestore";

const addCommentToTopicService = async (topicId, comment) => {
  if (!topicId || !comment) {
    throw Error("either Topic Id or comment not found");
  }
  await addCommentToTopic(topicId, comment);
};

const addCommentToTopic = async (topicId, comment) => {
  const topicRef = collection(db, "topics");
  const taskDoc = doc(topicRef, topicId);
 await updateDoc(taskDoc, {
  comments: arrayUnion(comment.comment),
 });
};

export default addCommentToTopicService;
