// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment_commentAlign__ycM3l {
  display: flex;
  flex-direction: row;
  color: lightgray;
  gap: 0.5rem;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  font-size: 12px;
}

.comment_commentBar__BRvoH {
  width: 4px;
  background-color: darkgreen;
}

.comment_comment__Yrda\\+ {
  text-align: left;
  word-break: break-all;
}

.comment_comment__Yrda\\+>a {
  color: white;
}

.comment_groupCommentDisplay__VlrN9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.comment_groupClientNameText__pxm8z {
  color: white;
  font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/comment.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".commentAlign {\n  display: flex;\n  flex-direction: row;\n  color: lightgray;\n  gap: 0.5rem;\n  margin-left: 1.5rem;\n  margin-top: 0.5rem;\n  font-size: 12px;\n}\n\n.commentBar {\n  width: 4px;\n  background-color: darkgreen;\n}\n\n.comment {\n  text-align: left;\n  word-break: break-all;\n}\n\n.comment>a {\n  color: white;\n}\n\n.groupCommentDisplay {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.groupClientNameText {\n  color: white;\n  font-size: 0.8rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentAlign": `comment_commentAlign__ycM3l`,
	"commentBar": `comment_commentBar__BRvoH`,
	"comment": `comment_comment__Yrda+`,
	"groupCommentDisplay": `comment_groupCommentDisplay__VlrN9`,
	"groupClientNameText": `comment_groupClientNameText__pxm8z`
};
export default ___CSS_LOADER_EXPORT___;
