// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame-with-date_border__ze2CY {
  border: 0.5px solid whitesmoke;
}

.frame-with-date_date__O-EVD {
  background-color: whitesmoke;
  padding: 2px;
  font-size: 20px;
  width: 1.5rem;
  height: 2rem;
  display: block;
  text-align: center;
}

.frame-with-date_alignTasks__SCMSA {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/group-task/components/Topic/tasks/frame-with-date.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;EAC5B,YAAY;EACZ,eAAe;EACf,aAAa;EACb,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".border {\n  border: 0.5px solid whitesmoke;\n}\n\n.date {\n  background-color: whitesmoke;\n  padding: 2px;\n  font-size: 20px;\n  width: 1.5rem;\n  height: 2rem;\n  display: block;\n  text-align: center;\n}\n\n.alignTasks {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border": `frame-with-date_border__ze2CY`,
	"date": `frame-with-date_date__O-EVD`,
	"alignTasks": `frame-with-date_alignTasks__SCMSA`
};
export default ___CSS_LOADER_EXPORT___;
