import moment from "moment";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTasksOfTopic } from "../../../../../../Store/Coach/tasks/tasks-actions";
import AddTask from "./add-task";
import classes from "./group-topic.module.css";
import UnitTask from "../../../../../../Components/UI/unit-task";
import UnitTopic from "../../../../../../Components/UI/unit-topic";
const GroupTopic = ({ name, groupId, propsTasks, topicId, date, }) => {
  const dispatch = useDispatch();
  const tasksOfTopic = useSelector((state) =>
    state.topics.tasks?.filter((e) => e.topicId === topicId)
  );
  const tasks = tasksOfTopic?.map((t, i) => (
    <UnitTask task={t} key={i} topicId={topicId} />
  ));
  const addTask = moment(date).add(1, "day").isAfter(moment()) && (
    <AddTask
      topicId={topicId}
      topicName={name}
      date={moment(date)}
      // To preserve the order of tasks
      taskOrder={
        tasksOfTopic && tasksOfTopic?.length > 0
          ? (tasks[tasks.length - 1].taskOrder ?? tasks.length) + 1
          : 1
      }
    />
  );
  const getTasks = useCallback(
    async (topicId) => {
      await getTasksOfTopic(topicId, date)(dispatch);
    },
    [dispatch, date]
  );
  useEffect(() => {
    getTasks(topicId);
  }, [getTasks, topicId]);

  return (
    <div className={classes.center}>
      <UnitTopic
        topicId={topicId}
        name={name}
        groupId={groupId}
        showCopy={true}
      />
      {tasks}
      {addTask}
    </div>
  );
};

export default GroupTopic;
