// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-info_display__75-A5 {
  width: 75%;
  margin:0 auto;
} 

.client-info_align__OwfcS {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.client-info_date__FkbtS {
  border-radius: 0.5rem;
  border: none;
  font-size: 16px;
  padding: 2px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  color: black;
}

.client-info_arrowchart__m42\\+L{
  display: flex;
  column-gap: 17px;
  align-items: center;
}

.client-info_arrowcharttext__BDHcZ h2{
  color: white;
}


.client-info_arrowcharticon__6stUL i{
  color: white;
}


.client-info_arrowcharticon__6stUL{
  display: flex;
  column-gap: 10px;
}

.client-info_main_div__K27lp{
     display: flex;
    justify-content: flex-end;
    padding: 20px 50px;
}

.client-info_button_box__8RpXj{
  display:  flex;
  column-gap: 14px;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 1201px) and (max-width: 1366px){
  .client-info_display__75-A5 {
    width: 72%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1367px) and (max-width: 1600px){
  .client-info_display__75-A5 {
    width: 72%;
    margin: 0 auto;
  }
}

/* .button_box button {
  background: #293C89;
  color: white;
  border: none;
  padding: 12px 40px;
  border-radius: 10px;
} */
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/tasks/components/client-info.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,mDAAmD;EACnD,YAAY;AACd;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;;AAGA;EACE,YAAY;AACd;;;AAGA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;KACK,aAAa;IACd,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE;IACE,UAAU;IACV,cAAc;EAChB;AACF;AACA;EACE;IACE,UAAU;IACV,cAAc;EAChB;AACF;;AAEA;;;;;;GAMG","sourcesContent":[".display {\n  width: 75%;\n  margin:0 auto;\n} \n\n.align {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 1rem;\n}\n\n.date {\n  border-radius: 0.5rem;\n  border: none;\n  font-size: 16px;\n  padding: 2px;\n  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;\n  color: black;\n}\n\n.arrowchart{\n  display: flex;\n  column-gap: 17px;\n  align-items: center;\n}\n\n.arrowcharttext h2{\n  color: white;\n}\n\n\n.arrowcharticon i{\n  color: white;\n}\n\n\n.arrowcharticon{\n  display: flex;\n  column-gap: 10px;\n}\n\n.main_div{\n     display: flex;\n    justify-content: flex-end;\n    padding: 20px 50px;\n}\n\n.button_box{\n  display:  flex;\n  column-gap: 14px;\n  text-align: center;\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n}\n@media only screen and (min-width: 1201px) and (max-width: 1366px){\n  .display {\n    width: 72%;\n    margin: 0 auto;\n  }\n}\n@media only screen and (min-width: 1367px) and (max-width: 1600px){\n  .display {\n    width: 72%;\n    margin: 0 auto;\n  }\n}\n\n/* .button_box button {\n  background: #293C89;\n  color: white;\n  border: none;\n  padding: 12px 40px;\n  border-radius: 10px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"display": `client-info_display__75-A5`,
	"align": `client-info_align__OwfcS`,
	"date": `client-info_date__FkbtS`,
	"arrowchart": `client-info_arrowchart__m42+L`,
	"arrowcharttext": `client-info_arrowcharttext__BDHcZ`,
	"arrowcharticon": `client-info_arrowcharticon__6stUL`,
	"main_div": `client-info_main_div__K27lp`,
	"button_box": `client-info_button_box__8RpXj`
};
export default ___CSS_LOADER_EXPORT___;
