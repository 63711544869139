import Logout from "../logout/logout";
import classes from "./add-user.module.css";
import UserForm from "./form";
import { Wrap, Card } from "../index";
import { useSelector } from "react-redux";
import role from "../../util/role-enum";
const AddUser = (props) => {
  const isSuccessful = useSelector((state) =>
    props.role === role.client
      ? state.clients.isClientCreationSuccessfully
      : state.coaches.isCoachCreationSuccessfully
  );
  const error = useSelector((state) =>
    props.role === role.client ? state.clients.error : state.coaches.error
  );
  return (
    <Wrap className={classes.marginTop}>
      {isSuccessful && (
        <div className={classes.successMessage}>
          {props.role === role.client
            ? "Client added successfully"
            : "Coach added successfully"}
        </div>
      )}
      {error && <div className={classes.failureMessage}>{error}</div>}
      <h1 style={{color:"white"}}>{props.title}</h1>
      <Card className={`${classes.center}`}>
        <UserForm buttonText={props.buttonText} role={props.role} />
      </Card>
      {/* <Logout /> */}
    </Wrap>
  );
};
export default AddUser;
