import React, { useEffect, useState } from 'react';
import classes from "./profile.module.css";
import { useDispatch, useSelector } from "react-redux";
import {updateProfileAction,ProfileAction} from '../../../Store/Admin/coaches/coaches-actions'
import SucessModal from '../../../Components/Modal/SucessModals';
// import { auth } from "../../Configuration/initialize-firebase";
// import firebase from 'firebase/app';
// import 'firebase/auth';

export default function Profile() {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const isLoading = useSelector((state) => state.auth.isCheckingAuthStatus);
  const [message,setmessage] =useState('')
  const [show,setshow]=useState(false)
  const [isModalOpen,setisModalOpen]=useState(false)
  

  const user = useSelector((state) => state.auth.userInfo);
  const coaches = useSelector((state) => state.coaches.coaches);
  const [error, setError] = useState(undefined);
  const dispatch = useDispatch();
  console.log(userInfo,"code")

  // State to hold the form values
  const [formValues, setFormValues] = useState({
    id:"",
    firstName: "",
    lastName: "",
    email: "",
    meetingUrl: "", // Add more fields as needed
  });

  console.log(error,"errorerror")

  // Update form values on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const updateProfile = async(e) => {
    e.preventDefault();
    try{
      await updateProfileAction(formValues)(dispatch);
      fetchUserProfile()
      setmessage("Profile Update Successfully")
      setshow(true)
      setisModalOpen(true)

      setTimeout(() => {
        window.location.reload();
      },2000);
      

    }catch(e){
    }
  };


  const fetchUserProfile = async () => {
    try {
      if (userInfo) {
        // Assuming ProfileAction takes an id parameter and is an asynchronous action creator
        const userData = await dispatch(ProfileAction(userInfo.id));
        console.log(userData, "fetchedUserData");

        // Update the form values with the fetched user data
        setFormValues({
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          meetingUrl: userData.meetingUrl,
          // Add more fields as needed
        });
      }
    } catch (error) {
      console.error('Error fetching user profile:', error.message);
    }
  };


  const handleInputChangedata = (event) => {
    const { name, value } = event.target;
    let validUrl =isValidZoomOrGoogleMeetLink(value)
   if (validUrl) {
     setFormValues({
       ...formValues,
       [name]: value
     });
   } else {
    // Set an error or perform other actions for an invalid URL
    // For example, you can set an error state to display an error message to the user
     setError('Invalid URL');
   }
   
  };


  const isValidZoomOrGoogleMeetLink = (link) => {
    const calendlyRegex = /^https?:\/\/calendly\.com\//i;
     return calendlyRegex.test(link);
  };

 
  useEffect(() => {
    fetchUserProfile();
  }, [dispatch, userInfo]);

  return (
    <>
    <div className={classes.profile_name}>
  <div className={classes.profile_wrap}>
    <div>
      <h1 className={classes.title}> My Profile </h1>
    </div>
    <div className={classes.left_arrow}>
      <a href="#">
        {" "}
        <i className="fa-solid fa-arrow-left" />
      </a>
    </div>
  </div>
  <div className={classes.profileuserfileds}>
    <div className={classes.profilename_input}>
      <i className="fa-regular fa-user" />{" "}
      <input id="firstName" name='firstName' type="text" placeholder="Lina" 
          value={formValues.firstName}
           onChange={handleInputChange} />
    </div>
    <div className={classes.profilename_input}>
      <i className="fa-regular fa-user" />{" "}
      <input id="lastName" name='lastName' type="text" placeholder="Anderson" value={formValues.lastName}  onChange={handleInputChange} />
    </div>
    <div className={classes.profilename_input}>
    <i class="fa-solid fa-envelope"></i>{" "}
      <input id="email" name='email' type="text" placeholder="linaanderson123@gmail.com"value={formValues.email}  onChange={handleInputChange}  />
    </div>
    <div className={classes.profilename_input}>
    <i class="fa-solid fa-link"></i>{" "}
    <input id="name"  name='meetingUrl'  type="text"  placeholder={!formValues.meetingUrl ? "api calendly" : ""}   value={formValues.meetingUrl || "https://calendly.com/"}  
  onChange={handleInputChangedata} 
/>
    </div>
  </div>
  {error && <span style={{ color: 'red' }}>{error}</span>}
  <div className={classes.save_btn}>
      <button onClick={(e)=>{updateProfile(e)}}>save </button>
  </div>
</div>
{
  show ? (
    <SucessModal  isModalOpen={isModalOpen} setisModalOpen={setisModalOpen}  setshow={setshow} message={message}/>
  ) : null
}

    </>
  );
}
