// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clients-section_sizing__AZOHV {
  width: 300px;
  background-color: #253341;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  bottom: 0;
    
}

.clients-section_sizing__AZOHV::-webkit-scrollbar {
  width: 0.5rem;
}

.clients-section_sizing__AZOHV::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.clients-section_sizing__AZOHV::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.7);
}
@media only screen and (min-width: 1201px) and (max-width: 1366px){
  .clients-section_sizing__AZOHV {
    width: 300px;
    background-color: #253341;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    overflow-y: scroll;
    position: fixed;
    top: 60px;
    bottom: 0;
      
  }

}
@media only screen and (min-width: 1367px) and (max-width: 1600px){
  .clients-section_sizing__AZOHV {
    width: 300px;
    background-color: #253341;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    overflow-y: scroll;
    position: fixed;
    top: 60px;
    bottom: 0;
      
  }

}

`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/home/clients-in-tasks/clients-section.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,mDAAmD;EACnD,kBAAkB;EAClB,eAAe;EACf,MAAM;EACN,SAAS;;AAEX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,0CAA0C;AAC5C;AACA;EACE;IACE,YAAY;IACZ,yBAAyB;IACzB,mDAAmD;IACnD,kBAAkB;IAClB,eAAe;IACf,SAAS;IACT,SAAS;;EAEX;;AAEF;AACA;EACE;IACE,YAAY;IACZ,yBAAyB;IACzB,mDAAmD;IACnD,kBAAkB;IAClB,eAAe;IACf,SAAS;IACT,SAAS;;EAEX;;AAEF","sourcesContent":[".sizing {\n  width: 300px;\n  background-color: #253341;\n  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;\n  overflow-y: scroll;\n  position: fixed;\n  top: 0;\n  bottom: 0;\n    \n}\n\n.sizing::-webkit-scrollbar {\n  width: 0.5rem;\n}\n\n.sizing::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);\n}\n\n.sizing::-webkit-scrollbar-thumb {\n  background-color: rgba(169, 169, 169, 0.7);\n}\n@media only screen and (min-width: 1201px) and (max-width: 1366px){\n  .sizing {\n    width: 300px;\n    background-color: #253341;\n    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;\n    overflow-y: scroll;\n    position: fixed;\n    top: 60px;\n    bottom: 0;\n      \n  }\n\n}\n@media only screen and (min-width: 1367px) and (max-width: 1600px){\n  .sizing {\n    width: 300px;\n    background-color: #253341;\n    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;\n    overflow-y: scroll;\n    position: fixed;\n    top: 60px;\n    bottom: 0;\n      \n  }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sizing": `clients-section_sizing__AZOHV`
};
export default ___CSS_LOADER_EXPORT___;
