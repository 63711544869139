// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-formatting-text-field_fullWidth__0tNLZ {
  width: 100%;
}

.add-formatting-text-field_background__BtVFr {
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: #2d3f51;
  opacity: 0.8;
}
.add-formatting-text-field_fullOpacity__9-pPX {
  opacity: 1;
}
.add-formatting-text-field_background__BtVFr:hover {
  opacity: 1;
}

.add-formatting-text-field_align__iuAmE {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.add-formatting-text-field_input__CHecV {
  font-family: inherit;
  border-radius: 4px;
  width: 90%;
  border: none;
  padding-left: 10px;
  resize: vertical;
  background-color: white;
}

.add-formatting-text-field_icons__sGCwP {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.add-formatting-text-field_styleButton__IOWTk {
  background-color: white;
  font-size: 0.8rem;
  padding: 2px 5px;
  border: none;
  border-radius: 2px;
}
.add-formatting-text-field_styleAddButton__qp5y3 {
  background-color: royalblue;
  font-size: 0.9rem;
  padding: 1px 3px;
  border: none;
  color: white;
  border-radius: 5px;
}

.add-formatting-text-field_buttonDisplay__9sN3- {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.add-formatting-text-field_check__GwVj1 {
  align-self: center;
  color: royalblue;
  width: 20px;
  height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/add-formatting-text-field.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,eAAe;EACf,qBAAqB;EACrB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,2BAA2B;EAC3B,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd","sourcesContent":[".fullWidth {\n  width: 100%;\n}\n\n.background {\n  margin: 0.5rem;\n  padding: 0.5rem;\n  border-radius: 0.5rem;\n  background: #2d3f51;\n  opacity: 0.8;\n}\n.fullOpacity {\n  opacity: 1;\n}\n.background:hover {\n  opacity: 1;\n}\n\n.align {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n}\n\n.input {\n  font-family: inherit;\n  border-radius: 4px;\n  width: 90%;\n  border: none;\n  padding-left: 10px;\n  resize: vertical;\n  background-color: white;\n}\n\n.icons {\n  margin-top: 1rem;\n  display: flex;\n  gap: 0.5rem;\n  justify-content: flex-end;\n}\n\n.styleButton {\n  background-color: white;\n  font-size: 0.8rem;\n  padding: 2px 5px;\n  border: none;\n  border-radius: 2px;\n}\n.styleAddButton {\n  background-color: royalblue;\n  font-size: 0.9rem;\n  padding: 1px 3px;\n  border: none;\n  color: white;\n  border-radius: 5px;\n}\n\n.buttonDisplay {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 5px;\n}\n\n.check {\n  align-self: center;\n  color: royalblue;\n  width: 20px;\n  height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": `add-formatting-text-field_fullWidth__0tNLZ`,
	"background": `add-formatting-text-field_background__BtVFr`,
	"fullOpacity": `add-formatting-text-field_fullOpacity__9-pPX`,
	"align": `add-formatting-text-field_align__iuAmE`,
	"input": `add-formatting-text-field_input__CHecV`,
	"icons": `add-formatting-text-field_icons__sGCwP`,
	"styleButton": `add-formatting-text-field_styleButton__IOWTk`,
	"styleAddButton": `add-formatting-text-field_styleAddButton__qp5y3`,
	"buttonDisplay": `add-formatting-text-field_buttonDisplay__9sN3-`,
	"check": `add-formatting-text-field_check__GwVj1`
};
export default ___CSS_LOADER_EXPORT___;
