// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-time_padding__jMUET {
  margin: 1rem 1.5rem;
}

.date-time_day__syUbL {
  color: white;
  font-size: 12px;
}

.date-time_monthWithDate__wntIs {
  color: white;
  font-size: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/home/clients-in-tasks/date-time.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".padding {\n  margin: 1rem 1.5rem;\n}\n\n.day {\n  color: white;\n  font-size: 12px;\n}\n\n.monthWithDate {\n  color: white;\n  font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"padding": `date-time_padding__jMUET`,
	"day": `date-time_day__syUbL`,
	"monthWithDate": `date-time_monthWithDate__wntIs`
};
export default ___CSS_LOADER_EXPORT___;
