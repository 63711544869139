import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingIndicator from "../../../Components/UI/loader";
import { getCoaches } from "../../../Store/Admin/coaches/coaches-actions";
import CoachTile from "./coach-tile";
import classes from "./coaches.module.css";

const Coaches = () => {
  const user = useSelector((state) => state.auth.userInfo);
  const coaches = useSelector((state) => state.coaches.coaches);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const dispatch = useDispatch();
  useEffect(() => {
    const getClients = async () => {
      setError(undefined);
      setIsLoading(true);
      try {
        await getCoaches()(dispatch);
        setIsLoading(false);
      } catch (e) {
        setError(e.toString);
        setIsLoading(false);
      }
    };
    if (user) {
      getClients();
    }
  }, [user, dispatch]);
  const component = error ? (
    <p className={classes.error}>{error}</p>
  ) : isLoading ? (
    <div className={classes.center}>
      <LoadingIndicator />
    </div>
  ) : coaches.length ? (
    coaches.map((e) => (
      <CoachTile
        key={e.email}
        firstName={e.firstName}
        lastName={e.lastName}
        email={e.email}
      />
    ))
  ) : (
    <p className={classes.noCoaches}>
      Please click the '+ Add Coaches' Button to add Coaches
    </p>
  );
  return component;
};

export default Coaches;
