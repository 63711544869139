import { addUserToCloudFirestore } from "../users/add-user-to-cloud";
export const addClientToDB = async (id, userInfo) => {
  if (!id) {
    throw Error("User id is null");
  }
  if (
    !userInfo.firstName ||
    !userInfo.lastName ||
    !userInfo.role ||
    !userInfo.coachId
  ) {
    throw Error("Please check your first name and last name");
  }
  const data = {
    id,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    role: userInfo.role,
    coachId: userInfo.coachId,
    email: userInfo.email,
    notes:userInfo.Notes
  };
  if (userInfo.language) {
    data.language = userInfo.language;
  }
  await addUserToCloudFirestore(id, data);
};
