// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-tile_tile__bJi4v {
  padding: 0.5rem;
  margin: 2px 0px;
  background-color: #19222f;
  border-radius: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  gap: 5rem;
  justify-content: space-between !important;
}
.client-tile_textInTile__ECt4M {
  color: lightgray;
  font-size: 16px;
  width: 7rem;
  text-align: justify;
}

.client-tile_tile__bJi4v:hover {
  background-color: #243041;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/clients/client-tile.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,yCAAyC;AAC3C;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".tile {\n  padding: 0.5rem;\n  margin: 2px 0px;\n  background-color: #19222f;\n  border-radius: 10px;\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  align-items: flex-start;\n  gap: 5rem;\n  justify-content: space-between !important;\n}\n.textInTile {\n  color: lightgray;\n  font-size: 16px;\n  width: 7rem;\n  text-align: justify;\n}\n\n.tile:hover {\n  background-color: #243041;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tile": `client-tile_tile__bJi4v`,
	"textInTile": `client-tile_textInTile__ECt4M`
};
export default ___CSS_LOADER_EXPORT___;
