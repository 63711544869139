import UnitTask from "../../../../../../Components/UI/unit-task";
import classes from "./home-topic.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { getTasksOfHomeTopic } from "../../../../../../Store/Coach/tasks/tasks-actions";
import WriteCommentOnTopic from "./write-comment-on-topic";
import UnitTopic from "../../../../../../Components/UI/unit-topic";

const HomeTopic = (props) => {
  const topicId = props.topicId;
  const tasks = useSelector((state) =>
    state.topics.homeTasks.filter((e) => e.topicId === props.topicId)
  );
  const component =
    tasks?.length &&
    tasks?.map((e) => <UnitTask key={e.id} task={e} topicId={topicId} />);
  const dispatch = useDispatch();
  const getTasks = useCallback(async () => {
    await getTasksOfHomeTopic(topicId, props.clientId, props.groupId)(dispatch);
  }, [topicId, props.clientId, dispatch, props.groupId]);


  console.log(component,"componentcomponentcomponent")
  console.log(props.comments,"nhh")

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  return (
    <div className={classes.background}>
      <p className={classes.white}>{props.date}</p>
      <div className={classes.topic}>
        <UnitTopic
          topicId={topicId}
          name={props.topicName}
          clientId={props.clientId}
        />
      </div>
      {component && <div className={classes.tasks}>{component}</div>}
      <WriteCommentOnTopic topicId={props.topicId} comments={props.comments} topicDate={props.date} clientId={props.clientId} />
    </div>
  );
};

export default HomeTopic;
