import { tasksActions } from "./tasks-slice";
import taskStatus from "../../../util/task-status";
import { addTopicClientService, addTopicGroupService } from "../../../services/tasks/add-topic";
import getTopicsByClientIdService from "../../../services/tasks/get-topics-by-client";
import addTaskToTopicService from "../../../services/tasks/add-task-to-topic";
import getTasksOfTopicService from "../../../services/tasks/get-tasks-of-topic";
import getTopicsHomeService from "../../../services/tasks/get-topics-home";
import addCommentToTopicService from "../../../services/tasks/add-comment-to-topic";
import deleteTaskService from "../../../services/tasks/delete-task-by-id";
import updateTaskService from "../../../services/tasks/update-task";
import deleteTopicService from "../../../services/tasks/delete-topic";
import editTopicService from "../../../services/tasks/edit-topic";
import addTasksToTopicService from "../../../services/tasks/add-tasks-to-topic";
import { notificationTrigger } from "../../../services/tasks/send-notification";
import getTopicsByGroupIdService from "../../../services/tasks/get-topics-by-group";

const addTopicClient = async (  
  task,
  clientId,
  coachId,
  topicOrder
  ) => {
  try {
    console.log(task)
    const topicData = {
      createdAt: new Date(task.date),
      Pdf: task.Pdf,
      video:task.video,
      task_name: task.task_name,
      todolist: task.todolist,
      recordingfile: task.recordingfile,
      clientId: clientId,
      coachId: coachId,
      status:"inProgress",
      coach_comments:[],
      client_comment:[]
    };
    
    console.log(topicData,"topicDatatopicData")
   const topic = await addTopicClientService(topicData);
   console.log(topic,"code is added")
   return topic;
  } catch (e) {
    throw e;
  }
};

const addTopicGroup = async (topicName, groupId, date, coachId, topicOrder) => {
  try {
    const topicData = {
      topicName,
      groupId,
      coachId,
      topicOrder,
      createdAt: date,
    };
    const topic = await addTopicGroupService(topicData);
    return topic;
  } catch (e) {
    throw e;
  }
};

export const addTopic =
  (task, clientId, coachId) => async (dispatch) => {
    try {
      console.table(task)
      console.log(clientId,"clientId")
      console.log(coachId,"coachIdcoachId")
      const topic = await addTopicClient(
        task,
        clientId,
        coachId,
      );
      dispatch(tasksActions.addTopic(topic));
    } catch (e) {
      throw e;
    }
  };

export const addGroupTopic =
  (topicName, groupId, date, coachId, topicOrder) => async (dispatch) => {
    try {
      const topic = await addTopicGroup(
        topicName,
        groupId,
        date,
        coachId,
        topicOrder
      );
      dispatch(tasksActions.addTopic(topic));
    } catch (e) {
      throw e;
    }
  };

export const deleteTopic = (topicId, clientId) => async (dispatch) => {
  try {
    const payload = {
      topicId,
      clientId,
    };
    await deleteTopicService(topicId);
    dispatch(tasksActions.deleteTopic(payload));
  } catch (e) {
    throw e;
  }
};

export const updateTopicName =
  (topicId, clientId, newTopicName) => async (dispatch) => {
    try {
      const payload = {
        topicId,
        newTopicName,
        clientId,
      };
      await editTopicService(topicId, newTopicName);
      dispatch(tasksActions.editTopicName(payload));
    } catch (e) {
      throw e;
    }
  };

export const addTaskToTopic =
  (taskDescription, topicId, taskOrder) => async (dispatch) => {
    try {
      const taskInfo = {
        description: taskDescription,
        status: taskStatus.inProgress,
        taskOrder,
      };
      const task = await addTaskToTopicService(taskInfo, topicId);
      const taskData = {
        topicId,
        ...task,
      };
      dispatch(tasksActions.addTask(taskData));
      return taskData.id;
    } catch (e) {
      throw e;
    }
  };

export const getTopicsOfClient = (clientId) => async (dispatch) => {
  try {
    
    const topics = await getTopicsByClientIdService(clientId);
    dispatch(tasksActions.setTopics(topics));
  } catch (e) {
    throw e;
  }
};
export const getTopicsOfGroup = (groupId) => async (dispatch) => {
  try {
    const topics = await getTopicsByGroupIdService(groupId);
    dispatch(tasksActions.setTopics(topics));
  } catch (e) {
    throw e;
  }
};

export const getTasksOfTopic = (topicId) => async (dispatch) => {
  try {
    
    const payload = await taskOfTopic(topicId);
    console.log(payload,'payload')
   
   
    dispatch(tasksActions.setTasks(payload));
  } catch (e) {
    throw e;
  }
};
export const getTasksOfHomeTopic = (topicId, clientId, groupId) => async (dispatch) => {
  try {
    if (!clientId && !groupId) {
      throw Error("No clientId/groupId found");
    }
    const payload = await taskOfTopic(topicId);
    payload.tasks?.map((e) => {
      return clientId ? {
        clientId: clientId,
        ...e,
      } : {
        groupId: groupId,
        ...e,
      };
    });
    dispatch(tasksActions.setHomeTask(payload));
  } catch (e) {
    throw e;
  }
};

export const taskOfTopic = async (topicId) => {
  const payload = {};
  console.log(topicId,"topicId")
  const tasks = await getTasksOfTopicService(topicId);
  console.log(tasks,"taskstaskstasks")
  payload.tasks = tasks.map((e) => {
    e.topicId = topicId;
    return e;
  });
  return payload;
};

export const getTopicsOfHomePage = (coachId) => async (dispatch) => {
  try {
    const topics = await getTopicsHomeService(coachId);
    dispatch(tasksActions.setHomeTopics(topics));
  } catch (e) {
    throw e;
  }
};

export const addCommentOnTopic = (topicId, comment) => async (dispatch) => {
  try {
    const payload = {
      topicId,
      comment,
    };

    console.log(payload)

    await addCommentToTopicService(topicId,payload);
    dispatch(tasksActions.addCommentToTopic(payload));
  } catch (e) {
    throw e;
  }
};

export const deleteTask = (taskId, topicId) => async (dispatch) => {
  try {
    await deleteTaskService(taskId, topicId);
    dispatch(tasksActions.deleteTask(taskId));
  } catch (e) {
    throw e;
  }
};

export const updateTask =
  (taskId, task) => async (dispatch) => {
    try {
      const payload = {
        taskId,
        task,
      };
      const data =await updateTaskService(taskId, task);
      console.log("codeeee",data)
      dispatch(tasksActions.updateTaskList(payload));
    } catch (e) {
      throw e;
    }
  };

export const paste =
  (topic, tasks, clientId, date, topicOrder) => async (dispatch) => {
    try {
      const addedTopic = await addTopicClient(
        topic.topicName,
        clientId,
        date,
        topic.coachId,
        topicOrder
      );
      const tasksToAdd = await addTasksToTopicService(tasks, addedTopic.id);
      dispatch(tasksActions.addTopic(addedTopic));
      dispatch(tasksActions.addTask(tasksToAdd));
      dispatch(tasksActions.paste());
    } catch (e) {
      throw e;
    }
  };

export const sendNotification = (topic, tasks, deviceToken, taskId, topicId, date) => async (dispatch) => {
  try {
    const title = date ? "New Comment added" : typeof tasks === 'object' ? "New Topic Added" : tasks ? 'New Task Added' : 'New Topic Added';
    const task = typeof tasks === 'object' ? tasks.reduce((task, t) => `${task}\n${t.description}`, []) : tasks;
    const body = task ?
      `${topic},\n${task}` : topic;
    await notificationTrigger(title, body, deviceToken, taskId, topicId, date);
  } catch (e) {
    throw e;
  }
}


// export const AddCoachComment =
//   (taskId, comment) => async (dispatch) => {
//     try {
//       const payload = {
//         taskId,
//         comment,
//       };
//       await updateTaskService(taskId, comment);
//       dispatch(tasksActions.updateTask(payload));
//     } catch (e) {
//       throw e;
//     }
//   };