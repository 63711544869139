import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClients } from "../../../../Store/Coach/Client/client-actions";
import { clientActions } from "../../../../Store/Coach/Client/client-slice";
import Client from "./client";
import { getGroups } from "../../../../Store/Coach/groups/group-actions";
// import { getClients } from "../../../../Store/Coach/Client/client-actions";

const NewClients = () => {
  const user = useSelector((state) => state.auth.userInfo);
  const clients = useSelector((state) => state.clients.clients);
  console.log(user,"user")
  console.log(clients,"clientsclients")
  const topics = useSelector((state) => state.topics.homeTopics);
  const clientIds = useMemo(
    () =>
      topics?.map((e, i) => {
        return { clientId: e.clientId, order: i };
      }),
    [topics]
  );
  const dispatch = useDispatch();
  const fetchClients = useCallback(async () => {
    await getClients(user.id)(dispatch);
    if (clientIds?.length) {
      dispatch(clientActions.setHomeClients(clientIds));
    }
  }, [user, clientIds, dispatch]);
  const fetchGroups = useCallback(async () => {
    await getGroups()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      fetchClients();
      fetchGroups();
    }
  }, [user, fetchClients, fetchGroups]);
  const clientsComponent = clients?.map((e) => (
    <Client
      isSelected={true}
      key={e.email}
      name={`${e.firstName} ${e.lastName}`}
      email={e.email}
      scrollId={e.order}
      id={e.id}
      clients={clients}
    />
  ));
  return <div>{clientsComponent}</div>;
};
export default NewClients;
