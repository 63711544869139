import classes from "./splash.module.css";
const Splash = () => {
  return (
    <div className={classes.center}>
      <img
        className={classes.img}
        src={require("../../assets/logos/logo.png")}
        alt="Logo"
      />
      {/* <h1>Luc Labarile</h1> */}
    </div>
  );
};

export default Splash;
