// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_button__wxtKy {
  margin: 10px;
  color: white;
  font-size: 16px;
  background: #293c89;
  padding: 10px 16px;
  border-radius: 0.5rem;
  width: 200px;
  border: none;
  height: 48px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
  opacity: 0.9;
}
.button_button__wxtKy:hover {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/button.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,mDAAmD;EACnD,eAAe;EACf,YAAY;AACd;AACA;EACE,UAAU;AACZ","sourcesContent":[".button {\n  margin: 10px;\n  color: white;\n  font-size: 16px;\n  background: #293c89;\n  padding: 10px 16px;\n  border-radius: 0.5rem;\n  width: 200px;\n  border: none;\n  height: 48px;\n  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;\n  cursor: pointer;\n  opacity: 0.9;\n}\n.button:hover {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_button__wxtKy`
};
export default ___CSS_LOADER_EXPORT___;
