import classes from "./admin-coach-menu.module.css";
import { Logout } from "../../../Components/index";
import { NavLink } from "react-router-dom";
import { routes } from "../../../util/routes";
const AdminMenu = () => {
  const isActive = ({ isActive }) =>
    isActive ? classes.active : classes.inactive;
  return (
    <div className={classes.align}>
      <div className={classes.row}>
        <img
          className={classes.logo}
          src={require("../../../assets/logos/logo.png")}
          alt="logo"
        />
       

        <ul className={classes.ul}>
          <li>
            <NavLink
              className={isActive}
              end
              to={`/${routes.adminDashboard.index}`}
            >
              Clients
            </NavLink>
          </li>
        </ul>
      </div>
      <Logout />
    </div>
  );
};

export default AdminMenu;
