import moment from "moment";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTasksOfTopic } from "../../../../../../Store/Coach/tasks/tasks-actions";
import AddTask from "./add-task";
import classes from "./client-topic.module.css";
import UnitTask from "../../../../../../Components/UI/unit-task";
import UnitTopic from "../../../../../../Components/UI/unit-topic";
const ClientTopic = (props) => {
  const dispatch = useDispatch();
  const tasksOfTopic = useSelector((state) =>
    state.topics.tasks?.filter((e) => e.topicId === props.topicId)
  );

   console.log(tasksOfTopic,"tasksOfTopictasksOfTopic")

  const tasks = tasksOfTopic?.map((t, i) => (
    <UnitTask task={t} key={i} topicId={props.topicId} />
  ));
  const addTask = moment(props.date).add(1, "day").isAfter(moment()) && (
    <AddTask
      topicId={props.topicId}
      topicName={props.name}
      date={moment(props.date)}
      // To preserve the order of tasks
      taskOrder={
        tasks.length > 0
          ? (tasks[tasks.length - 1].taskOrder ?? tasks.length) + 1
          : 1
      }
    />
  );
  const getTasks = useCallback(
    async (clientId) => {
      await getTasksOfTopic(clientId, props.date)(dispatch);
    },
    [dispatch, props.date]
  );
  useEffect(() => {
    getTasks(props.topicId);
  }, [getTasks, props.topicId]);

  return (
    <div className={classes.center}>
      <UnitTopic
        topicId={props.topicId}
        name={props.name}
        clientId={props.clientId}
        showCopy={true}
      />
      {tasks}
      {addTask}
    </div>
  );
};

export default ClientTopic;
