import { query, collection, where, getDocs,doc,getDoc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";
import moment from "moment";

const getProgram = (programId) => {
  if (!programId) {
    throw Error("Client id not found");
  }
  return getProgramById(programId);
};


// // const topicRef = collection(db, "topics");
// console.log(topicRef,"topicRef")
// const taskRef = collection(topicRef, topicId, "tasks");
// console.log(topicRef,"topicRef")
// const response = await getDocs(taskRef);
// console.log(response.docs,"response")
// // debugger
// const data = response.docs

//   .map((e) => {
//     return {
//       id: e.id,
//       ...e.data(),
//     };
//   })
//   .sort((a, b) => +a.taskOrder - +b.taskOrder);
// return data;
const getProgramById = async (programId) => {

    const programsCollectionRef = collection(db, "programs"); // Assuming "programs" is your collection name
    const programDocRef = doc(programsCollectionRef, programId);
  
    try {
      const programSnapshot = await getDoc(programDocRef);
      if (programSnapshot.exists()) {
        const programData = programSnapshot.data();
        // debugger
      

        console.log(programData, "sortedTasks");
        return programData
      } else {
        console.log("Program not found");
      }
    } catch (error) {
      console.error("Error getting program by ID:", error);
    }
  };
  
  

  

export default getProgram;
