// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-comments_align__tHyQT {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/home/orderedTasks/components/topics/home-comments.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,QAAQ;AACV","sourcesContent":[".align {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"align": `home-comments_align__tHyQT`
};
export default ___CSS_LOADER_EXPORT___;
