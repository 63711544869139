// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-form-field_textfield__kf21K {
  width: 15rem;
    height: 48px;
    border: none;
    border-radius: 0.5rem;
    margin: 10px;
    padding-left: 20px;
}

.text-form-field_textfieldExtra__-kPFw {
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/text-form-field.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;IACV,YAAY;IACZ,YAAY;IACZ,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".textfield {\n  width: 15rem;\n    height: 48px;\n    border: none;\n    border-radius: 0.5rem;\n    margin: 10px;\n    padding-left: 20px;\n}\n\n.textfieldExtra {\n  width: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textfield": `text-form-field_textfield__kf21K`,
	"textfieldExtra": `text-form-field_textfieldExtra__-kPFw`
};
export default ___CSS_LOADER_EXPORT___;
