// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unit-task_background__2N7h9 {
  margin: 1px;
  padding: 0.5rem;
  width: 90%;
  border-radius: 0.5rem;
  background: #2d3f51;
  display: flex;
  flex-direction: column;
}
.unit-task_align__EgCo0 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  color: whitesmoke;
  text-align: start;
  font-size: 14px;
}

.unit-task_task__xrvwi {
  word-break: break-all;
}

.unit-task_task__xrvwi > a {
  color: white;
}

.unit-task_buttons__kbGa8 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/unit-task.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,UAAU;EACV,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,uBAAuB;EACvB,QAAQ;EACR,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".background {\n  margin: 1px;\n  padding: 0.5rem;\n  width: 90%;\n  border-radius: 0.5rem;\n  background: #2d3f51;\n  display: flex;\n  flex-direction: column;\n}\n.align {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 8px;\n  color: whitesmoke;\n  text-align: start;\n  font-size: 14px;\n}\n\n.task {\n  word-break: break-all;\n}\n\n.task > a {\n  color: white;\n}\n\n.buttons {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `unit-task_background__2N7h9`,
	"align": `unit-task_align__EgCo0`,
	"task": `unit-task_task__xrvwi`,
	"buttons": `unit-task_buttons__kbGa8`
};
export default ___CSS_LOADER_EXPORT___;
