// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-text-field_background__T0wx6 {
  margin: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.5rem;
  background: #2d3f51;
  opacity: 0.8;
}
.add-text-field_fullOpacity__9ANrA {
  opacity: 1;
}
.add-text-field_background__T0wx6:hover {
  opacity: 1;
}

.add-text-field_align__BEqXs {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.add-text-field_input__-kVbL {
  font-family: inherit;
  border-radius: 4px;
  width: 90%;
  border: none;
  padding-left: 10px;
  resize: vertical;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/add-text-field.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,WAAW;EACX,qBAAqB;EACrB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".background {\n  margin: 0.5rem;\n  padding: 0.5rem;\n  width: 100%;\n  border-radius: 0.5rem;\n  background: #2d3f51;\n  opacity: 0.8;\n}\n.fullOpacity {\n  opacity: 1;\n}\n.background:hover {\n  opacity: 1;\n}\n\n.align {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n}\n\n.input {\n  font-family: inherit;\n  border-radius: 4px;\n  width: 90%;\n  border: none;\n  padding-left: 10px;\n  resize: vertical;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `add-text-field_background__T0wx6`,
	"fullOpacity": `add-text-field_fullOpacity__9ANrA`,
	"align": `add-text-field_align__BEqXs`,
	"input": `add-text-field_input__-kVbL`
};
export default ___CSS_LOADER_EXPORT___;
