import { doc, getDoc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

const getUserFromCloud = async (id) => {
  if (!id) {
    throw Error("Unable to get user form the cloud because 'id' is null");
  }
  const docRef = doc(db, "users", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw Error("User does not exist with the give 'id'");
  }
};

export default getUserFromCloud;
