import classes from "./coach-tile.module.css";
const CoachTile = (props) => {
  return (
    <div className={classes.tile}>
      <p className={classes.textInTile}>{props.firstName}</p>
      <p className={classes.textInTile}>{props.lastName}</p>
      <p className={classes.textInTile}>{props.email}</p>
    </div>
  );
};

export default CoachTile;
