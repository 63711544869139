import { createUserWithEmailAndPassword,fetchSignInMethodsForEmail } from "firebase/auth";
import { workerAuth, } from "../../Configuration/initialize-firebase";
import { toast } from "react-toastify";



export const MultipleCreateUser = async (data) => {

  try {
    const userIds = [];
    const promises = data.map(async (user) => {
      const { email, password } = user;
      console.log(user,"useruseruser")
      if (!email || !password) {
        throw new Error("Email or password is undefined or null");
      }
      const signInMethods = await fetchSignInMethodsForEmail(workerAuth, email);

      if(signInMethods && signInMethods.length > 0){
        // toast.warning(`Email '${email}' already exists. Skipping creation.`);
      }else{
        const userCredentials = await createUserWithEmailAndPassword(
          workerAuth, // Replace 'workerAuth' with your Firebase auth instance
          email,
          password
        );
        userIds.push(userCredentials.user.uid);
      }
    });

    await Promise.all(promises);
    console.log(userIds,"userIds")
    return userIds;
  } catch (error) {
    throw new Error("Error creating users: " + error.message);
  }
};