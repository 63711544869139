import { collection, getDocs } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";
const getTasksOfTopicService = async (topicId) => {
  console.log(topicId,"topicIdtopicId")
  if (!topicId) {
    Error("Unable to find the topic Id");
  }
  return await getTaskOfTopic(topicId);
};

const getTaskOfTopic = async (topicId) => {
  const topicRef = collection(db, "topics");
  console.log(topicRef,"topicRef")
  const taskRef = collection(topicRef, topicId, "tasks");
  console.log(topicRef,"topicRef")
  const response = await getDocs(taskRef);
  console.log(response.docs,"response")
  // debugger
  const data = response.docs

    .map((e) => {
      return {
        id: e.id,
        ...e.data(),
      };
    })
    .sort((a, b) => +a.taskOrder - +b.taskOrder);
  return data;
};

export default getTasksOfTopicService;
