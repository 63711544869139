import { useDispatch, useSelector } from "react-redux";
import { addCommentOnTopic } from "../../../../../../Store/Coach/tasks/tasks-actions";
import HomeComments from "./home-comments";
import classes from "./write-comment-on-topic.module.css";
import React,{useState} from "react";
import { sendNotification } from "../../../../../../Store/Coach/tasks/tasks-actions";
import axios  from "axios";
// import FileUpload from "./uploader/upload";
import CloudinaryUpload from "./uploader/upload";
const WriteCommentOnTopic = ({ topicId, comments, topicDate, clientId }) => {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients.homeClients);
  const  [file,setfile]=useState(null)

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      const comment = event.target[0].value;  
      event.target.reset();
      // console.log(topicId,"topicId")
      // console.log(file,"code ")
      await addCommentOnTopic(topicId, comment,file)(dispatch);
      const client = clients.find((client) => client.id === clientId);
      if (client.deviceToken) {
        await sendNotification(comment, undefined, client.deviceToken, undefined, undefined, topicDate)(dispatch);
      }
    } catch (e) {
      //do something
    }
  };

  // const handleFileChange =async(event)=>{
  //   try {
  //     const formData = new FormData();
  //     formData.append('file', event.target.files[0]);
  //     formData.append('upload_preset', 'your_upload_preset'); // Replace with your Cloudinary upload preset

  //     const response = await axios.post(
  //       `https://api.cloudinary.com/v1_1/db3gzsopp/TaskRealtiveQuery        `,
  //       formData
  //     );

  //     console.log('File uploaded successfully:', response.data.url);
  //   } catch (error) {
  //     console.error('Error uploading file:', error.message);
  //   }
  // }

  return (
    <div className={classes.center}>
      <div className={classes.display}>
        {comments && <HomeComments comments={comments} />}
        <p className={classes.text}>Write a comment</p>
        <form className={classes.fullWidth} onSubmit={onSubmit} method="submit">
          <input
            className={classes.commentField}
            input="text"
            placeholder="Write a comment..."
          />

        </form>
      </div>
    </div>
  );
};

export default WriteCommentOnTopic;
