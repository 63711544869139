// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SucessMoadl_modal_box_wrap__IF8fq{
    background: #535353!important;
}
.SucessMoadl_head__orJdY{
    text-align: center;
}
.SucessMoadl_head__orJdY img{
    width: 80px;
}
.SucessMoadl_head__orJdY h2{
    font-size: 20px;
    color: black;
}
.SucessMoadl_head__orJdY p{
    color: black;
    font-size: 16px;
}
.SucessMoadl_yesnotbutton__iwTpq{
    text-align: center;
    display: flex;
    column-gap: 14px;
    justify-content: center;
}
.SucessMoadl_nobtn__pscYJ{
    background: #FF0000;
border: navajowhite;
padding: 10px 44px;
color: white;
border-radius: 4px;
font-size: 18px;
text-transform: capitalize;
}
.SucessMoadl_yes__0R5ob{
    background-color: #00A3FF;
    border: navajowhite;
padding: 10px 44px;
color: white;
border-radius: 4px;
font-size: 18px;
text-transform: capitalize;
}


.SucessMoadl_showfile__ovZuZ {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.SucessMoadl_showfile__ovZuZ i{
    position: absolute;
    right: 0; /* Adjust as needed */
    color: white;
    /* Optionally, you can use top: 50%; transform: translateY(-50%); for vertical centering */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Task/SucessMoadl.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;AACA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI,YAAY;IACZ,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;IACI,mBAAmB;AACvB,mBAAmB;AACnB,kBAAkB;AAClB,YAAY;AACZ,kBAAkB;AAClB,eAAe;AACf,0BAA0B;AAC1B;AACA;IACI,yBAAyB;IACzB,mBAAmB;AACvB,kBAAkB;AAClB,YAAY;AACZ,kBAAkB;AAClB,eAAe;AACf,0BAA0B;AAC1B;;;AAGA;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,QAAQ;IACR,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,QAAQ,EAAE,qBAAqB;IAC/B,YAAY;IACZ,0FAA0F;AAC9F","sourcesContent":[".modal_box_wrap{\n    background: #535353!important;\n}\n.head{\n    text-align: center;\n}\n.head img{\n    width: 80px;\n}\n.head h2{\n    font-size: 20px;\n    color: black;\n}\n.head p{\n    color: black;\n    font-size: 16px;\n}\n.yesnotbutton{\n    text-align: center;\n    display: flex;\n    column-gap: 14px;\n    justify-content: center;\n}\n.nobtn{\n    background: #FF0000;\nborder: navajowhite;\npadding: 10px 44px;\ncolor: white;\nborder-radius: 4px;\nfont-size: 18px;\ntext-transform: capitalize;\n}\n.yes{\n    background-color: #00A3FF;\n    border: navajowhite;\npadding: 10px 44px;\ncolor: white;\nborder-radius: 4px;\nfont-size: 18px;\ntext-transform: capitalize;\n}\n\n\n.showfile {\n    display: flex;\n    width: 100%;\n    position: relative;\n    justify-content: center;\n    align-items: center;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n}\n\n.showfile i{\n    position: absolute;\n    right: 0; /* Adjust as needed */\n    color: white;\n    /* Optionally, you can use top: 50%; transform: translateY(-50%); for vertical centering */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_box_wrap": `SucessMoadl_modal_box_wrap__IF8fq`,
	"head": `SucessMoadl_head__orJdY`,
	"yesnotbutton": `SucessMoadl_yesnotbutton__iwTpq`,
	"nobtn": `SucessMoadl_nobtn__pscYJ`,
	"yes": `SucessMoadl_yes__0R5ob`,
	"showfile": `SucessMoadl_showfile__ovZuZ`
};
export default ___CSS_LOADER_EXPORT___;
