import classes from "./frame-with-date.module.css";
import moment from "moment";
import ClientTopic from "./client-topic";
import AddTopic from "../add-topic";
import { useSelector } from "react-redux";
import React from "react";

const FrameWithDate = (props) => {
  const allTopics = useSelector((state) => state.topics.topics);
  const topics = allTopics.filter(
    (e) => e.createdAt === moment(props.date).format("DD/MM/YYYY")
  );
  const addTopic = moment(props.date).add(1, "day").isAfter(moment()) && (
    <AddTopic
      date={moment(props.date).toDate()}
      // To preserve the order of topics
      topicOrder={
        topics.length > 0
          ? (topics[topics.length - 1].topicOrder ?? topics.length) + 1
          : 1
      }
    />
  );
  return (
    <div className={classes.border}>
      <p className={classes.date}>{props.date.format("D")}</p>
      <div className={classes.alignTasks}>
        {topics.map((e) => (
          <ClientTopic
            name={e.topicName}
            key={e.id}
            clientId={props.clientId}
            tasks={e.tasks ?? []}
            topicId={e.id}
            date={props.date}
          />
        ))}
      </div>
      {addTopic}
    </div>
  );
};

export default React.memo(FrameWithDate);
