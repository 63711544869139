import { collection, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";
const deleteTaskService = async (taskId, topicId) => {
  if (!taskId || !topicId) {
    throw Error("Topic or task id is not found");
  }
  await deleteTask(taskId, topicId);
};

const deleteTask = async (taskId, topicId) => {
  const topicRef = collection(db, "topics");
  const taskRef = collection(topicRef, topicId, "tasks");
  const taskDoc = doc(taskRef, taskId);
  await deleteDoc(taskDoc);
};

export default deleteTaskService;
