// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_loader__Jkr9x {
  border: 5px solid lightgray; /* Light grey */
  border-top: 5px solid gray; /* Blue */
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: loader_spin__mHLJx 2s linear infinite;
}

@keyframes loader_spin__mHLJx {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/loader.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B,EAAE,eAAe;EAC5C,0BAA0B,EAAE,SAAS;EACrC,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gDAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader {\n  border: 5px solid lightgray; /* Light grey */\n  border-top: 5px solid gray; /* Blue */\n  border-radius: 50%;\n  width: 2rem;\n  height: 2rem;\n  animation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `loader_loader__Jkr9x`,
	"spin": `loader_spin__mHLJx`
};
export default ___CSS_LOADER_EXPORT___;
