import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminDashboard from "./Pages/admin-dashboard/admin-dashboard";
import ClientTab from "./Pages/coach-dashboard/clients/clients-tab";
import DashboardCoach from "./Pages/coach-dashboard/dashboard-coach";
import AddClient from "./Pages/coach-dashboard/clients/add-clients";
import Login from "./Pages/login/login";
import Splash from "./Pages/splash/splash";
import { setupListener } from "./Store/Authentication/auth-actions";
import AddCoaches from "./Pages/admin-dashboard/coaches/add-coaches";
import CoachTab from "./Pages/admin-dashboard/coaches/coach-tab";
import TasksPage from "./Pages/coach-dashboard/tasks/main";
import { routes } from "./util/routes";
import role from "./util/role-enum";
import CoachDashboardHome from "./Pages/coach-dashboard/home/home";
import GroupsTab from "./Pages/coach-dashboard/groups/groups-tab";
import AddGroup from "./Pages/coach-dashboard/groups/add-group";
import GroupsPage from "./Pages/coach-dashboard/group-task/main";
import ChangePassword from "./Pages/coach-dashboard/changepassword/ChangePassword";
import Profile from "./Pages/coach-dashboard/profile/Profile";
import Program from "./Pages/Program/program/progrma";
import CreateProgrmatask from "./Pages/Program/program/create-progrma-task";
import PdfReader from "./Components/pdf-reader/PdfReader";
import EDItProgrmatask from "./Pages/Program/program/Edit-program-task";
import SignUp from "./Pages/SignUp/SignUp";


function App() {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const isLoading = useSelector((state) => state.auth.isCheckingAuthStatus);
  const dispatch = useDispatch();
  useEffect(() => {
    setupListener()(dispatch);
  }, [dispatch]);
  return (
    <div>
      <Routes>
        <Route
          index
          element={
            isLoading ? (
              <Splash />
            ) : !userInfo ? (
              <Login />
            ) : (
              <Navigate
                to={
                  userInfo && userInfo.role === role.adminCoach
                    ? routes.adminDashboard.index
                    : routes.coachDashboard.index
                }
              />
            )
          }
        />
        
        {userInfo && userInfo.role === role.adminCoach && (
          <Route
            path={routes.adminDashboard.index}
            element={<AdminDashboard />}
          >
            <Route index element={<CoachTab />} />
            <Route
              path={routes.adminDashboard.addCoach}
              element={<AddCoaches />}
            />
          </Route>
        )}

        {userInfo && userInfo.role === role.coach && (
          <>
            <Route
              path={routes.coachDashboard.index}
              element={<DashboardCoach />}
            >
               {/* <Route
                  path={routes.coachDashboard.pdf}
                  element={<PdfReader/>}
                /> */}
              <Route path={`${routes.coachDashboard.profile.index}`} element={<Profile />}></Route>
              <Route path={`${routes.coachDashboard.profile.changepassword}`} element={<ChangePassword />}></Route>
              {/* <Routes> */}
              <Route path={`${routes.coachDashboard.program.index}`}>
              <Route index element={<Program />} />
              <Route path={`${routes.coachDashboard.program.create}`} element={<CreateProgrmatask />} />
              <Route path={`${routes.coachDashboard.program.edit}`} element={<EDItProgrmatask />} />
            </Route>
              {/* </Routes> */}
              <Route index element={<CoachDashboardHome />} />
              <Route path={routes.coachDashboard.clients.index}>
                <Route index element={<ClientTab />} />
                <Route
                  path={routes.coachDashboard.clients.addClients}
                  element={<AddClient />}
                />
                <Route
                  path={routes.coachDashboard.clients.tasks}
                  element={<TasksPage />}
                />
              </Route>
               
              {/* </Route> */}
              
              <Route path={routes.coachDashboard.groups.index}>
                <Route index element={<GroupsTab />} />
                <Route
                  path={routes.coachDashboard.groups.addGroups}
                  element={<AddGroup />}
                />
                <Route
                  path={routes.coachDashboard.groups.tasks}
                  element={<GroupsPage />}
                />
              </Route>
            </Route>
          </>
        )}
        <Route path={routes.any} element={<Navigate to={routes.index} />} />
      </Routes>
    </div>
  );
}

export default App;
