import useInput from "../../Hooks/use-input";
import classes from "./form.module.css";
import { TextFormField, Button, LoadingButton } from "../index";
import { createCoach } from "../../Store/Admin/coaches/coaches-actions";
import { useDispatch, useSelector } from "react-redux";
import { createClient } from "../../Store/Coach/Client/client-actions";
import { useNavigate } from "react-router-dom";
import role from "../../util/role-enum";
import { useState } from "react";
import languageEnum from "../../util/language-enum";
const UserForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.userInfo);
  const isLoading = useSelector((state) =>
    props.role === role.client
      ? state.clients.isLoading
      : state.coaches.isLoading
  );
  const [language, setLanguage] = useState(languageEnum.english);
  const onLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const {
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    valueBlurHandler: firstNameBlurHandler,
    reset: firstNameReset,
    value: firstName,
    isValid: isFirstNameValid,
  } = useInput((value) => value.trim().length > 0);
  const {
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    valueBlurHandler: lastNameBlurHandler,
    reset: lastNameReset,
    value: lastName,
    isValid: isLastNameValid,
  } = useInput((value) => value.trim().length > 0);
  const {
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    valueBlurHandler: emailBlurHandler,
    reset: emailReset,
    value: email,
    isValid: isEmailValid,
  } = useInput((value) => value.trim().includes("@"));
  const {
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    valueBlurHandler: passwordBlurHandler,
    reset: passwordReset,
    value: password,
    isValid: isPasswordValid,
  } = useInput((value) => value.length > 7);
  const {
    hasError: rePasswordHasError,
    valueChangeHandler: rePasswordChangeHandler,
    valueBlurHandler: rePasswordBlurHandler,
    reset: rePasswordReset,
    value: rePassword,
    isValid: isRePasswordValid,
  } = useInput((value) => value.length > 7);



  // const {
  //   hasError: NotesasError,
  //   valueChangeHandler: NotesasChangeHandler,
  //   valueBlurHandler: NotesasBlurHandler,
  //   reset: NotesasReset,
  //   value: Notes,
  // } = useInput((value) => value.length > 8);



  const {
    hasError: NotesasError,
    valueChangeHandler: NotesasChangeHandler,
    valueBlurHandler: NotesasBlurHandler,
    reset: NotesasReset,
    value: Notes,
    isValid: isNotesValid,
  } = useInput((value) => value.trim().length > 0);

  // const {
  //   hasError: NotesasError,
  //   valueChangeHandler: firstNameChangeHandler,
  //   valueBlurHandler: firstNameBlurHandler,
  //   reset: firstNameReset,
  //   value: firstName,
  //   isValid: isFirstNameValid,
  // } 
  


  // const NotesError=NotesasError
  // ? "Please enter at least 8 to 10 characters"
  //   : Notes !== undefined &&
  //     Notes.length > 0 
  //     ? "Please  fill the Notes"
  //     : undefined;

  const passwordError = passwordHasError
    ? "Please enter at least 8 characters"
    : password !== undefined &&
      password.length > 0 &&
      rePassword !== undefined &&
      rePassword.length > 0 &&
      password !== rePassword
      ? "Your password does not match"
      : undefined;
  const rePasswordError = rePasswordHasError
    ? "Please enter at least 8 characters"
    : password !== undefined &&
      password.length > 0 &&
      rePassword !== undefined &&
      rePassword.length > 0 &&
      password !== rePassword
      ? "Your password does not match"
      : undefined;
  const isFormValid =
    isPasswordValid &&
    isRePasswordValid &&
    isFirstNameValid &&
    isLastNameValid &&
    isEmailValid;
  const resetForm = () => {
    firstNameReset();
    lastNameReset();
    emailReset();
    passwordReset();
    rePasswordReset();
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    if (isFormValid) {
      const userInfo = {
        firstName,
        lastName,
        role: props.role,
        email,
        password,
        Notes
      };
      console.log(userInfo,"userInfo")
      try {
        if (props.role === role.client) {
          userInfo.coachId = currentUser.id;
          userInfo.language = language;
          await createClient(userInfo)(dispatch);
        } else {
          await createCoach(userInfo)(dispatch);
        }
        navigate(-1);
        resetForm();
      } catch (e) {
      }
    }
  };




  return (
    <form method="submit" onSubmit={onSubmit} className={classes.formAlignment}>
      <div className={classes.row}>
        <div>
          <TextFormField
            type="text"
            placeholder="First Name"
            onChange={firstNameChangeHandler}
            onBlur={firstNameBlurHandler}
            value={firstName}
          />
          {firstNameHasError && (
            <p className={classes.error}>Please enter a valid first name</p>
          )}
        </div>
        <div>
          <TextFormField
            type="text"
            placeholder="Last Name"
            onChange={lastNameChangeHandler}
            onBlur={lastNameBlurHandler}
            value={lastName}
          />
          {lastNameHasError && (
            <p className={classes.error}>Please enter a valid last name</p>
          )}
        </div>
      </div>
      <div>
        <TextFormField
          type="email"
          placeholder="Email"
          isFormFieldExtra={true}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          value={email}
        />
        {emailHasError && (
          <p className={classes.error}>Please enter a valid email address</p>
        )}
      </div>
      <div>
        <TextFormField
          type="password"
          placeholder="Password"
          isFormFieldExtra={true}
          onChange={passwordChangeHandler}
          onBlur={passwordBlurHandler}
          value={password}
        />
        {passwordError && <p className={classes.error}>{passwordError}</p>}
      </div>
      <div>
        <TextFormField
          type="password"
          placeholder="Re-Password"
          isFormFieldExtra={true}
          onChange={rePasswordChangeHandler}
          onBlur={rePasswordBlurHandler}
          value={rePassword}
        />
        {rePasswordError && <p className={classes.error}>{rePasswordError}</p>}
      </div>

      {props.role === role.client && (
      <div>
        <TextFormField
          type="text"
          placeholder="Notes..."
          isFormFieldExtra={true}
          onChange={NotesasChangeHandler}
          onBlur={NotesasBlurHandler}
          value={Notes}
        />
         {NotesasError && (
            <p className={classes.error}>Please enter The Notes</p>
          )}
      </div>)}

      {props.role === role.client && (
        <div className={classes.radioButtonAlign}>
          <div className={classes.radioButtonAlign}>
            <input
              type="radio"
              value={languageEnum.english}
              name="language"
              onChange={onLanguageChange}
              checked={language === languageEnum.english}
            />
            <p style={{color:"white"}}>English</p>
          </div>

          <div className={classes.radioButtonAlign}>
            <input
              type="radio"
              value={languageEnum.spanish}
              name="language"
              onChange={onLanguageChange}
              checked={language === languageEnum.spanish}
            />
            <p style={{color:"white"}}>Spanish</p>
          </div>
        </div>
      )}
      {isLoading ? <LoadingButton /> : <Button text={props.buttonText} />}
    </form>
  );
};

export default UserForm;
