import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";
const getClientsWithCoachId = async (coachId) => {
  const userRef = collection(db, "users");
  const q = query(userRef, where("coachId", "==", coachId));
  const userSnapshot = await getDocs(q);
  const data = userSnapshot.docs.map((e) => {
    return {
      id: e.id,
      ...e.data(),
    };
  });
  // data.map(async (e) => {
  //   if (!e.email) {
  //     e.email = await getEmailByUidService(e.id);
  //   }
  //   return e;
  // });
  return data;
};

export default getClientsWithCoachId;
