// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coach-menu_align__7Waub {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #293c89;
  padding: 0.3rem 3%;
  z-index: 5;
}
.coach-menu_ul__1zyKm{
  margin-bottom: 0px;
}
.coach-menu_row__XiuQA {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 48%;
}

.coach-menu_title__V0\\+HY {
  color: white;
  font-size: 18px;
}

.coach-menu_logo__ti8KF {
  height: 1.5rem;
  width: 1.5rem;
  object-fit: contain;
  margin: 0px 0px 0px 5px;
}

.coach-menu_ul__1zyKm {
  list-style-type: none;
}
.coach-menu_ul__1zyKm > li {
  display: inline;
  margin: 8px;
  font-size: 14px;
}
.coach-menu_active__Cbvr8 {
  color: rgb(256, 256, 256, 1);
  text-decoration: none;
}
.coach-menu_inactive__8JdeY {
  color: rgb(256, 256, 256, 0.5);
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/menu/coach-menu.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,eAAe;EACf,WAAW;EACX,eAAe;AACjB;AACA;EACE,4BAA4B;EAC5B,qBAAqB;AACvB;AACA;EACE,8BAA8B;EAC9B,qBAAqB;AACvB","sourcesContent":[".align {\n  position: fixed;\n  top: 0;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  background: #293c89;\n  padding: 0.3rem 3%;\n  z-index: 5;\n}\n.ul{\n  margin-bottom: 0px;\n}\n.row {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 1rem;\n  width: 48%;\n}\n\n.title {\n  color: white;\n  font-size: 18px;\n}\n\n.logo {\n  height: 1.5rem;\n  width: 1.5rem;\n  object-fit: contain;\n  margin: 0px 0px 0px 5px;\n}\n\n.ul {\n  list-style-type: none;\n}\n.ul > li {\n  display: inline;\n  margin: 8px;\n  font-size: 14px;\n}\n.active {\n  color: rgb(256, 256, 256, 1);\n  text-decoration: none;\n}\n.inactive {\n  color: rgb(256, 256, 256, 0.5);\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"align": `coach-menu_align__7Waub`,
	"ul": `coach-menu_ul__1zyKm`,
	"row": `coach-menu_row__XiuQA`,
	"title": `coach-menu_title__V0+HY`,
	"logo": `coach-menu_logo__ti8KF`,
	"active": `coach-menu_active__Cbvr8`,
	"inactive": `coach-menu_inactive__8JdeY`
};
export default ___CSS_LOADER_EXPORT___;
