// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ordered-task_margin__NOIBw {
  margin: 2rem;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/coach-dashboard/home/orderedTasks/ordered-task.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".margin {\n  margin: 2rem;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"margin": `ordered-task_margin__NOIBw`
};
export default ___CSS_LOADER_EXPORT___;
