import React, { useState, useEffect } from "react";
import classes from "./Task.module.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import PdfReader from "../../Components/pdf-reader/PdfReader";
import { useNavigate } from "react-router-dom";

const cloudName = "db3gzsopp";
const uploadPreset = "u2muuej0";

export default function UploadPdf({ setBtnDisable, setloader, setTask, task, show, setshow,progressBar,setprogressbar }) {
  const [viewpdffile, setviewpdffile] = useState(true);
  const navigate = useNavigate();

  const handlePdfUpload = async (e) => {
    console.log("Entering handleFileChange");
    if (!e.target.files[0]) {
      toast.error("Please select a file.");
      return;
    }

    const fileSizeInMB = e.target.files[0].size / (1024 * 1024); // Convert to MB
    const maxFileSize = 100; // Maximum allowed file size in MB
  
    if (fileSizeInMB > maxFileSize) {
      toast.error("File size exceeds 100 MB. Please upload a smaller file.");
      return;
    }

    console.log("Selected file:", e.target.files[0]);
    setBtnDisable(true);
    setloader(true);
    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("upload_preset", uploadPreset);

      // Determine resource type based on file type
      let resourceType;
      const fileType = e.target.files[0].type;

      if (fileType.includes("video")) {
        resourceType = "video";
      } else if (fileType.includes("application/pdf")) {
        resourceType = "raw";
      } else if (fileType.includes("image")) {
        resourceType = "image";
      } else {
        console.error("Unsupported file type:", fileType);
        toast.error("Unsupported file type");
        setloader(false);
        return;
      }
      //    debugger'
      console.log(cloudName, "cloudName");
      console.log(resourceType, "resourceType");
      console.log(formData, "resourceType");
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress:event=>{
            setprogressbar(Math.round((100*event.loaded)/event.total))
          }
        }
      );

      console.log(response.data);
      // setviewpdffile(false);

      setTask((prevTask) => ({
        ...prevTask,
        Pdf: response.data,
      }));
      setprogressbar(0)

      setBtnDisable(false);
      setloader(false);

      toast.success("uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Please upload a file and ensure that the image is no larger than 10MB.");
      setBtnDisable(false);
      setloader(false);
    }
  };

  const closeHandler = () => {
    // setviewpdffile(true);
    setTask((prevState) => ({
      ...prevState,
      Pdf: {},
    }));
  };

  const navigateHandler = (url) => {
    window.open(url, "_blank"); // '_blank' opens the link in a new tab
  };

  // useEffect(() => {
  //   if (show) {
  //     // If 'show' is true, set both 'setviewpdffile' and 'setshow' to false
  //     setviewpdffile(false);
  //     setshow(false);
  //   } else {
  //     // If 'show' is false, set 'setviewpdffile' to true
  //     setviewpdffile(true);
  //   }
  // }, [show]);

  return (
    <div>
    <div className={classes.upload__box}>
      <div className={classes.upload__upload__btn_box1}>
        {task.Pdf && Object.keys(task.Pdf).length > 0 ? (
          <>
             <p> Upload File & Image</p>
            {(() => {
              const url = task.Pdf.url;
              console.log(url,"neee")
              const fileType = task.Pdf.url.split('.').pop().toLowerCase();;
              console.log(fileType,"cooo")
              return (
                <div className={classes.iconcsss}>
                {(fileType === "png" || fileType === "jpeg" || fileType === "jpg") && (
                  <img
                    src={url}
                    alt="Uploaded Image"
                    height="150px"
                    width="100%"
                    className={classes.uploadedImage}
                    onClick={() => {
                      navigateHandler(url, fileType);
                    }}
                    
                  />
                )}
                {fileType=="pdf" && (
                  <>
                    <br />
                    <div
                      className={classes.pdficons}
                      key={task.video.url + Math.random()} 
                      onClick={() => {
                        navigateHandler(url, fileType);
                      }}
                    >
                      <h1>
                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </h1>
                    </div>
                  </>
                )}
                <i className="fa-solid fa-xmark" onClick={closeHandler}></i>
              </div>
              );
            })()}
          </>
        ) : (
          <div className={classes.upload__box}>
            <div className={classes.upload__upload__btn_box}>
              <p> Upload File & Image</p>
              <label className={classes.upload__btn}>
                <i className="fa-solid fa-upload"></i>
                <input
                  type="file"
                  accept=".pdf, .jpg, .jpeg, .png"
                  className="upload__inputfile"
                  id="image"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    handlePdfUpload(event);
                  }}
                />
                <p className={classes.upload_gallery_text}>Upload from gallery</p>
              </label>
            </div>
            <div className={classes.upload__img_wrap} />
          </div>
        )}
      </div>
    </div>
  </div>
  );
}
