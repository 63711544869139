import classes from "./date-time.module.css";
import moment from "moment";
const DateAndTime = () => {
  let date = moment(Date.now());
  let day = date.format("dddd");

  let monthWithDate = `${date.format("MMMM")} ${date.format("D")}`;
  return (
    <div className={classes.padding}>
      <p className={classes.monthWithDate}>{monthWithDate}</p>
      <p className={classes.day}>{day}</p>
    </div>
  );
};
export default DateAndTime;
