// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-button_button__n\\+yiF {
  background-color: transparent;
}

.icon-button_icon__jFKtN {
  width: 1rem;
  height: 1rem;
  object-fit: cover;
  cursor: pointer;
}

.icon-button_background__abANe {
  padding: 4px;
}

.icon-button_background__abANe:hover {
  background-color: #22303d;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/icon-button.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".button {\n  background-color: transparent;\n}\n\n.icon {\n  width: 1rem;\n  height: 1rem;\n  object-fit: cover;\n  cursor: pointer;\n}\n\n.background {\n  padding: 4px;\n}\n\n.background:hover {\n  background-color: #22303d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `icon-button_button__n+yiF`,
	"icon": `icon-button_icon__jFKtN`,
	"background": `icon-button_background__abANe`
};
export default ___CSS_LOADER_EXPORT___;
