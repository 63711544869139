import classes from "./unit-topic.module.css";
import { useDispatch } from "react-redux";
import { useState } from "react";
import AddTextField from "./add-text-field";
import IconButton from "./icon-button";
import iconType from "../../util/icon-type";
import {
  deleteTopic,
  updateTopicName,
} from "../../Store/Coach/tasks/tasks-actions";
import { tasksActions } from "../../Store/Coach/tasks/tasks-slice";
const UnitTopic = (props) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);

  const onEditStateHandler = () => {
    setIsEditing((state) => !state);
  };

  const onDeleteHandler = async () => {
    try {
      await deleteTopic(props.topicId, props.clientId)(dispatch);
    } catch (e) {
    }
  };
  const onCopyHandler = () => dispatch(tasksActions.copy(props.topicId));

  const onUpdateHandler = (event) => {
    event.preventDefault();
    updateTopicName(
      props.topicId,
      props.clientId,
      event.target[0].value
    )(dispatch).catch((error) => console.log(error));
    event.target.reset();
    setIsEditing((state) => !state);
  };

  const component = isEditing ? (
    <div className={classes.alignButtonAndField}>
      <AddTextField value={props.name} onSubmit={onUpdateHandler} />
      <div className={classes.alignButtons}>
        <IconButton icon={iconType.close} onClick={onEditStateHandler} />
      </div>
    </div>
  ) : (
    <div className={classes.alignTop}>
      <p className={classes.title}>{props.name}</p>
      {!isEditing && (
        <div className={classes.alignButtons}>
          <IconButton icon={iconType.delete} onClick={onDeleteHandler} />
          <IconButton icon={iconType.edit} onClick={onEditStateHandler} />
          {props.showCopy && (
            <IconButton icon={iconType.copy} onClick={onCopyHandler} />
          )}
        </div>
      )}
    </div>
  );

  return component;
};

export default UnitTopic;
