import { Outlet } from "react-router-dom";
import CoachMenu from "./menu/coach-menu";

const DashboardCoach = () => {
  return (
    <div>
      <CoachMenu />
      <Outlet />
    </div>
  );
};

export default DashboardCoach;
