import { collection, doc, updateDoc,setDoc,getDoc } from "firebase/firestore";
import { db } from "../../Configuration/initialize-firebase";

const updateTaskService = async (taskId,task) => {
  if (!taskId || !task) {
    throw Error("task/topic or description not found");
  }
  await updateTask(taskId,  task);
};

const updateTask = async (taskId, task) => {
  const topicRef = collection(db, "topics");
  const taskDoc = doc(topicRef, taskId);

  // Update topic document
  await updateDoc(taskDoc, {
    createdAt: new Date(task.date),
    topicName: task.task_name,
  });

  const taskRef = doc(topicRef, taskId, "tasks", task.taskId);

  let taskInfo = {
    description: task.todolist,
    Pdf: task.Pdf,
    video: task.video,
    recordingfile: task.recordingfile,
    status: task.status,
    comments: [],
    taskOrder: 1,
    createdAt: new Date(task.date),
  };

  // Await the update operation
  await updateDoc(taskRef, taskInfo);

  // Fetch the updated task document
  const updatedTaskDoc = await getDoc(taskRef);
  console.log(updatedTaskDoc.data(),"lucky ")

  if (updatedTaskDoc.exists()) {
    const updatedTaskData = updatedTaskDoc.data();
    console.log("Task updated successfully:", updatedTaskData);
    return updatedTaskData;
  } else {
    console.log("Task not found or not updated");
    return null;
  }
};





export default updateTaskService;
