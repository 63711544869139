import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import {
  sendNotification,
  addTopic,
  getTasksOfTopic,
  addCommentOnTopic,
} from "../../Store/Coach/tasks/tasks-actions";
import { useDispatch, useSelector } from "react-redux";

export default function ClientComment({eventId,taslkList}) {
  const topic = useSelector((state) => state.topics.topics);
  const [clients_comment, setclients_comment] = useState([]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  // const [name,setname]=useState("")
 console.log(taslkList,"taslkListtaslkList")

  const handleClose = () => setShow(false);

  useEffect(() => {
    setShow(true);
  }, []);



  useEffect(() => {
    const fetchData = async () => {
  
           if(taslkList.length>0 &&taslkList[0].comments){
            setclients_comment(taslkList[0].comments);
           }
    
    };

    fetchData();
  }, [eventId, topic]);

  return (
    <div className="bg-secondary">
    <Modal show={show} onHide={handleClose}>
    <Modal.Header>
    <div className="coach_comment_box_wrap">
           <h2>Client Comment</h2>
           <i class="fa-solid fa-xmark" onClick={handleClose}></i>
           </div>
        </Modal.Header>
      <Modal.Body>
        {clients_comment.length > 0 &&
          clients_comment.map((commentsection, i) => {
            console.log(commentsection, "commentcomment");
            return (
                <div class="card-body comment_box_text">
                  <p key={i}>{commentsection}</p>
                </div>
            );
          })}
      </Modal.Body>
    </Modal>
  </div>
  )
}
